<div class="card p-3 mb-4">
  <div class="card-body">
    <div class="fs-5 mb-3">Lista de Colecciones</div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col" class="text-center">#</th>
                  <th scope="col" class="text-center">Token</th>
                  <th scope="col" class="text-center">Addr</th>
                  <th scope="col" class="text-center">Price</th>
                  <th scope="col" class="text-center">Supply / Max Supply</th>
                  <th scope="col" class="text-center">Estado</th>
                  <th scope="col" class="text-center">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="pairList$ | async as list; else loading">
                  <ng-container *ngIf="list.length > 0; else noRecords">
                    <tr *ngFor="let item of list">
                      <th scope="row" class="text-center">
                        {{ item.pId }}
                      </th>
                      <td class="text-center">
                        {{ item.addr | erc20 : "name" | async | uppercase }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge bg-dark"
                          (click)="copyToClipboard(item.addr)">
                          {{ item.addr | truncateWalletAddress }}
                          &nbsp;
                          <i class="bi bi-clipboard"></i>
                        </span>
                      </td>
                      <td class="text-center">
                        {{ item.price | web3Utils : "fromWei" }}
                      </td>
                      <td class="text-center">
                        {{
                          item.addr | customERC721a : "totalSupply" | async
                        }}
                        / {{ item.addr | customERC721a : "MAX_SUPPLY" | async }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          [ngClass]="{
                            'bg-success': item.active,
                            'bg-danger': !item.active
                          }">
                          {{ item.active ? "Activo" : "Inactivo" }}
                        </span>
                      </td>
                      <td class="text-center">
                        <div class="btn-group btn-group-admin" role="group">
                          <ng-container
                            *ngIf="
                              item.addr | collectionById | async as data;
                              else renderLoadingOptionBtn
                            ">
                            <ng-container
                              [ngTemplateOutlet]="renderLoadOnDBBtn"
                              [ngTemplateOutletContext]="{
                                data: data,
                                item: item
                              }"></ng-container>
                          </ng-container>

                          <button
                            type="button"
                            class="btn btn-primary"
                            title="Ver en BSC Scan"
                            (click)="openBSCScan(item.addr)">
                            <i class="bi bi-link-45deg"></i>
                          </button>

                          <ng-container
                            *ngIf="
                              !(
                                item.addr
                                | customERC721a : 'hasRole' : [vendorContract]
                                | async
                              )
                            ">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              title="Permisos Vendor"
                              (click)="setVendorPermission(item.addr)">
                              <i class="bi bi-hammer"></i>
                            </button>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              item.addr | collectionById | async as data;
                              else renderLoadingOptionBtn
                            ">
                            <button
                              type="button"
                              class="btn btn-warning"
                              title="Editar"
                              *ngIf="data.exist"
                              [routerLink]="
                                '/admin/vendor/collection/' + item.addr
                              ">
                              <i class="bi bi-card-text"></i>
                            </button>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              item.addr | collectionById | async as data;
                              else renderLoadingOptionBtn
                            ">
                            <button
                              type="button"
                              class="btn btn-danger"
                              title="Eliminar"
                              *ngIf="data.exist"
                              (click)="removeCollection(data)">
                              <i class="bi bi-trash3"></i>
                            </button>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderLoadOnDBBtn let-data="data" let-item="item">
  <ng-container *ngIf="data.exist">
    <button type="button" class="btn btn-success" title="Colección Cargada">
      <i class="bi bi-cloud-check"></i>
    </button>
  </ng-container>

  <ng-container *ngIf="!data.exist">
    <button
      type="button"
      class="btn btn-warning"
      title="Cargar Colección"
      (click)="loadCollectionOnFB(item)">
      <i class="bi bi-cloud-upload"></i>
    </button>
  </ng-container>
</ng-template>

<ng-template #renderLoadingOptionBtn>
  <button class="btn btn-secondary" type="button" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
  </button>
</ng-template>

<ng-template #noRecords>
  <tr>
    <th scope="row" colspan="7">
      <h5 class="text-center">No hay registros</h5>
    </th>
  </tr>
</ng-template>

<ng-template #loading>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
