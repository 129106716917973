import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CustomTranslateService } from "src/app/services/custom-translate.service";

@Component({
  selector: "app-coming-soon",
  templateUrl: "./coming-soon.component.html",
  styleUrls: ["./coming-soon.component.css"],
})
export class ComingSoonComponent implements OnInit {
  @ViewChild("languageMenu") languageMenu!: ElementRef;
  @ViewChild("mobileMenu") mobileMenu!: ElementRef;
  public currentLanguage = "en";

  constructor(public translateSrv: CustomTranslateService) {
    this.currentLanguage = this.translateSrv.currentLanguage;
    console.log(this.currentLanguage);
  }

  ngOnInit() {}

  modeColor() {
    const checkbox: any = document.getElementById("changeColor");

    if (checkbox.checked) {
      document.body.classList?.add("dark");
      document.body.classList?.remove("white");
      localStorage.setItem("changeColor", "white");
    } else {
      document.body.classList?.add("white");
      document.body.classList?.remove("dark");
      localStorage.setItem("changeColor", "dark");
    }
  }

  toggleMenuLanguage() {
    this.languageMenu.nativeElement.classList.toggle("show");
  }

  changeLanguage(language: string) {
    this.languageMenu.nativeElement.classList.toggle("show");
    this.translateSrv.changeLanguage(language);
    this.currentLanguage = language;
  }
}
