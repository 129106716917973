import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, catchError, from, map, of } from "rxjs";
import { toWei } from "src/app/helpers/utils";
import { Web3Service } from "src/app/services/contract/web3.service";
import { environment } from "src/environments/environment";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-withdraw-erc20",
  templateUrl: "./withdraw-erc20.component.html",
  styleUrls: ["./withdraw-erc20.component.css"],
})
export class WithdrawERC20Component {
  public contract = environment.marketplaceAddress;
  public nativeCurrency = environment.chain.nativeCurrency;
  public erc20List$!: Observable<any[]>;

  public form: FormGroup;
  public vm = {
    token: [{ type: "required", message: "Es obligatorio" }],
    amount: [
      { type: "required", message: "Es obligatorio" },
      { type: "min", message: "Debe ser mayor a 0" },
    ],
  };
  public submitted = false;

  constructor(
    public fb: FormBuilder,
    public contractService: Web3Service,
    public vendorContractSrv: VendorContractService,
    private alertStepsSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.form = fb.group({
      token: ["", Validators.required],
      amount: ["", [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit(): void {
    this.loadTokens();
  }

  get f() {
    return this.form.controls;
  }

  loadTokens() {
    this.erc20List$ = from(
      this.contractService.vendor_whitelist_tokensList()
    ).pipe(
      map((data: any[]) => data.length > 0
        ? data.filter((item) => !item.isNative)
        : data
      ),
      catchError((err) => of([]))
    );
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        service: this.vendorContractSrv,
        askMessage: `¿Retirar ${_data.amount}?`,
        method: "vendor_withdraw_withdrawToken",
        params: [_data.token, toWei(_data.amount, 18)],
      });

      if (!result.status) {
        this.sweetAlert2Srv.showError(result.data.message);
        return;
      }

      this.submitted = false;
      this.form.patchValue({ token: "", amount: "" });
      this.loadTokens();

      this.sweetAlert2Srv.showSuccess("Retiro exitoso");
      return;
      
    } catch (err) {
      console.log("Error on WithdrawERC20Component@onSubmit", err);
    }
  }
}
