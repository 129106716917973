import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, catchError, from, map, of } from "rxjs";
import { toWei } from "src/app/helpers/utils";
import { environment } from "src/environments/environment";
import { SaleDistributionContractService } from "src/app/services/contract/sale-distribution-contract.service";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";

@Component({
  selector: "app-withdraw-erc20-sale-distribution",
  templateUrl: "./withdraw-erc20-sale-distribution.component.html",
  styleUrls: ["./withdraw-erc20-sale-distribution.component.css"],
})
export class WithdrawErc20SaleDistributionComponent implements OnInit {
  // public contract = environment.marketplaceAddress;

  public nativeCurrency = environment.chain.nativeCurrency;
  public erc20List$!: Observable<any[]>;

  public form: FormGroup;
  public vm = {
    token: [{ type: "required", message: "Es obligatorio" }],
    amount: [
      { type: "required", message: "Es obligatorio" },
      { type: "min", message: "Debe ser mayor a 0" },
    ],
  };
  public submitted = false;

  constructor(
    public fb: FormBuilder,
    private vendorContractSrv: VendorContractService,
    public saleDistributionContractSrv: SaleDistributionContractService,
    private alertStepSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.form = fb.group({
      token: ["", Validators.required],
      amount: ["", [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit(): void {
    this.loadTokens();
  }

  get f() {
    return this.form.controls;
  }

  loadTokens() {
    this.erc20List$ = from(
      this.vendorContractSrv.vendor_whitelist_tokensList()
    ).pipe(
      map((data: any[]) => data.length > 0
        ? data.filter((item) => !item.isNative)
        : data
      ),
      catchError((err) => of([]))
    );
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepSrv.showStepsGeneral({
        service: this.saleDistributionContractSrv,
        askMessage: `¿Retirar ${_data.amount}?`,
        method: "withdraw_withdrawToken",
        params: [_data.token, toWei(_data.amount, 18)],
      });

      if (!result.status) {
        this.sweetAlert2Srv.showError(result.data.message);
        return;
      }

      this.submitted = false;
      this.form.patchValue({ token: "", amount: "" });
      this.loadTokens();

      this.sweetAlert2Srv.showSuccess("Retiro exitoso");
      return;

    } catch (err) {
      console.log(
        "Error on WithdrawErc20SaleDistributionComponent@onSubmit",
        err
      );
    }
  }
}
