<div class="container-list-item pt-3">
  <div
    class="proyect-list-item mb-4"
    [ngClass]="{ active: showDetails }"
    (click)="onSelectProject()">
    <div class="new-tag" *ngIf="item.isNew">NEW</div>
    <img [src]="item?.thumbnails" class="img-fluid proyect-list-img" alt="" />
    <div class="proyect-list-item-texts">
      <h5>{{ item?.projectName | uppercase }}</h5>
      <p>*Rentabilidad estimada</p>
      <div class="d-flex align-items-center justify-content-between">
        <span>Saber el estatus de esta propiedad</span>
        <img
          src="assets/img/arrow-circle-orange.png"
          class="img-fluid icon-list"
          alt="" />
        <img
          src="assets/img/arrow-circle-white.png"
          class="img-fluid icon-list white"
          alt="" />
      </div>
    </div>
  </div>

  <div class="content-detail flex" *ngIf="showDetails">
    <div>
      <div class="swiper-slide">
        <img
          src="{{ item?.gallery[0].url }}"
          class="img-fluid img-pro"
          alt="" />
        <!-- <img src="assets/img/F_1.png" class="img-fluid img-pro" alt="" /> -->
        <div class="swiper-slide-shared">
          <img src="assets/img/Icon_20.png" class="img-fluid shared" alt="" />
        </div>
      </div>
    </div>

    <div class="proyects-availables">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="proyects-availables-percents">
            <div id="myProgress">
              <div id="myBar"></div>
            </div>
            <div class="myProgress-text">
              <span>18%</span>
              <span>100%</span>
            </div>
            <div class="proyects-availables-percents-item">
              <span>Fracciones Restantes</span>
              <p>
                <ng-container
                  *ngIf="erc721Info$ | async as data; else renderCeroValue"
                  [ngTemplateOutlet]="renderObsValue"
                  [ngTemplateOutletContext]="{
                    data: data,
                    field: 'supplyLeft'
                  }"></ng-container>
              </p>
            </div>

            <div class="proyects-availables-percents-item">
              <span>Valor Fracción</span>
              <p>
                <ng-container *ngIf="item.addr; else renderCeroValue">
                  {{ (price$ | async)?.price | number : "1.0-0" }}
                </ng-container>
                USD
              </p>
            </div>

            <div class="proyects-availables-percents-item">
              <span>Rentabilidad Estimada</span>
              <p>{{ item?.estimatedProfitability || 0 }}%*</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="proyects-availables-texts">
            <!-- <img src="assets/img/Magic.png" class="img-fluid magic" alt="" /> -->
            <h6>Descripción del proyecto</h6>
            <p [innerHTML]="item?.description"></p>

            <ng-container
              *ngIf="
                buttonOptionType == 1;
                then renderComingSoonButton
              "></ng-container>
            <ng-container
              *ngIf="
                buttonOptionType == 2;
                then renderBuyCollectionButtons
              "></ng-container>
            <ng-container
              *ngIf="
                buttonOptionType == 3;
                then renderSoldOutButton
              "></ng-container>

            <div
              class="d-flex flex-column align-items-center align-items-md-start align-items-lg-center justify-content-between mt-1">
              <div class="d-flex align-item-center justify-content-center">
                <a class="legal" (click)="openLink(item?.legalDocumentation)">
                  DOCUMENTACIÓN LEGAL
                </a>

                <p class="mx-2 mb-0">|</p>

                <a class="legal" (click)="openLink(item?.legalProcess)">
                  PROCESO LEGAL
                </a>
              </div>

              <a class="pdf my-3" (click)="openLink(item?.pdfDocument)">
                <img src="assets/img/pdf-orange.png" class="img-fluid" />
                Descargar PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderBuyCollectionButtons>
  <div class="btns-proyects-view">
    <button class="btn btn-purple">
      Comprar Fracciones con tarjeta de crédito
    </button>

    <div class="d-flex flex-column w-100">
      <button class="btn btn-buy" (click)="openBuyForm($event, item.addr)">
        Comprar Fracciones con criptomonedas
      </button>
      <div class="mb-3 form-content" id="{{ item.addr }}">
        <app-form-buy-propertie [dataId]="item.addr"></app-form-buy-propertie>
      </div>
    </div>

    <button class="btn btn-orange">CONOCE MÁS</button>
  </div>
</ng-template>

<ng-template #renderSoldOutButton>
  <div class="btns-proyects-view">
    <button class="btn btn-purple">Sold Out</button>
  </div>
</ng-template>

<ng-template #renderComingSoonButton>
  <div class="btns-proyects-view">
    <button class="btn btn-purple">Próximamente...</button>
  </div>
</ng-template>

<ng-template #renderObsValue let-data="data" let-field="field">
  {{ data[field] }}
</ng-template>

<ng-template #renderCeroValue>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>
