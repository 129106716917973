import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Observable, Subscription, of } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";

@Component({
  selector: "app-user-profile-sidebar",
  templateUrl: "./user-profile-sidebar.component.html",
  styleUrls: ["./user-profile-sidebar.component.css"],
})
export class UserProfileSidebarComponent implements OnInit, OnDestroy {
  public profile: any;
  public userDoc$!: Observable<null | any>;
  public records$: Observable<any[]> = of([]);
  private sub$!: Subscription;
  public mobile: any;

  // pablorioncones95@gmail.com

  constructor(
    private authSrv: AuthenticationService,
    private mediaQuery: MediaQueriesService
  ) {
    this.profile = this.authSrv.getLocalProfile();
  }

  ngOnInit() {
    this.userDoc$ = this.authSrv.userDoc$;
    /** Escuchar cambios en mediaqueries de la pantalla */
    this.sub$ = this.mediaQuery.mediaQuery$.subscribe((res: any) => {
      this.mobile = res.width <= 1199.98;
    });
  }

  async logOut() {
    return await this.authSrv.logout();
  }
  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
