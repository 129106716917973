import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // public apiURL = `${environment.API_URL}/api/v1`;
  public apiURL = `${environment.API_URL}`;

  constructor(private http: HttpClient) {}

  async get(path: string, data: any = {}) {
    try {
      /** Construir URL */
      const apiLink = `${this.apiURL}${path}`;

      /** Construir parametros de la URI */
      const params = new HttpParams({ fromObject: data });

      /** Realizar la peticion */
      const snapshot: any = await this.http
        .get(apiLink, { params: params })
        .toPromise();

      /** Retornar resultados */
      return snapshot.results;
    } catch (err) {
      console.log("Error on ApiService.get", err);
      throw err;
    }
  }

  async post(path: string, data: any) {
    try {
      /** Construir URL */
      const apiLink = `${this.apiURL}${path}`;

      /** Realizar la peticion */
      const snapshot: any = await this.http.post(apiLink, data).toPromise();

      /** Retornar resultados */
      return snapshot.results;
    } catch (err) {
      console.log("Error on ApiService.post", err);
      throw err;
    }
  }
}
