import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
// import { EmailNotificationService } from 'src/app/services/email-notification.service';
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { DataService } from "src/app/services/data.service";
import { MustMatch } from "src/app/helpers/must-match.validator";
import { checkEmailStored } from "src/app/helpers/custom-validations.helper";
import { TemporalTokenService } from "src/app/services/temporal-token.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<boolean>();

  public showPassword: any = {
    password: false,
    repeatpassword: false,
  };

  public form!: FormGroup;
  public vm: any = {
    name: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Solamente letras " },
      { type: "minlength", message: "Minimo 2 letras" },
    ],
    email: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Dirección de correo electrónico no válido" },
      {
        type: "emailStored",
        message:
          "El correo electrónico ya existe o se encuentra en uso, favor ingresar otra dirección",
      },
    ],
    phoneNumber: [
      { type: "required", message: "Se requiere número de teléfono" },
      { type: "pattern", message: "Solo se permiten los números" },
      {
        type: "minlength",
        message: "El número de teléfono debe tener al menos 10 caracteres",
      },
      {
        type: "maxlength",
        message: "El número de teléfono debe tener al menos 10 caracteres",
      },
    ],
    password: [
      { type: "required", message: "Requerido" },
      {
        type: "pattern",
        message:
          "Minimo ocho caracteres, al menos una letra en mayuscula, una letra minuscula, un numero y un caracter especial.",
      },
      { type: "maxlength", message: "La longitud máxima es de 12" },
    ],
    repeatpassword: [
      { type: "required", message: "Requerido" },
      {
        type: "mustMatch",
        message: "La contraseña de confirmación no coincide",
      },
    ],
    term: [{ type: "required", message: "Requerido" }],
    policy: [{ type: "required", message: "Requerido" }],
    prefix: [{ type: "required", message: "Requerido" }],
  };
  public submit = false;
  public loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private temporalTokenSrv: TemporalTokenService,
    // private emailNotificationSrv: EmailNotificationService,
    public dataSrv: DataService
  ) {
    this.buildForm();
  }

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  /**
   * Construir formulario
   */
  buildForm() {
    this.form = this.fb.group(
      {
        name: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
            Validators.minLength(2),
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          ],
          [checkEmailStored(this.authSrv)],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
            ),
            Validators.maxLength(12),
          ],
        ],
        phoneNumber: [
          "",
          [
            Validators.required,
            Validators.pattern(/^\d+$/),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        repeatpassword: ["", [Validators.required]],
        term: [false, [Validators.requiredTrue]],
      },
      { validator: MustMatch("password", "repeatpassword") }
    );
  }

  showValue(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      console.log("formData", formData);

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }

      /** Construir documento de usuario */
      const data = this.authSrv.buildUserDoc({
        name: `${formData.name}`.trim().toLowerCase(),
        email: `${formData.email}`.trim().toLowerCase(),
        password: `${formData.password}`.trim(),
        phoneNumber: `${formData.phoneNumber}`.trim(),
        term: formData.term,
      });
      console.log("data", data);

      /** Ejecutar 2FA en proceso de registro */
      const run2FA = await this.temporalTokenSrv.runByEmail(
        this.form.value.email
      );
      console.log("run2FA", run2FA);
      if (!run2FA) {
        return;
      }

      /** Crear usuario en sistema de autenticación */
      const afsUser: any = await this.authSrv.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      console.log("afsUser", afsUser);

      /** Capturar UID del usuario */
      const uid = afsUser.user.uid;

      // /** Registrar usuario */
      await this.authSrv.store(`${uid}`, data);
      this.sweetAlert2Srv.showSuccess("El Registro Fue Exitoso");

      /** TODO: Enviar mail de bienvenida */
      // await this.emailNotificationSrv.sendWelcomeNotification(data.name, data.email);

      /** Guardar identificador en el localStorage */
      this.authSrv.setLocalUID(uid);

      /** Obtener documento actualizado del usuario */
      const userDoc = await this.authSrv.getByUID(uid);
      const toParse = {
        email: userDoc.email,
        name: userDoc.name,
        avatar: userDoc.avatar,
        phoneNumber: userDoc.phoneNumber,
        walletAddress: userDoc.walletAddress,
      };

      localStorage.setItem("profile", JSON.stringify(toParse));

      return this.newItemEvent.emit(false);
    } catch (err: any) {
      console.log("Error on SignUpComponent.onSubmit", err);
      // alert("Error on SignUpComponent.onSubmit" + err.message);

      await this.sweetAlert2Srv.showWarning(err.message);
      this.authSrv.logout();
      window.location.reload();
      this.form.reset();
      return;
    } finally {
      this.loading = false;
    }
  }
}
