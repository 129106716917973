import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";
import { TemplateService } from "src/app/services/template.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public uid$!: Observable<null | string>;
  public authOption!: string;
  public userMobile!: boolean;

  private sub$!: Subscription;
  public mobile: any;

  public showNavbar = true;

  constructor(
    private authSrv: AuthenticationService,
    private mediaQuery: MediaQueriesService,
    private templateSrv: TemplateService
  ) {}

  ngOnInit() {
    this.uid$ = this.authSrv.uid$;
    // console.log(this.uid$);

    this.sub$ = this.authSrv.authOption$.subscribe((res: any) => {
      this.authOption = res;
    });

    this.sub$.add(
      this.mediaQuery.mediaQuery$.subscribe((res: any) => {
        // console.log(res.width);
        if (res.width <= 1199.98) {
          // console.log("mediaquery js");
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      })
    );
    this.sub$.add(
      this.authSrv.uid$.subscribe((res: any) => {
        if (res) {
          this.userMobile = true;
        } else {
          this.userMobile = false;
        }
      })
    );
  }

  /**
   * Al cambiar de tab en el sidebar cuando no esta autenticado
   * @param tabName
   */
  onSelectTab(tabName: string) {
    this.authSrv.authOption$.next(tabName);
  }

  /**
   * TODO: revisar antes de eliminar
   * funcion cuando el sidebar se cierra se pueda volver abrir
   * @param event
   * @param tabName
   */

  sidebar() {
    this.showNavbar ? (this.showNavbar = false) : (this.showNavbar = true);
    this.templateSrv.events$.next({ type: "sidebar", data: this.showNavbar });
  }

  openNav() {
    this.templateSrv.events$.next({ type: "sidebar", data: true });
  }

  scrollToElement(element: string): void {
    setTimeout(() => {
      let menuItem = document.querySelector(element);
      menuItem?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 1);
  }

  async logout() {
    return await this.authSrv.logout();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
