<div class="proyects-list mb-4">
  <ng-container
    *ngIf="proyectsList$ | async as data; else renderLoaderList"
    [ngTemplateOutlet]="renderCheckList"
    [ngTemplateOutletContext]="{ list: data }"></ng-container>
</div>

<ng-template #renderProjectList let-list="list">
  <ng-container
    *ngFor="let item of list; index as idx"
    [ngTemplateOutlet]="
      item.status !== 'draft' ? renderRegularCardItem : renderDraftCardItem
    "
    [ngTemplateOutletContext]="{ item: item, idx: idx }"></ng-container>
</ng-template>

<ng-template #renderRegularCardItem let-item="item" let-idx="idx">
  <app-proyects-view-list-item-mobile
    [item]="item"></app-proyects-view-list-item-mobile>
</ng-template>

<ng-template #renderDraftCardItem let-item="item" let-idx="idx">
  <div class="container-list-item pt-3">
    <div class="proyect-list-item draft mb-4" (click)="onSelectProject(item)">
      <div class="d-flex align-items-center">
        <img src="assets/img/hourglass.png" alt="" />
        <span>Coming Soon</span>
      </div>
      <p>{{ item.projectName | titlecase }}</p>
    </div>
  </div>
</ng-template>

<ng-template #renderCheckList let-list="list">
  <ng-container
    *ngIf="list.length > 0; else renderNoRecordsJet"
    [ngTemplateOutlet]="renderProjectList"
    [ngTemplateOutletContext]="{ list: list }"></ng-container>
</ng-template>

<ng-template #renderNoRecordsJet>
  <div class="container-list-item">
    <div class="proyect-list-item draft mb-4">
      <div class="d-flex align-items-center">
        <img src="assets/img/hourglass.png" alt="" />
        <span>Coming Soon</span>
      </div>
      <p>Obra de arte Picasso</p>
    </div>
  </div>
</ng-template>

<ng-template #renderLoaderList>
  <ng-container
    [ngTemplateOutlet]="renderLoaderItem"
    *ngFor="let item of [1, 1, 1]"></ng-container>
</ng-template>

<ng-template #renderLoaderItem>
  <div class="container-list-item">
    <div class="proyect-list-item mb-4">
      <img
        src="assets/img/loaderListItem.png"
        class="img-fluid proyect-list-img" />
      <div class="proyect-list-item-texts">
        <h5 class="placeholder-glow">
          <span class="placeholder col-10" style="height: 20px"></span>
        </h5>
        <p class="placeholder-glow">
          <span class="placeholder col-8" style="height: 16px"></span>
        </p>
        <div class="placeholder-glow">
          <span class="placeholder col-10" style="height: 16px"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
