<header>
  <ng-container *ngIf="uid$ | async; then profileMobile"></ng-container>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [ngClass]="{ active: mobile == true && userMobile == true }">
    <div class="container-fluid d-flex">
      <a class="navbar-brand ms-3" href="#">
        <img src="assets/img/logo-nuevo.png" alt="" />
        <p class="d-none d-xl-flex">
          | 2501 (From 2501 To 2589 Odd) NW (Northwest) 74TH AVE, MIAMI, FL
          33122-1417, USA
        </p>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse d-none d-xl-flex"
        id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 align-items-center">
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToElement('#quienesSomos')">
              Quienes Somos
            </a>
          </li>
          <li class="nav-item"><a class="nav-link px-1">|</a></li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToElement('#faq')">FAQ </a>
          </li>
        </ul>
        <div class="d-flex">
          <div
            class="btn-group"
            role="group"
            aria-label="Basic mixed styles example">
            <ng-container
              *ngIf="
                uid$ | async;
                then renderLoginDesktop;
                else renderLogoutDesktop
              "></ng-container>
          </div>
        </div>
      </div>

      <div class="mobile-login-register">
        <ng-container
          *ngIf="
            uid$ | async;
            then renderLoginMobile;
            else renderLogoutMobile
          "></ng-container>
      </div>
    </div>
  </nav>
  <div class="menu-mobile">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="scrollToElement('#quienesSomos')">
          Quienes Somos
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link px-1">|</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="scrollToElement('#faq')">FAQ </a>
      </li>
    </ul>
  </div>
</header>

<ng-template #renderLoginDesktop>
  <!-- <button type="button" class="btn btn-transparent" (click)="logout()">
    <img src="assets/img/cerrar-sesion.png" alt="" />
    Cerrar Sesión
  </button> -->

  <img
    class="img-fluid ms-2 cursor-pointer"
    src="assets/img/icon-menu.png"
    alt="icono menu"
    (click)="sidebar()" />
</ng-template>

<ng-template #renderLoginMobile>
  <button type="button" class="btn btn-transparent-mobile" (click)="logout()">
    <img src="assets/img/cerrar-sesion.png" alt="" />
    Cerrar Sesión
  </button>
</ng-template>

<ng-template #renderLogoutMobile>
  <button
    type="button"
    class="btn btn-transparent-mobile"
    (click)="onSelectTab('login'); openNav()"
    [ngClass]="{ active: authOption == 'login' }">
    <img src="assets/img/user-orange.png" alt="" /> Iniciar Sesión
  </button>

  <button
    type="button"
    class="btn btn-transparent-mobile"
    (click)="onSelectTab('sign-up'); openNav()"
    [ngClass]="{ active: authOption == 'sign-up' }">
    <img src="assets/img/enter-orange.png" alt="" />
    Registrarse
  </button>
</ng-template>

<ng-template #renderLogoutDesktop>
  <button
    type="button"
    class="btn btn-transparent"
    (click)="onSelectTab('login'); openNav()"
    [ngClass]="{ active: authOption == 'login' }">
    <img src="assets/img/user-grey.png" alt="" />
    <img src="assets/img/user-orange.png" class="img-active" alt="" />
    Iniciar Sesión
  </button>

  <button
    type="button"
    class="btn btn-transparent"
    (click)="onSelectTab('sign-up'); openNav()"
    [ngClass]="{ active: authOption == 'sign-up' }">
    <img src="assets/img/enter-orange.png" class="img-active" alt="" />
    <img src="assets/img/enter-grey.png" alt="" />
    Registrarse
  </button>
</ng-template>

<ng-template #profileMobile>
  <app-user-profile-mobile></app-user-profile-mobile>
</ng-template>
