import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';

@Component({
  selector: 'app-input-file-single-button',
  templateUrl: './input-file-single-button.component.html',
  styleUrls: ['./input-file-single-button.component.css']
})
export class InputFileSingleButtonComponent implements OnInit {

  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;

  @Input() accept = ['image/jpeg', 'image/png', 'image/jpg'].join(',');
  @Input() btnText = 'Select file';
  @Input() btnHtml = '<i class="bi bi-upload"></i> &nbsp; Select file';
  @Input() btnLabelType = 'text';

  @Output() onSelectFile = new Subject<File>();

  constructor(
    private sweetAlert2Srv: Sweetalert2Service,
  ) { }

  ngOnInit(): void { }

  launcInputImageFile(){ this.fileInputRef.nativeElement.click(); }

  async onInputFileChange(){
    const files = this.fileInputRef.nativeElement.files;
    // console.log('files', files?.length);

    /** Validar si hay archivos */
    if(!files?.length) { 
      this.fileInputRef.nativeElement.value = '';
      return;
    }

    const file = files[0];
    // console.log('file', file);
    const fileType = file.type;
    // console.log('fileType', fileType);

    /** Run file type validation */
    if(!this.accept.includes(fileType)){
      console.log('invalid file type', fileType);
      await this.sweetAlert2Srv.showToast('Invalid file type', 'error');
      this.fileInputRef.nativeElement.value = '';
      return;
    }

    this.onSelectFile.next(file);
    this.fileInputRef.nativeElement.value = '';
    return;
  }
}
