<div class="user-profile">
  <div>
    <div class="user-img" (click)="goToProfile()">
      <ng-container *ngIf="userDoc$ | async as profile">
        <img
          *ngIf="profile.avatar"
          class="img-fluid"
          [src]="profile.avatar"
          [alt]="profile.name" />
        <img
          *ngIf="!profile.avatar"
          class="img-fluid"
          src="assets/img/user-avatar-placeholder.png"
          alt="icon" />
      </ng-container>
    </div>
  </div>
  <div class="d-flex flex-row flex-xl-column justify-content-between w-100">
    <div class="d-flex flex-column">
      <span class="text-welcome">Bienvenido</span>

      <div class="d-flex align-items-center">
        <img
          src="assets/img/Icon_18.png"
          class="img-fluid user-icon d-none d-xl-flex" />

        <ng-container *ngIf="userDoc$ | async as profile; else renderRowLoader">
          <p class="name">{{ profile.name | titlecase }}</p>
        </ng-container>

        <img
          src="assets/img/check-user.png"
          class="img-arrow d-none d-xl-flex"
          alt="" />
      </div>

      <ng-container *ngIf="userDoc$ | async as profile; else renderRowLoader">
        <div *ngIf="profile.walletAddress; else renderNoWalletConfig">
          <div class="d-flex align-items-center">
            <p class="wallet">
              Wallet: {{ profile.walletAddress | truncateWalletAddress }}
            </p>
            <img
              width="17px"
              class="img-fuid ms-1"
              src="assets/img/check.png"
              alt="" />
          </div>
        </div>
      </ng-container>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <div
        class="my-properties d-flex d-xl-none"
        (click)="openTab($event, 'properties'); goToProperties()">
        <span>Mis Propiedades</span>
        <img
          src="assets/img/arrow-slider.png"
          class="grey-arrow img-arrow mobile"
          alt="flecha" />
        <img
          src="assets/img/arrow-slider.png"
          class="orange-arrow img-arrow mobile"
          alt="" />
      </div>

      <div class="position-relative">
        <span class="notification-number"></span>
        <img src="assets/img/Icon_44.png" class="img-fluid icon-email" alt="" />
      </div>

      <div
        (click)="logOut()"
        style="cursor: pointer"
        class="d-none d-xl-flex align-items-center me-xl-1 me-xxl-4">
        <img
          src="assets/img/cerrar-sesion.png"
          class="img-fluid icon-email mx-xl-1"
          alt="" />
        <span class="sing-out"> Cerrar Sesión</span>
      </div>
    </div>
  </div>
</div>

<div id="properties" class="content-detail">
  <div *ngIf="mobile">
    <ng-container [ngSwitch]="templateSrv.currentSidebarOption">
      <app-user-profile-sidebar
        *ngSwitchCase="'my-properties'"></app-user-profile-sidebar>
      <app-sidebar-profile-form
        *ngSwitchCase="'profile'"></app-sidebar-profile-form>
      <app-sidebar-config-user-code
        *ngSwitchCase="'config-code'"></app-sidebar-config-user-code>
    </ng-container>
  </div>
</div>

<ng-template #renderNoWalletConfig>
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="goToConfigWallet()">
    <p class="wallet">Wallet: Pendiente Configurar</p>
    <img
      width="17px"
      class="img-fuid ms-1"
      src="assets/img/x-circle.png"
      alt="" />
  </div>
</ng-template>

<ng-template #renderRowLoader>
  <p
    class="placeholder-glow"
    style="width: 100%; height: 16px; margin-bottom: 10px">
    <span class="placeholder col-12"></span>
  </p>
</ng-template>

<!-- <app-notifications-users></app-notifications-users> -->
