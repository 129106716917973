import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Web3Service } from "./web3.service";
import { customArrayNumber } from "src/app/helpers/utils";
import BigNumber from "bignumber.js";

@Injectable({
  providedIn: "root",
})
export class DistributionContractService {
  public abi = "/assets/abi/Distribution.json";

  public contractAddress = environment.distributionAddress;

  constructor(private web3Srv: Web3Service) {}

  /** ===============================================================
   *                 DISTRIBUTION Claim METHODS
   * ================================================================ */

  async distribution_claim_runDistributionPaginated(
    from: string,
    to: string,
    nftAddr: string,
    withPercentage: boolean,
    fullDeposit: string,
    amount: string,
    tokenToPay: string
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "runDistributionPaginated",
      params: [
        from,
        to,
        nftAddr,
        withPercentage,
        fullDeposit,
        amount,
        tokenToPay,
      ],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Collection METHODS
   * ================================================================ */

  async distribution_collection_getCollectionInfo(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "_collection",
      params: [addr],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_collection_getCollectionClaimRecord(
    addr: string,
    id: string | number
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "_collectionClaimRecords",
      params: [addr, id],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_collection_getCollectionClaimRecordPaginated(
    addr: string,
    from: number,
    to: number
  ) {
    try {
      const pagination: any = { from: 0, to: 0 };

      if (from) {
        pagination.from = from;
      }
      if (to) {
        pagination.to = to;
      }

      const collectionInfo =
        await this.distribution_collection_getCollectionInfo(addr);

      if (!collectionInfo.exist) {
        return [];
      }

      /** Corregir si el valor "hasta" es mayor al de la colección */
      if (new BigNumber(to).isGreaterThan(Number(collectionInfo.count) - 1)) {
        pagination.to = Number(collectionInfo.count) - 1;
      }

      const recordsList = customArrayNumber(pagination.from, pagination.to);

      const snapshot = await Promise.all(
        recordsList.map(async (id: any) => {
          const record =
            await this.distribution_collection_getCollectionClaimRecord(
              addr,
              id
            );
          return { ...record, _id: id };
        })
      );

      // console.log('snapshot', snapshot);

      return snapshot.sort((a: any, b: any) => a._id - b._id);
    } catch (err) {
      console.log(
        "Error on Web3Service.distribution_collection_getCollectionClaimRecordPaginated",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                 DISTRIBUTION UserPayment METHODS
   * ================================================================ */

  async distribution_userPayment_getUserPaymentInfo(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "userPaymentInfo",
      params: [addr],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_userPayment_getUserClaimRecord(
    addr: string,
    id: string | number
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "userClaimRecords",
      params: [addr, id],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_userPayment_getUserClaimRecordPaginated(
    addr: string,
    from: number,
    to: number
  ) {
    try {
      const pagination: any = { from: 0, to: 0 };

      if (from) {
        pagination.from = from;
      }
      if (to) {
        pagination.to = to;
      }

      const collectionInfo =
        await this.distribution_userPayment_getUserPaymentInfo(addr);
      // console.log('collectionInfo', collectionInfo);

      if (!collectionInfo.exist) {
        return [];
      }

      /** Corregir si el valor "hasta" es mayor al de la colección */
      if (new BigNumber(to).isGreaterThan(Number(collectionInfo.claims) - 1)) {
        pagination.to = Number(collectionInfo.claims) - 1;
      }

      const recordsList = customArrayNumber(pagination.from, pagination.to);
      // console.log('recordsList', recordsList);

      const snapshot = await Promise.all(
        recordsList.map(async (id: any) => {
          const record = await this.distribution_userPayment_getUserClaimRecord(
            addr,
            id
          );
          return { ...record, _id: id };
        })
      );

      // console.log('snapshot', snapshot);

      return snapshot.sort((a: any, b: any) => a._id - b._id);
    } catch (err) {
      console.log(
        "Error on Web3Service.distribution_userPayment_getUserClaimRecordPaginated",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                   DISTRIBUTION Withdraw METHODS
   * ================================================================ */

  async distribution_withdraw_withdraw(amount: string) {
    const addr = this.web3Srv.accounts[0];
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdraw",
      params: [amount, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_withdraw_withdrawToken(erc20: string, amount: string) {
    const addr = this.web3Srv.accounts[0];
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawToken",
      params: [erc20, amount, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Administered METHODS
   * ================================================================ */

  async distribution_administered_addAdmin(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_administered_addUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_administered_removeUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_administered_isAdmin(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      params: [wallet],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_administered_isUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "isUser",
      params: [wallet],
      callType: "call",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Chainalisys METHODS
   * ================================================================ */

  async distribution_chainalisys_SANCTIONS_CONTRACT() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "SANCTIONS_CONTRACT",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_chainalisys_setAddressSanctions(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setAddressSanctions",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async distribution_chainalisys_stateSanctions() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "stateSanctions",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_chainalisys_setStateSanctions(status: boolean) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setStateSanctions",
      params: [status],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Blacklist METHODS
   * ================================================================ */

  async distribution_blacklist_isBlackList(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "isBlackList",
      params: [addr],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async distribution_blacklist_setAddressBlackList(
    addr: string,
    status: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "setAddressBlackList",
      params: [addr, status],
      callType: "send",
      urlABI: this.abi,
    });
  }
}
