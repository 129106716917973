import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { WhiteListTokenComponent } from "./pages/white-list-token/white-list-token.component";
import { WithdrawalsAdminComponent } from "./pages/withdrawals-admin/withdrawals-admin.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "marketPlace",
    loadChildren: () =>
      import("./market-place-admin/marketPlaceeAdmin.module").then(
        (m) => m.MarketPlaceAdminModule
      ),
  },
  {
    path: "vendor",
    loadChildren: () =>
      import("./vendor-admin/vendorAdmin.module").then(
        (m) => m.VendorAdminModule
      ),
  },
  {
    path: "collection",
    loadChildren: () =>
      import("./collection/collection.module").then((m) => m.CollectionModule),
  },
  {
    path: "utilities",
    loadChildren: () =>
      import("./utilities/utilities.module").then((m) => m.UtilitiesModule),
  },
  {
    path: "cases",
    loadChildren: () =>
      import("./cases-admin/cases-admin.module").then(
        (m) => m.CasesAdminModule
      ),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "payments-due",
    loadChildren: () =>
      import("./payments-due/payments-due.module").then(
        (m) => m.PaymentsDueModule
      ),
  },
  {
    path: "white-list-token",
    component: WhiteListTokenComponent,
  },
  {
    path: "withdrawals",
    component: WithdrawalsAdminComponent,
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "/admin/dashboard",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
