<section class="inversion mt-5 pt-5">
  <div class="container">
    <div class="row">
      <h2 class="title text-center mb-3">
        Invierta donde las oportunidades <br />
        son más atractivas
      </h2>

      <p class="text text-center">
        Nos especializamos en los mercados emergentes que ofrezcan seguridad
        jurídica <br />
        y perspectivas económicas favorables.
      </p>

      <div class="col-12 mt-5 justify-content-center d-flex">
        <img src="assets/img/81.png" class="img-fluid" alt="" />
      </div>
    </div>
  </div>
</section>
