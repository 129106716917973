<div class="sidebar">
  <div class="sidebar-profile">
    <div class="sidebar-profile-img">
      <img
        src="assets/img/user-icon-profiel-admin-white.png"
        class="img-fluid"
        alt="" />
    </div>
    <p class="sidebar-profile-name">Admin</p>
  </div>
  <a [routerLink]="'/admin/users'" [routerLinkActive]="'active'">
    <img
      src="assets/img/user-icon-admin-white.png"
      class="icon orange-icon"
      alt="icono de usuario" />
    <img
      src="assets/img/user-icon-admin-black.png"
      class="icon white-icon"
      alt="" />
    Usuarios
  </a>
  <a routerLink="/admin/payments-due" routerLinkActive="active">
    <img
      src="assets/img/pending-icon-admin-white.png"
      class="icon orange-icon"
      alt="icono de usuario" />

    <img
      src="assets/img/pending-icon-admin-balck.png"
      class="icon white-icon"
      alt="icono de usuario" />
    Pagos pendientes
  </a>
  <a [routerLink]="'/admin/white-list-token'" [routerLinkActive]="'active'">
    <img
      src="assets/img/tokens-icon-admin-white.png"
      class="icon orange-icon"
      alt="icono de usuario" />
    <img
      src="assets/img/tokens-icon-admin-black.png"
      class="icon white-icon"
      alt="icono de usuario" />
    Tokens a Recibir
  </a>
  <a [routerLink]="'/admin/collection'" [routerLinkActive]="'active'">
    <img
      src="assets/img/proyect-icon-admin-white.png"
      class="icon orange-icon"
      alt="icono de usuario" />
    <img
      src="assets/img/proyect-icon-admin-black.png"
      class="icon white-icon"
      alt="icono de usuario" />
    Proyectos
  </a>
  <a [routerLink]="'/admin/withdrawals'" [routerLinkActive]="'active'">
    <img
      src="./assets/img/withdrawal-icon-admin-white.png"
      class="icon orange-icon" />
    <img
      src="assets/img/withdrawal-icon-admin-black.png"
      class="icon white-icon" />
    Retiros
  </a>

  <a [routerLink]="'/admin/utilities'" [routerLinkActive]="'active'">
    <img
      src="./assets/img/utilities-icon-admin-white.png"
      class="icon orange-icon" />
    <img
      src="assets/img/utilities-icon-admin-black.png"
      class="icon white-icon" />
    Utilidades
  </a>
</div>
