import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  Observable,
  Subscription,
  catchError,
  distinctUntilChanged,
  interval,
  map,
  of,
  switchMap,
} from "rxjs";
import { fromWei } from "src/app/helpers/utils";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-proyects-view-details-desktop-info",
  templateUrl: "./proyects-view-details-desktop-info.component.html",
  styleUrls: ["./proyects-view-details-desktop-info.component.css"],
})
export class ProyectsViewDetailsDesktopInfoComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("formBuyButton") formBuyButton!: ElementRef;

  @Input() item: any = {
    projectName: "...",
    addr: null,
    gallery: new Array(3).fill({
      order: 0,
      size: 0,
      thumbnails: false,
      type: "image/png",
      url: this.collectionSrv.defaultSwiper,
    }),
  };
  public price$ = of({ price: 0 });
  public erc721Info$: Observable<any> = of({
    addr: null,
    totalSupply: 0,
    supply: 0,
    supplyLeft: 0,
  });

  public supplyPercentTotal: string = "0px";
  public uid$!: Observable<string>;

  private sub$!: Subscription;

  public openForm: boolean = false;

  constructor(
    private authSrv: AuthenticationService,
    private collectionSrv: CollectionService,
    private web3Srv: Web3Service,
    private sweetAlert2Srv: Sweetalert2Service
  ) {}

  ngOnInit(): void {
    this.uid$ = this.authSrv.uid$;

    this.sub$ = this.collectionSrv.onViewSelectedCollection$.subscribe(
      (res: any) => {
        if (res.action == "selectProject") {
          this.item = res.data;
          this.openForm = false;
          if (this.formBuyButton) {
            this.formBuyButton.nativeElement.classList.remove("active");
          }

          /** Obtener información de ERC721a */
          this.erc721Info$ = interval(1000).pipe(
            switchMap(() => this.web3Srv.erc721_getAllInfo(this.item.addr)),
            map((res: any) => ({
              addr: this.item.addr,
              totalSupply: res.totalSupply,
              supply: res.supply,
              supplyLeft: res.totalSupply - res.supply,
            })),
            catchError((err) =>
              of({
                addr: null,
                totalSupply: 0,
                supply: 0,
                supplyLeft: 0,
              })
            ),
            distinctUntilChanged(
              (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
            )
          );

          this.erc721Info$.subscribe((values) => {
            this.calculateSupplyPercent(values);
          });

          /** Parsear Precio de la coleccióin */
          this.price$ = of({ price: fromWei(this.item.price, 18) });
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { item } = changes;
    console.log(item);
    if (item && item.currentValue) {
      this.item = item.currentValue;
      console.log(item);
    }
  }

  openBuyForm() {
    if (this.openForm === true) {
      this.openForm = false;
    } else {
      this.openForm = true;
    }
    // this.formBuy.nativeElement.classList.toggle("d-none");
    this.formBuyButton.nativeElement.classList.toggle("active");
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  openLink(url: string) {
    if (!url) {
      this.sweetAlert2Srv.showWarning("Enlace no disponible");
      return;
    }
    window.open(url, "_blank");
  }

  previewCard() {
    this.sweetAlert2Srv.showWarning("Próximamente será habilitado");
  }

  async calculateSupplyPercent(values: any) {
    let totalPercent =
      (Number(values.supply) * 100) / Number(values.totalSupply);
    this.supplyPercentTotal = String(totalPercent + "%");
  }
}
