import { Component, OnInit } from "@angular/core";
import { Observable, catchError, from, of } from "rxjs";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { Web3Service } from "src/app/services/contract/web3.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public account: string;

  public roles$!: Observable<any>;
  public rol: boolean = true;

  constructor(
    public web3Srv: Web3Service,
    private vendorCotractSrv: VendorContractService
  ) {
    this.account = this.web3Srv.accounts[0];
  }

  ngOnInit(): void {
    this.roles$ = from(
      this.vendorCotractSrv.vendor_administered_getRoles(this.account)
    ).pipe(
      catchError((err) => {
        return of({
          vendorIsAdmin: false,
          vendorIsUser: false,
        });
      })
    );
  }
}
