import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { fromWei, toWei } from "./helpers/utils";
import { CommonService } from "./services/common.service";
import { AbiService } from "./services/contract/abi.service";
import { Web3Service } from "./services/contract/web3.service";
import { CustomTranslateService } from "./services/custom-translate.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  authenticated: boolean = false;
  data: string[] | undefined;

  public appVersion = "0.0.22";

  constructor(
    private commonService: CommonService,
    private web3: Web3Service,
    private abiSrv: AbiService,
    public translateSrv: CustomTranslateService
  ) {
    const cl = localStorage.getItem("language");
    console.log("cl", cl);

    /** No existe el idioma */
    if (!cl) {
      this.translateSrv.changeLanguage("en");
    } else {
      this.translateSrv.loadLocalLanguage();
    }
    console.log("MAIN:", environment.chain.chainId);
    console.log("MAIN:", environment.chain.rpc);
    console.log("appVersion:", this.appVersion);
  }

  ngOnInit(): void {
    // this.parseABI();
  }

  async parseABI() {
    // const r = await this.abiSrv.parseABI('/assets/abi/NexChain.json');
    const r = await this.abiSrv.parseABI(this.web3.vendorABI);
    console.log(r);
  }
}
