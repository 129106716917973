<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 font_thb">Revocar permisos</div>
        <div class="fs-small mb-3">Remover rol de Administrador</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <input type="text" formControlName="address" class="form-control" placeholder="Address">
                <label for="floatingInput">
                    Billetera
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.address">
                        <div class="form-text text-danger mb-2" *ngIf="f['address'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Remover
                </button>
            </div>

        </form>
    </div>
</div>