import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/admin-layout/admin-layout.component";

const routes: Routes = [
  //   {
  //     path: "**",
  //     pathMatch: "full",
  //     redirectTo: "/pages/home",
  //   },
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  //   {
  //     path: "admin",
  //     component: AdminLayoutComponent,
  //     loadChildren: () =>
  //       import("./admin/admin.module").then((m) => m.AdminModule),
  //   },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
