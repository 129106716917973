import { Component, OnInit } from "@angular/core";
import { TemplateService } from "src/app/services/template.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-user-profile-mobile",
  templateUrl: "./user-profile-mobile.component.html",
  styleUrls: ["./user-profile-mobile.component.css"],
})
export class UserProfileMobileComponent implements OnInit {
  private sub$!: Subscription;
  public showNavbar = false;

  constructor(private templateSrv: TemplateService) {}

  ngOnInit() {
    this.sub$ = this.templateSrv.events$.subscribe((res: any) => {
      const { type, data } = res;
      if (type === "menu-profile-mobile") {
        this.showNavbar = data;
      }
    });
  }
}
