<div class="row mt-5">
  <div class="col-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/admin/vendor">
            <i class="bi bi-arrow-left"></i>&nbsp;Dashboard
          </a>
        </li>
        <li class="breadcrumb-item" aria-current="page">Vendor Contract</li>
        <li class="breadcrumb-item" aria-current="page">Collection</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ id }}
        </li>
      </ol>
    </nav>
  </div>

  <ng-container
    *ngIf="roles$ | async as roles; else renderLoader"
    [ngTemplateOutlet]="renderCheckRoles"
    [ngTemplateOutletContext]="{roles}"></ng-container>
</div>

<ng-template #renderForm>
  <div class="col-12" *ngIf="collectionDoc; else renderLoader">
    <div class="card p-3 mb-4">
      <div class="card-body">
        <div class="fs-5 mb-3">Información de la Colección</div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="fs-small mb-3">
              <strong>Nombre:</strong>
              {{
                collectionDoc.addr | customERC721a : "name" | async | uppercase
              }}
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="fs-small mb-3">
              <strong>Simbolo:</strong>
              {{
                collectionDoc.addr
                  | customERC721a : "symbol"
                  | async
                  | uppercase
              }}
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="fs-small mb-3">
              <strong>Dirección:</strong>
              {{ collectionDoc.addr | truncateWalletAddress }}
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="fs-small mb-3">
              <strong>Total:</strong>
              {{
                collectionDoc.addr
                  | customERC721a : "totalSupply"
                  | async
                  | uppercase
              }}
              /
              {{
                collectionDoc.addr
                  | customERC721a : "MAX_SUPPLY"
                  | async
                  | uppercase
              }}
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="fs-small mb-3">
              <strong>BaseTokenURI:</strong>
              {{ collectionDoc.addr | customERC721a : "baseTokenURI" | async }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-4">
      <div class="card-body">
        <div class="fs-5 mb-3">Datos Visuales</div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-floating mb-3">
            <input
              type="text"
              formControlName="projectName"
              class="form-control"
              placeholder="Nombre del Projecto" />
            <label for="floatingInput"> Nombre del Projecto </label>
            <ng-container
              [ngTemplateOutlet]="renderVM"
              [ngTemplateOutletContext]="{
                field: 'projectName'
              }"></ng-container>
          </div>

          <div class="form-floating mb-3">
            <textarea
              cols="30"
              rows="10"
              formControlName="description"
              class="form-control"
              placeholder="Descripción"></textarea>
            <label for="floatingInput"> Descripción </label>
            <ng-container
              [ngTemplateOutlet]="renderVM"
              [ngTemplateOutletContext]="{
                field: 'description'
              }"></ng-container>
          </div>

          <div class="form-floating mb-3">
            <input
              type="number"
              formControlName="estimatedProfitability"
              class="form-control"
              placeholder="Rentabilidad Estimada (%)" />
            <label for="floatingInput"> Rentabilidad Estimada (%) </label>
            <ng-container
              [ngTemplateOutlet]="renderVM"
              [ngTemplateOutletContext]="{
                field: 'estimatedProfitability'
              }"></ng-container>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text">¿Es Nuevo?</span>
            <select
              class="form-select"
              formControlName="isNew"
              placeholder="¿Es Nuevo?">
              <option [value]="true">Sí</option>
              <option [value]="false">No</option>
            </select>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text">Estado</span>
            <select
              class="form-select"
              formControlName="status"
              placeholder="Estado"
              aria-describedby="statusHelp">
              <option
                *ngFor="let item of collectionStatus"
                [value]="item.value">
                {{ item.label | uppercase }}
              </option>
            </select>
          </div>
          <div id="statusHelp" class="form-text mb-4">
            {{ collectionStatusHelp }}
          </div>

          <hr />
          <div class="fs-5 mb-3">Documentación Legal</div>
          <ng-container
            [ngTemplateOutlet]="renderInputPDFFileOption"
            [ngTemplateOutletContext]="{
              field: 'legalDocumentation'
            }"></ng-container>

          <!-- <div class="form-floating mb-3">
              <input type="text" formControlName="legalDocumentation" class="form-control" placeholder="legalDocumentation">
              <label for="floatingInput">
                legalDocumentation
              </label>
              <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{field: 'legalDocumentation'}"></ng-container>
            </div> -->

          <hr />

          <hr />
          <div class="fs-5 mb-3">Documento Proceso Legal</div>
          <ng-container
            [ngTemplateOutlet]="renderInputPDFFileOption"
            [ngTemplateOutletContext]="{
              field: 'legalProcess'
            }"></ng-container>

          <!-- <div class="form-floating mb-3">
              <input type="text" formControlName="legalProcess" class="form-control" placeholder="legalProcess">
              <label for="floatingInput">
                legalProcess
              </label>
              <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{field: 'legalProcess'}"></ng-container>
            </div> -->

          <hr />

          <div class="fs-5 mb-3">Documento PDF</div>
          <ng-container
            [ngTemplateOutlet]="renderInputPDFFileOption"
            [ngTemplateOutletContext]="{ field: 'pdfDocument' }"></ng-container>
          <!-- <div class="form-floating mb-3">
            <input type="text" formControlName="pdfDocument" class="form-control" placeholder="pdfDocument">
            <label for="floatingInput">
              pdfDocument
            </label>
            <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{field: 'pdfDocument'}"></ng-container>
          </div> -->
          <hr />

          <hr />
          <div class="fs-5 mb-3">Galeria</div>

          <app-input-file-single-button
            btnLabelType="html"
            btnHtml="<i class='bi bi-image'></i> &nbsp; Select file"
            (onSelectFile)="onGalleryInputFileChange($event)"
            [accept]="acceptImageFileTypes"></app-input-file-single-button>

          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'gallery' }"></ng-container>

          <!-- <div class="form-floating mb-3">
            <input type="text" formControlName="gallery" class="form-control" placeholder="gallery">
            <label for="floatingInput">
              gallery
            </label>

            <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{field: 'gallery'}"></ng-container>
          </div> -->

          <ng-container
            *ngIf="
              galleryFiles.length > 0;
              then renderGallery;
              else renderNoGalleryFiles
            "></ng-container>

          <div class="d-flex justify-content-start mt-4">
            <button type="submit" class="btn btn-primary">Actualizar</button>
            <button
              type="button"
              class="btn btn-danger"
              routerLink="/admin/vendor">
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderInputPDFFileOption let-field="field">
  <ng-container
    *ngIf="f[field].value"
    [ngTemplateOutlet]="renderFileRaw"
    [ngTemplateOutletContext]="{field}"></ng-container>
  <ng-container
    *ngIf="!f[field].value"
    [ngTemplateOutlet]="renderInputPDFFile"
    [ngTemplateOutletContext]="{field}"></ng-container>
</ng-template>

<ng-template #renderFileRaw let-field="field">
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    <i
      class="bi bi-filetype-pdf"
      role="img"
      (click)="openFileOnNewTab(f[field].value)"></i>
    &nbsp; PDF file uploaded.
    <button
      type="button"
      class="btn-close"
      (click)="removePDFDocumentFile(field)"></button>
  </div>
</ng-template>

<ng-template #renderInputPDFFile let-field="field">
  <app-input-file-single-button
    btnLabelType="html"
    btnHtml="<i class='bi bi-filetype-pdf'></i> &nbsp; Select file"
    (onSelectFile)="onSelectDocumentFile(field, $event)"
    [accept]="acceptPDFFileTypes"></app-input-file-single-button>
</ng-template>

<ng-template #renderGallery>
  <div
    class="card mb-3"
    *ngFor="
      let item of galleryFiles;
      index as idx;
      first as isFirst;
      last as isLast
    ">
    <div class="row g-0">
      <div class="col-md-4">
        <img [src]="item.url" class="img-fluid rounded-start" />
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title">Image {{ idx + 1 }}</h5>
          <p class="card-text">Type: {{ item.type }}</p>
          <p class="card-text">Size: {{ item.size }}</p>
          <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          <div class="btn-group btn-group-admin">
            <button
              type="button"
              class="btn btn-warning"
              title="Fijar como thumbnails"
              (click)="setThumbnail(idx)"
              *ngIf="!item.thumbnails">
              <i class="bi bi-pin-fill"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              title="Subir en el orden"
              (click)="changeGalleryFileOrder(idx, idx - 1)"
              *ngIf="!isFirst">
              <i class="bi bi-caret-up-square-fill"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              title="Bajar en el orden"
              (click)="changeGalleryFileOrder(idx, idx + 1)"
              *ngIf="!isLast">
              <i class="bi bi-caret-down-square-fill"></i>
            </button>
            <button
              type="button"
              class="btn btn-danger"
              title="Eliminar"
              (click)="deleteGalleryFile(idx)">
              <i class="bi bi-trash3"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderNoGalleryFiles>
  <div class="card card-body">No gallery yet</div>
</ng-template>

<ng-template #renderCheckRoles let-roles="roles">
  <ng-container
    *ngIf="roles.vendorIsUser; then renderForm; else noStaff"></ng-container>
</ng-template>

<ng-template #renderVM let-field="field">
  <ng-container *ngIf="submitted">
    <ng-container *ngFor="let item of vm[field]">
      <div
        class="form-text text-danger mb-2"
        *ngIf="f[field].hasError(item.type)">
        {{ item.message | uppercase }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noStaff>
  <div class="col-12 mt-5">
    <app-not-access></app-not-access>
  </div>
</ng-template>

<ng-template #renderLoader>
  <div class="col-12">
    <div class="alert alert-info" role="alert">
      <h4 class="alert-heading">Please, wait</h4>
      <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <div
          class="spinner-border ms-auto"
          role="status"
          aria-hidden="true"></div>
      </div>
    </div>
  </div>
</ng-template>
