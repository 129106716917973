<form class="form-sidebar" [formGroup]="form" novalidate autocomplete="off">
  <div class="row flex-column">
    <!-- Input Name -->
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Nombre"
        aria-label="Name"
        formControlName="name" />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'name' }"></ng-container>
    </div>

    <!-- Input Email -->
    <div class="col">
      <input
        type="email"
        class="form-control"
        placeholder="Correo"
        aria-label="email"
        formControlName="email" />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"></ng-container>
    </div>

    <!-- Input Phone Number -->
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Teléfono"
        aria-label="Teléfono"
        formControlName="phoneNumber" />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'phoneNumber' }"></ng-container>
    </div>

    <!-- Input Password -->
    <div class="col position-relative">
      <input
        [type]="showPassword.password ? 'text' : 'password'"
        class="form-control"
        placeholder="Contraseña"
        aria-label="Contraseña"
        formControlName="password"
        autocomplete="new-password" />
      <ng-container
        [ngTemplateOutlet]="renderShowValueBtn"
        [ngTemplateOutletContext]="{
          field: 'password',
          id: 'password-register'
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'password' }"></ng-container>
    </div>

    <!-- Input Repeat Password -->
    <div class="col position-relative">
      <input
        [type]="showPassword.repeatpassword ? 'text' : 'password'"
        class="form-control"
        placeholder="Confirmar contraseña"
        aria-label="Confirmar contraseña"
        formControlName="repeatpassword"
        autocomplete="new-password" />
      <ng-container
        [ngTemplateOutlet]="renderShowValueBtn"
        [ngTemplateOutletContext]="{
          field: 'repeatpassword',
          id: 'password-register-confirm'
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'repeatpassword' }"></ng-container>
    </div>

    <!-- Input Terminos y Condiciones -->
    <div class="col">
      <label class="container-check">
        Al continuar, acepto los Términos de servicio y la Política de
        privacidad
        <input type="checkbox" formControlName="term" />
        <span class="checkmark"></span>
      </label>

      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'term' }"></ng-container>
    </div>

    <div class="col mt-4">
      <ng-container *ngIf="!loading; else renderLoadingBtn">
        <button type="submit" class="btn" (click)="onSubmit()">
          <span> Crear cuenta</span>
        </button>
      </ng-container>
    </div>
  </div>
</form>

<ng-template #renderLoadingBtn>
  <button type="submit" class="btn" disabled>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</ng-template>

<ng-template #renderShowValueBtn let-field="field" let-id="id">
  <div
    class="password-view"
    [ngClass]="{ active: !showPassword[field] }"
    [id]="id"
    (click)="showValue(field)">
    <img src="assets/img/Icon_37.png" class="eye" />
    <img src="assets/img/hidden-eye.png" class="eye-hidden" />
  </div>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | titlecase }}
      </span>
    </ng-container>
  </div>
</ng-template>
