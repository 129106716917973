<div class="row mt-5">
  <ng-container
    *ngIf="roles$ | async as roles; else renderLoader"
    [ngTemplateOutlet]="renderOptions"
    [ngTemplateOutletContext]="{ roles: roles }"></ng-container>
</div>

<ng-template #renderOptions let-roles="roles">
  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
    <div class="card card-admin mb-3">
      <div class="card-body">
        <h5 class="card-title">Configuración</h5>
        <p class="card-text mb-0">administrador</p>
        <a class="btn btn-orange-admin" routerLink="/admin/vendor">Vamos</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderLoader>
  <div
    class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3"
    *ngFor="let item of [1, 1, 1, 1, 1, 1]">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title placeholder-glow">
          <span class="placeholder col-12"></span>
        </h5>
        <p class="card-text mb-0 placeholder-glow">
          <span class="placeholder col-12"></span>
        </p>
        <a
          href="#"
          tabindex="-1"
          class="btn btn-primary disabled placeholder col-6"></a>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-container *ngIf="isAdminMarkte == true">
        <div class="col-12 col-sm-6 col-lg-4 mb-3">
            <div class="card" routerLink="/admin/marketPlace">
                <div class="i-card">
                    <div class="title fw-bold">
                        MarketPlace
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isAdminVendor == true">
        <div class="col-12 col-sm-6 col-lg-4 mb-3">
            <div class="card" routerLink="/admin/vendor">
                <div class="i-card">
                    <div class="title fw-bold">
                        Vendor
                    </div>
                </div>
            </div>
        </div>
    </ng-container> -->
<!-- <ng-template #user>
    <ng-container *ngIf="isUser == true">
        <div class="col-12 col-sm-6 col-lg-4 mb-3">
            <div class="card-dashboard" routerLink="/admin/userSwap">
                <div class="i-card mt-3">
                    <div class="title fw-bold">
                        {{ "home-dashboard.admin-panel.user" | translate }}AP
                    </div>
                    <div class="i-title">
                        {{ "home-dashboard.admin-panel.action" | translate  }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template> -->
