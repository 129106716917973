import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from '../contract/web3.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class AlertStepsService {

  public title = environment.projectName;

  constructor(
    private web3Srv: Web3Service,
  ) { }


  async showStepsGeneral(options: StepGeneralParams): Promise<StepGeneralResponse> {
    const {
      askMessage = 'Execute this action?',
      approveMessage = 'Are you sure you want to execute this action?',
      method,
      params = null,
      service,
      confirmButtonText = 'Yes',
      cancelButtonText = 'No',
      showCancelButton = true
    } = options;

    const steps = ['1', '2'];
    const stepResponse = {
      step: 0,
      data: { message: "Transaction canceled" },
      status: false,
    };

    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: askMessage,
      currentProgressStep: 0,
    });

    if (!ask) { return stepResponse; }

    let transactionRecord: any;

    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: approveMessage,
      currentProgressStep: 1,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading();
          Queue.enableInput();
          // Queue.clickConfirm();

          const transaction = (params)
            ? await service[method](...params)
            : await service[method]();

          transactionRecord = transaction;

          // console.log({ transaction });
          return Queue.clickConfirm();

        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!transactionStatus) {
      stepResponse.step = 1;
      return stepResponse;
    }

    stepResponse.step = 1;
    stepResponse.data = transactionRecord;
    stepResponse.status = true;

    return stepResponse;
  }

  async showStepsWithApproved(params: StepWithApprovedParams): Promise<StepGeneralResponse> {
    const {
      askMessage = 'Confirm',
      checkBalanceText = 'Checking balance',
      checkBalanceParams,
      approvedParams,
      contractParams,
      confirmButtonText = 'Yes',
      cancelButtonText = 'No',
      showCancelButton = true,
      service,
    } = params;
    const steps = ['1', '2', '3', '4'];

    const stepResponse = {
      step: 0,
      data: { message: "Transaction canceled" },
      status: false,
    };

    /**
     * Construir modal base
     */
    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: askMessage,
      currentProgressStep: 0,
    });

    if (!ask) { return stepResponse; }

    /** Validar balance del usuario */
    const { value: checkUserBalance } = await Queue.fire({
      title: this.title,
      text: checkBalanceText,
      currentProgressStep: 1,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading();
          Queue.enableInput();

          const { contract, amount } = checkBalanceParams;
          const checkBalance = await this.web3Srv.erc20_checkUserBalance(contract, amount);

          if (checkBalance) {
            return Queue.clickConfirm();
          }

          return Queue.clickCancel();
        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!checkUserBalance) {
      stepResponse.step = 1;
      stepResponse.data = { message: "Insufficient funds to perform the operation. Please check your balance and try again." };
      return stepResponse;
    }

    /** Aprovar manipulación de fondos del usuario hacia el SC */
    const { value: approved } = await Queue.fire({
      title: this.title,
      text: "We need your approval to withdraw the funds from your account before continuing",
      currentProgressStep: 2,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        Queue.showLoading();

        try {
          const approve = await this.web3Srv.erc20_approve(
            approvedParams.contract,
            approvedParams.spender,
            approvedParams.amount
          );
          console.log({ approve });

          if (!approve) {
            return Queue.clickCancel();
          }

          return Queue.clickConfirm();
        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!approved) {
      stepResponse.step = 2;
      return stepResponse;
    }

    /**
     * Solicitar firma en la transacción
     */
    let transactionRecord: any;
    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: "Please sign the transaction to continue",
      currentProgressStep: 3,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading();
          Queue.enableInput();

          const { method, params } = contractParams;
          const transaction = (params)
            ? await service[method](...params)
            : await service[method]();

          transactionRecord = transaction;

          console.log({ transaction });
          return Queue.clickConfirm();
        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!transactionStatus) {
      stepResponse.step = 3;
      return stepResponse;
    }

    /** Retornar resultado de la transacción */
    return { step: 3, data: transactionRecord, status: true };
  }

  async showStepsNative(params: StepNativeParams): Promise<StepGeneralResponse> {
    const {
      askMessage = 'Confirm',
      checkBalanceText = 'Checking balance',
      checkBalanceParams,
      contractParams,
      confirmButtonText = 'Yes',
      cancelButtonText = 'No',
      showCancelButton = true,
      service
    } = params;
    const steps = ['1', '2', '3'];

    const stepResponse = {
      step: 0,
      data: { message: "Transaction canceled" },
      status: false,
    };

    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText,
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' },
    });

    /**
     * Confirmar peticion de ejecutar transacción
     */
    const { isConfirmed: ask } = await Queue.fire({
      title: this.title,
      text: askMessage,
      currentProgressStep: 0,
    });

    if (!ask) { return stepResponse; }

    /**
     * Validar balance del usuario
     */
    const [account] = this.web3Srv.accounts;
    const { value: checkUserBalance } = await Queue.fire({
      title: this.title,
      text: checkBalanceText,
      currentProgressStep: 1,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading();
          Queue.enableInput();
          
          const checkBalance = await this.web3Srv.checkUserBalanceNative(checkBalanceParams.amount);
          // console.log({ checkBalance });
          if (checkBalance) { return Queue.clickConfirm(); }

          return Queue.clickCancel();
        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!checkUserBalance) {
      stepResponse.step = 1;
      stepResponse.data = { message: "Insufficient funds to perform the operation. Please check your balance and try again." };
      return stepResponse;
    }

    /**
     * Solicitar firma en la transacción
     */
    let transactionRecord: any;
    const { value: transactionStatus } = await Queue.fire({
      title: this.title,
      text: "Please sign the transaction to continue",
      currentProgressStep: 2,
      // backdrop: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: async () => {
        try {
          Queue.showLoading();
          Queue.enableInput();

          const { method, params } = contractParams;
          const transaction = (params)
            ? await service[method](...params)
            : await service[method]();

          transactionRecord = transaction;

          // console.log({ transaction });
          return Queue.clickConfirm();
        } catch (err) {
          return Queue.clickCancel();
        }
      },
    });

    if (!transactionStatus) {
      stepResponse.step = 2;
      return stepResponse;
    }

    /** Retornar resultado de la transacción */
    return { step: 2, data: transactionRecord, status: true };
  }

}


export interface StepGeneralParams {
  askMessage?: string;
  approveMessage?: string;
  method: string;
  params?: any[] | null;
  service: any,
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
};

export interface StepGeneralResponse {
  step: number;
  data: any;
  status: boolean;
}

export interface StepWithApprovedParams {
  service: any,
  askMessage?: string;
  checkBalanceText?: string;
  checkBalanceParams: {
    contract: string;
    amount: string;
  };
  approvedParams: {
    contract: string;
    spender: string;
    amount: string;
  };
  contractParams: {
    method: string;
    params: any[] | null;
  };
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
}

export interface StepNativeParams {
  service: any,
  askMessage?: string;
  checkBalanceText?: string;
  checkBalanceParams: {
    amount: string;
  };
  contractParams: {
    method: string;
    params: any[] | null;
  };
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
}
