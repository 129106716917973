import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { cmIsValidAddress } from "src/app/helpers/custom-validations.helper";
import { HdWalletService } from "src/app/services/api/hd-wallet.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserService } from "src/app/services/firebase/user.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { TemplateService } from "src/app/services/template.service";
import { Observable, map } from "rxjs";
import { Web3Service } from "src/app/services/contract/web3.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";

@Component({
  selector: "app-sidebar-config-user-code",
  templateUrl: "./sidebar-config-user-code.component.html",
  styleUrls: ["./sidebar-config-user-code.component.css"],
})
export class SidebarConfigUserCodeComponent implements OnInit, OnDestroy {
  /** Opcion elegida en el formulario */
  public optionSelected: any; // null: no seleccionado, 1 || new: nueva wallet, 2 || own: wallet existente

  /** Bloquear botones */
  public blockButtons: boolean = false;

  /** Estatus de la cuenta de wallet */
  public dataStatus$!: Observable<any>;

  public userDoc: any;
  private sub$!: Subscription;
  public mobile: any;

  constructor(
    private authSrv: AuthenticationService,
    private hdWalletSrv: HdWalletService,
    private web3Srv: Web3Service,
    public templateSrv: TemplateService,
    private spinner: NgxSpinnerService,
    private userSrv: UserService,
    private sweetAlert2Srv: Sweetalert2Service,
    private mediaQuery: MediaQueriesService
  ) {}

  ngOnInit(): void {
    /** Escuchar wallet */
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data && data.length > 0 ? data[0] : null))
    );
    this.sub$ = this.authSrv.userDoc$.subscribe((doc) => {
      this.userDoc = doc;
      console.log(doc);
    });

    /** Escuchar cambios en mediaqueries de la pantalla */
    this.sub$.add(
      this.mediaQuery.mediaQuery$.subscribe((res: any) => {
        // console.log("mediaQueries", res);
        // console.log(res.width);

        this.mobile = res.width <= 1199.98;
        // console.log("mobile", this.mobile);
      })
    );
  }

  /**
   * @dev Conectar cuenta de wallet
   */
  connectAccount() {
    this.web3Srv.launchAskConnectionType();
  }

  /**
   * @dev Desconectar cuenta de wallet
   */
  disconnectAccount() {
    this.web3Srv.logout();
  }

  /**
   * @dev Registrar wallet propia
   * @param addr          Dirección de la wallet a registrar
   * @returns
   */
  async setOwnWallet(addr: string) {
    // console.log('setOwnWallet', addr);
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "¿Está seguro de registrar esta wallet a tu cuenta?, una vez realizada la acción no se puede deshacer"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();
      this.blockButtons = true;

      /** Validar si la wallet a registrar ya se encuentra asociada a otra cuenta */
      const findWallet: any[] = await this.authSrv.getDynamicToPromise([
        { field: "walletAddress", condition: "==", value: addr },
      ]);
      // console.log("findWallet", findWallet);

      if (findWallet.length > 0) {
        this.sweetAlert2Srv.showError(
          "La wallet ya se encuentra registrada en el sistema"
        );
        return;
      }

      /** Obtener ID del usuario a través de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      // console.log("UID", uid);

      /** Actualizar dirección de billetera del usuario */
      await this.authSrv.update(uid, {
        createWallet: false,
        walletAddress: addr,
      });
      this.templateSrv.setDefaultSidebarOption();

      this.sweetAlert2Srv
        .showSuccess("Se ha registrado la wallet correctamente")
        .then(() => {
          /** Cerrar sesión web3 luego de cerrar la alerta */
          this.disconnectAccount();
        });

      return;
    } catch (err) {
      console.log(
        "Error on ProfileRegisterWalletFormComponent.setOwnWallet",
        err
      );
      return;
    } finally {
      this.spinner.hide();
      this.blockButtons = false;
    }
  }

  /**
   * @dev Crear nueva wallet custodio para el usuario
   * @returns
   */
  async setNewWallet() {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "¿Está seguro de crear una nueva wallet?, una vez realizada la acción no se puede deshacer"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();
      this.blockButtons = true;

      /** Obtener ID del usuario a través de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      console.log("UID", uid);

      /** Crear nueva wallet custodio para el usuario */
      const response = await this.hdWalletSrv.createWalletCustodio({ uid });
      console.log("snapshot", response);
      const sendEmailInformationWallet =
        await this.hdWalletSrv.requestWalletCustodioInfo({ uid });
      console.log("email", sendEmailInformationWallet);

      this.templateSrv.setDefaultSidebarOption();

      this.sweetAlert2Srv
        .showSuccess("Se ha registrado la wallet correctamente")
        .then(() => {
          /** Cerrar sesión web3 luego de cerrar la alerta */
          this.disconnectAccount();
        });

      return;
    } catch (err) {
      console.log(
        "Error on ProfileRegisterWalletFormComponent.setNewWallet",
        err
      );
      return;
    } finally {
      this.spinner.hide();

      this.blockButtons = false;
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
