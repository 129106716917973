<section class="how-it-works mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="title text-center mb-5">Cómo funciona</h2>
      </div>

      <div class="col-12 bg-img-how-it-works">
        <div class="row d-none d-lg-flex">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="how-it-works-item one">
              <h5 class="top-number">01.</h5>
              <span>Regístrate</span>
              <p>
                Primero, regístrate en nuestra plataforma. Es rápido y sencillo
                pero se requiere verificar tu identidad para garantizar
                seguridad.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="how-it-works-item three">
              <h5 class="top-number">03.</h5>
              <span>Selecciona la cantidad de fracciones</span>
              <p>
                Selecciona la cantidad de fracciones que deseas comprar y elige
                tu método de pago.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="how-it-works-item five">
              <h5 class="top-number">05.</h5>
              <span>Recibe rendimientos</span>
              <p>
                Recibe rendimientos periódicos según las condiciones de cada
                proyecto.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-end position-relative d-none d-lg-flex">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="how-it-works-item two">
              <div class="d-flex align-items-center">
                <h5>02.</h5>
                <span>Selecciona un proyecto </span>
              </div>

              <p>
                Cada uno tiene su propia descripción detallada para ayudarte a
                tomar una decisión informada.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="how-it-works-item four">
              <div class="d-flex align-items-center">
                <h5>04.</h5>
                <span>Transfiere fondos</span>
              </div>

              <p>
                Una vez verificada la transferencia, aparecerán en tu cuenta la
                transacción.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="how-it-works-item six">
              <div class="d-flex align-items-center">
                <h5>06.</h5>
                <span>Obtén liquidez</span>
              </div>

              <p>
                Necesitas recuperar tu inversión? Nuestro mercado secundario te
                lo permite en cualquier momento del proyecto.
              </p>
            </div>
          </div>
        </div>

        <div class="row d-flex d-lg-none">
          <div class="col-12 col-md-6">
            <div class="how-it-works-item one">
              <h5 class="top-number">01.</h5>
              <span>Regístrate</span>
              <p>
                Primero, regístrate en nuestra plataforma. Es rápido y sencillo
                pero se requiere verificar tu identidad para garantizar.
                seguridad
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 mt-4 mt-md-0">
            <div class="how-it-works-item two">
              <div>
                <h5 class="top-number">02.</h5>
                <span>Selecciona un proyecto </span>
              </div>

              <p>
                Cada uno tiene su propia descripción detallada para ayudarte a
                tomar una decisión informada
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 mt-4">
            <div class="how-it-works-item three">
              <h5 class="top-number">03.</h5>
              <span>Selecciona la cantidad de fracciones</span>
              <p>
                Selecciona la cantidad de fracciones que deseas comprar y elige
                tu método de pago.
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 mt-4">
            <div class="how-it-works-item four">
              <div>
                <h5 class="top-number">04.</h5>
                <span>Transfiere fondos</span>
              </div>

              <p>
                Una vez verificada la transferencia, aparecerán en tu cuenta la
                transacción.
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 mt-4">
            <div class="how-it-works-item five">
              <h5 class="top-number">05.</h5>
              <span>Recibe rendimientos</span>
              <p>
                Recibe rendimientos periódicos según las condiciones de cada
                proyecto.
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 mt-4">
            <div class="how-it-works-item six">
              <div>
                <h5 class="top-number">06.</h5>
                <span>Obtén liquidez</span>
              </div>

              <p>
                Necesitas recuperar tu inversión? Nuestro mercado secundario te
                lo permite en cualquier momento del proyecto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
