import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { toWei } from "src/app/helpers/utils";
import { environment } from "src/environments/environment";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { DistributionContractService } from "src/app/services/contract/distribution-contract.service";

@Component({
  selector: "app-withdraw-native-distributions",
  templateUrl: "./withdraw-native-distributions.component.html",
  styleUrls: ["./withdraw-native-distributions.component.css"],
})
export class WithdrawNativeDistributionsComponent implements OnInit {
  public form: FormGroup;
  public vm = {
    amount: [
      { type: "required", message: "Es obligatorio" },
      { type: "min", message: "Debe ser mayor a 0" },
    ],
  };
  public submitted = false;
  public nativeToken = environment.chain.nativeCurrency.name;
  public deadWallet = environment.addressdead;
  public contract = environment.distributionAddress;

  constructor(
    public fb: FormBuilder,
    public distributionContractSrv: DistributionContractService,
    private alertStepsSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.form = fb.group({
      amount: ["", [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        service: this.distributionContractSrv,
        askMessage: `¿Retirar ${_data.amount} ${this.nativeToken}?`,
        method: "distribution_withdraw_withdraw",
        params: [toWei(_data.amount, 18)],
      });

      if (!result.status) {
        return await this.sweetAlert2Srv.showError(result.data.message);
      } else {
        return this.sweetAlert2Srv
          .showSuccess("Retiro exitoso")
          .then((result) => {
            this.submitted = false;
            this.form.patchValue({ amount: "" });
          });
      }
    } catch (err) {
      console.log("Error on WithdrawNativeComponent@onSubmit", err);
    }
  }
}
