<div class="proyects-list mb-4 active">
  <ng-container
    *ngIf="proyectsList; else renderLoaderList"
    [ngTemplateOutlet]="renderCheckList"
    [ngTemplateOutletContext]="{ list: proyectsList }"></ng-container>
</div>

<ng-template #renderProjectList let-list="list">
  <ng-container
    *ngFor="let item of list"
    [ngTemplateOutlet]="
      item.status !== 'preview' ? renderRegularCardItem : renderPreviewCardItem
    "
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<ng-template #renderRegularCardItem let-item="item">
  <div class="proyect-list-item mb-4" (click)="onSelectProject(item)">
    <div class="new-tag" *ngIf="item.isNew">NEW</div>
    <img [src]="item.thumbnails" class="img-fluid proyect-list-img" alt="" />
    <div class="proyect-list-item-texts">
      <h5>{{ item.projectName | uppercase }}</h5>
      <p>*Rentabilidad estimada</p>
      <div class="d-flex align-items-center justify-content-between">
        <span>Saber el estatus de esta propiedad</span>
        <img
          src="assets/img/arrow-circle-orange.png"
          class="img-fluid icon-list"
          alt="" />
        <img
          src="assets/img/arrow-circle-white.png"
          class="img-fluid icon-list white"
          alt="" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderPreviewCardItem let-item="item">
  <div class="proyect-list-item draft mb-4" (click)="onSelectProject(item)">
    <div class="d-flex align-items-center">
      <img src="assets/img/hourglass.png" alt="" />
      <span>Coming Soon</span>
    </div>
    <p>{{ item.projectName | titlecase }}</p>
  </div>
</ng-template>

<ng-template #renderCheckList let-list="list">
  <ng-container
    *ngIf="list.length > 0; else renderNoRecordsJet"
    [ngTemplateOutlet]="renderProjectList"
    [ngTemplateOutletContext]="{ list: list }"></ng-container>
</ng-template>

<ng-template #renderNoRecordsJet>
  <!-- <div class="proyect-list-item mb-4">
        <img src="assets/img/loaderListItem.png" class="img-fluid proyect-list-img" alt="" />
        <div class="proyect-list-item-texts">
            <h5>No hay registros</h5>
            <p>&nbsp;</p>
            <div class="d-flex align-items-center justify-content-between">
                <span>&nbsp;</span>

                <img src="assets/img/Icon_11.png" class="img-fluid icon-list" alt="" />
            </div>
        </div>
    </div> -->
  <div class="proyect-list-item draft mb-4">
    <div class="d-flex align-items-center">
      <img src="assets/img/hourglass.png" alt="" />
      <span>Coming Soon</span>
    </div>
    <p>Obra de arte Picasso</p>
  </div>
</ng-template>

<ng-template #renderLoaderList>
  <ng-container
    [ngTemplateOutlet]="renderLoaderItem"
    *ngFor="let item of [1, 1, 1]"></ng-container>
</ng-template>

<ng-template #renderLoaderItem>
  <div class="proyect-list-item mb-4">
    <img
      src="assets/img/loaderListItem.png"
      class="img-fluid proyect-list-img"
      alt="" />
    <div class="proyect-list-item-texts">
      <h5 class="placeholder-glow">
        <span class="placeholder col-10" style="height: 20px"></span>
      </h5>
      <p class="placeholder-glow">
        <span class="placeholder col-8" style="height: 16px"></span>
      </p>
      <div class="placeholder-glow">
        <span class="placeholder col-10" style="height: 16px"></span>
      </div>
    </div>
  </div>
</ng-template>
