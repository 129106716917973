import { Component, OnInit } from "@angular/core";
import { Web3Service } from "src/app/services/contract/web3.service";
import { catchError, map, Observable, of, switchMap } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-admin",
  templateUrl: "./header-admin.component.html",
  styleUrls: ["./header-admin.component.css"],
})
export class HeaderAdminComponent implements OnInit {
  public dataStatus$!: Observable<any>;

  constructor(private web3Srv: Web3Service, private router: Router) {}

  ngOnInit() {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (addr: any) => {
        if (!addr)
          return {
            addr: null,
            access: false,
          };

        const [vendorRoles] = await Promise.all([
          this.web3Srv.vendor_administered_getRoles(addr),
        ]);

        return {
          addr,
          access: Object.values(vendorRoles).some((v) => v), // [true, false] => [true, true] => true
          ...vendorRoles,
        };
      }),
      catchError((err) => {
        return of({
          addr: null,
          access: false,
        });
      })
    );
  }

  async launch() {
    return this.web3Srv.launchAskConnectionType();
  }

  async logOut() {
    this.router.navigate(["/admin/dashboard"]).then(() => {
      this.web3Srv.logout();
    });
  }
}
