import { Component, OnInit, ViewChild } from "@angular/core";
import { SwiperComponent } from "swiper/angular";
// import Swiper core and required modules
import SwiperCore, {
  Swiper,
  Virtual,
  SwiperOptions,
  Autoplay,
  Pagination,
} from "swiper";

// install Swiper modules
SwiperCore.use([Virtual, Autoplay, Pagination]);
@Component({
  selector: "app-approval-processes",
  templateUrl: "./approval-processes.component.html",
  styleUrls: ["./approval-processes.component.css"],
})
export class ApprovalProcessesComponent implements OnInit {
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1199: {
        slidesPerView: 2.7,
        spaceBetween: 70,
      },
      1400: {
        slidesPerView: 2.7,
        spaceBetween: 100,
      },
    },
  };

  constructor() {}

  ngOnInit() {}

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }
}
