import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { toWei } from "src/app/helpers/utils";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { SaleDistributionContractService } from "src/app/services/contract/sale-distribution-contract.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-withdraw-native-sale-distribution",
  templateUrl: "./withdraw-native-sale-distribution.component.html",
  styleUrls: ["./withdraw-native-sale-distribution.component.css"],
})
export class WithdrawNativeSaleDistributionComponent implements OnInit {

  public form: FormGroup;
  public vm = {
    amount: [
      { type: "required", message: "Es obligatorio" },
      { type: "min", message: "Debe ser mayor a 0" },
    ],
  };
  public submitted = false;
  public nativeToken = environment.chain.nativeCurrency.name;
  public deadWallet = environment.addressdead;
  public contract = environment.saleDistributionAddress;

  constructor(
    public fb: FormBuilder,
    private saleDistributionContractSrv: SaleDistributionContractService,
    private alertStepSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service,
  ) {
    this.form = fb.group({
      amount: ["", [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepSrv.showStepsGeneral({
        service: this.saleDistributionContractSrv,
        askMessage: `¿Retirar ${_data.amount} ${this.nativeToken}?`,
        method: "withdraw_withdrawCrypto",
        params: [toWei(_data.amount, 18)]
      });

      if (!result.status) {
        this.sweetAlert2Srv.showError(result.data.message);
        return;
      } 

      this.submitted = false;
      this.form.patchValue({ amount: "" });
      this.sweetAlert2Srv.showSuccess("Retiro exitoso");
      return;

    } catch (err) {
      console.log("Error on WithdrawNativeSaleDistributionComponent@onSubmit", err);
    }
  }
}
