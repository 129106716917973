<div class="proyects-availables mt-4">
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-lg-3">
      <div class="proyects-availables-percents">
        <div id="myProgress">
          <div id="myBar" [ngStyle]="{ width: supplyPercentTotal }"></div>
        </div>
        <div class="myProgress-text">
          <span>{{ supplyPercentTotal }}</span>
          <span>100%</span>
        </div>
        <div class="proyects-availables-percents-item">
          <span>Fracciones Restantes</span>
          <p>
            <ng-container
              *ngIf="erc721Info$ | async as data; else renderCeroValue"
              [ngTemplateOutlet]="renderObsValue"
              [ngTemplateOutletContext]="{
                data: data,
                field: 'supplyLeft'
              }"></ng-container>
          </p>
        </div>

        <div class="proyects-availables-percents-item">
          <span>Valor Fracción</span>
          <p>
            <ng-container *ngIf="item.addr; else renderCeroValue">
              {{ (price$ | async)?.price | number : "1.0-0" }}
            </ng-container>
            USD
          </p>
        </div>

        <div class="proyects-availables-percents-item">
          <span>Rentabilidad Estimada</span>
          <p>{{ item?.estimatedProfitability || 0 }}%*</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-9">
      <div class="proyects-availables-texts">
        <h6>Descripción del proyecto</h6>
        <p [innerHTML]="item.description"></p>

        <ng-container
          *ngIf="
            item.status == 'preview' ||
              item.status == 'draft' ||
              item.status == 'closed';
            then renderComingSoonButton
          "></ng-container>
        <ng-container
          *ngIf="
            item.status == 'opening';
            then renderBuyCollectionButtons
          "></ng-container>
        <ng-container
          *ngIf="
            item.status == 'soldOut';
            then renderSoldOutButton
          "></ng-container>

        <div
          *ngIf="!openForm"
          class="d-flex align-items-center justify-content-between mt-4">
          <a
            class="legal cursor-pointer"
            (click)="openLink(item?.legalDocumentation)"
            >DOCUMENTACIÓN LEGAL</a
          >
          |
          <a class="legal cursor-pointer" (click)="openLink(item?.legalProcess)"
            >PROCESO LEGAL</a
          >
          <a class="pdf cursor-pointer" (click)="openLink(item?.pdfDocument)">
            <img src="assets/img/pdf-orange.png" class="img-fluid" alt="" />
            Descargar PDF
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderBuyButtons>
  <button *ngIf="!openForm" class="btn btn-purple" (click)="previewCard()">
    Comprar Fracciones con tarjeta de crédito
  </button>

  <div class="d-flex flex-column w-100">
    <button #formBuyButton class="btn btn-buy" (click)="openBuyForm()">
      Comprar Fracciones con criptomonedas
    </button>
    <div *ngIf="openForm" class="mb-3">
      <app-form-buy-propertie [dataId]="item.addr"></app-form-buy-propertie>
    </div>
  </div>
</ng-template>

<ng-template #renderUid>
  <p class="mb-2">Inicia Sesión para Comprar</p>
</ng-template>

<ng-template #renderBuyCollectionButtons>
  <div class="btns-proyects-view">
    <ng-container
      *ngIf="uid$ | async; else renderUid"
      [ngTemplateOutlet]="renderBuyButtons"></ng-container>
    <button *ngIf="!openForm" class="btn btn-orange">CONOCE MÁS</button>
  </div>
</ng-template>

<ng-template #renderSoldOutButton>
  <div class="btns-proyects-view">
    <button class="btn btn-purple">Sold Out</button>
  </div>
</ng-template>

<ng-template #renderComingSoonButton>
  <div class="btns-proyects-view">
    <button class="btn btn-purple">Próximamente...</button>
  </div>
</ng-template>

<ng-template #renderObsValue let-data="data" let-field="field">
  {{ data[field] }}
</ng-template>

<ng-template #renderCeroValue>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>
