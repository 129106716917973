import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-white-list-token',
  templateUrl: './white-list-token.component.html',
  styleUrls: ['./white-list-token.component.css']
})
export class WhiteListTokenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
