import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { TemplateService } from "src/app/services/template.service";

@Component({
  selector: "app-user-profile-template",
  templateUrl: "./user-profile-template.component.html",
  styleUrls: ["./user-profile-template.component.css"],
})
export class UserProfileTemplateComponent implements OnInit, OnDestroy {
  public profile: any;
  public userDoc$!: Observable<null | any>;
  private sub$!: Subscription;
  public mobile: any;

  constructor(
    private authSrv: AuthenticationService,
    public templateSrv: TemplateService,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private mediaQuery: MediaQueriesService
  ) {
    this.profile = this.authSrv.getLocalProfile();
  }

  ngOnInit() {
    this.userDoc$ = this.authSrv.userDoc$;

    /** Escuchar cambios en mediaqueries de la pantalla */
    this.sub$ = this.mediaQuery.mediaQuery$.subscribe((res: any) => {
      this.mobile = res.width <= 1199.98;
    });
  }

  openTab(evt: any, item: string, ub?: string) {
    let i: any, active: any;
    console.log(1);

    // Show the current tab, and add an "active" class to the button that opened the tab
    let show = document.getElementById(item);
    if (show?.classList.contains("flex")) {
      show?.classList.remove("flex");
      this.templateSrv.events$.next({
        type: "menu-profile-mobile",
        data: false,
      });
    } else {
      show?.classList.add("flex");
      this.templateSrv.events$.next({
        type: "menu-profile-mobile",
        data: true,
      });
    }

    evt.currentTarget.classList.toggle("active");
  }

  async modalNotificationOpen() {
    let modal = document.getElementById("modalNotification");
    modal?.classList.add("show");
    modal?.classList.add("d-flex");
  }

  goToProfile() {
    this.templateSrv.setSidebarOption("profile");
  }

  goToConfigWallet() {
    this.templateSrv.setSidebarOption("config-code");
  }

  goToProperties() {
    this.templateSrv.setSidebarOption("my-properties");
  }

  async logOut() {
    try {
      this.spinner.show();
      await this.authSrv.logout();
      return this.templateSrv.setDefaultSidebarOption();
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.sweetAlert2Srv.showError(error);
    } finally {
      this.spinner.hide();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
