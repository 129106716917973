import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advantages-real',
  templateUrl: './advantages-real.component.html',
  styleUrls: ['./advantages-real.component.css']
})
export class AdvantagesRealComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
