import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { SecurePasswordComponent } from "../shared/form/secure-password/secure-password.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";

const routes: Routes = [
  // {
  //   path: "home",
  //   component: HomeComponent,
  // },
  // {
  //   path: "secure",
  //   component: SecurePasswordComponent,
  // },
  {
    path: "coming-soon",
    component: ComingSoonComponent,
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "/pages/coming-soon",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
