import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { HomeComponent } from "./home/home.component";
import { HomeInvestmentComponent } from "./home/components/home-investment/home-investment.component";
import { ProjectsYourProfileComponent } from "./home/components/projects-your-profile/projects-your-profile.component";
import { HowItWorksComponent } from "./home/components/how-it-works/how-it-works.component";
import { AdvantagesRealComponent } from "./home/components/advantages-real/advantages-real.component";
import { HomeApprovalProcessesComponent } from "./home/components/home-approval-processes/home-approval-processes.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";

@NgModule({
  declarations: [
    HomeComponent,
    HomeInvestmentComponent,
    ProjectsYourProfileComponent,
    HowItWorksComponent,
    AdvantagesRealComponent,
    HomeApprovalProcessesComponent,
    ComingSoonComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule],
})
export class PagesModule {}
