import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminRoutingModule } from "./admin-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PipesModule } from "../pipes/pipes.module";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WhiteListTokenComponent } from "./pages/white-list-token/white-list-token.component";
import { VendorAdminModule } from "./vendor-admin/vendorAdmin.module";
import { WithdrawalsAdminComponent } from "./pages/withdrawals-admin/withdrawals-admin.component";

@NgModule({
  declarations: [
    DashboardComponent,
    WhiteListTokenComponent,
    WithdrawalsAdminComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    PipesModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    VendorAdminModule,
  ],
})
export class AdminModule {}
