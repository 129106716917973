import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/firebase/user.service";
import { MediaFileService } from "src/app/services/media-file.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { TemplateService } from "src/app/services/template.service";
import { InputSingleImageComponent } from "../input-single-image/input-single-image.component";

@Component({
  selector: "app-sidebar-profile-form",
  templateUrl: "./sidebar-profile-form.component.html",
  styleUrls: ["./sidebar-profile-form.component.css"],
})
export class SidebarProfileFormComponent implements OnInit, OnDestroy {
  @ViewChild(InputSingleImageComponent) inputFile!: InputSingleImageComponent;
  public userDoc: any;
  public form: FormGroup;
  public vm: any = {
    name: [{ type: "required", message: "El nombre es requerido" }],
    prefix: [{ type: "required", message: "El prefijo es requerido" }],
    phoneNumber: [
      { type: "required", message: "El número de teléfono es requerido" },
    ],
  };
  public submitted = false;
  public country: any[];

  private sub$!: Subscription;
  public mobile: any;

  public imgUpdate: any = false;

  constructor(
    private authSrv: AuthenticationService,
    public templateSrv: TemplateService,
    private fb: FormBuilder,
    private dataSrv: DataService,
    private spinner: NgxSpinnerService,
    private userSrv: UserService,
    private sweetAlert2Srv: Sweetalert2Service,
    private mediaQuery: MediaQueriesService,
    private mediaFileSrv: MediaFileService
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      prefix: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
    });

    this.country = this.dataSrv.getCountry();
  }

  ngOnInit(): void {
    this.sub$ = this.authSrv.userDoc$.subscribe((doc) => {
      this.userDoc = doc;

      this.form.patchValue({
        name: doc?.name || "",
        prefix: doc?.prefix || "",
        phoneNumber: doc?.phoneNumber || "",
      });
    });

    /** Escuchar cambios en mediaqueries de la pantalla */
    this.sub$.add(
      this.mediaQuery.mediaQuery$.subscribe((res: any) => {
        this.mobile = res.width <= 1199.98;
      })
    );
  }

  get f() {
    return this.form.controls;
  }

  async onAddImage() {
    this.inputFile.take();
  }

  /**
   * Al seleccionar imagen
   * @param files
   * @returns
   */
  async onSelectImages(files: any = []) {
    try {
      this.imgUpdate = files.toString();
      console.log(this.imgUpdate);

      return;
    } catch (error) {
      console.log("Error on AvatarComponent.onSelectImages", error);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async saveAvatar() {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "¿Estás seguro de actualizar la foto de perfil?"
      );
      if (!ask) {
        return;
      } else {
        await this.spinner.show();
        if (this.imgUpdate) {
          await this.mediaFileSrv.uploadFileWithCustomFoler(
            this.imgUpdate,
            "profile",
            "png"
          );
        } else {
          this.imgUpdate = false;
        }

        let data = {
          avatar: this.imgUpdate,
        };

        await this.authSrv.update(this.userDoc._id, data);

        this.sweetAlert2Srv
          .showSuccess("Se ha actualizado la foto de perfil")
          .then(() => {
            this.templateSrv.setDefaultSidebarOption();
          });
      }
      return;
    } catch (error) {
      console.log("Error on AvatarComponent.onSelectImages", error);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async onSubmit() {
    try {
      this.submitted = true;
      const formData = this.form.value;
      const data = {
        name: `${formData.name}`.trim().toLowerCase(),
        prefix: formData.prefix,
        phoneNumber: `${formData.phoneNumber}`.trim(),
      };
      console.log("Trying to submit form", data);

      if (this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }

      await this.spinner.show();

      /** Actualizar documento de usuario */
      await this.userSrv.update(this.userDoc._id, data);
      this.sweetAlert2Srv.showToast(
        "Perfil actualizado correctamente",
        "success"
      );
      this.templateSrv.setDefaultSidebarOption();
      return;
    } catch (err) {
      console.log("Error on SidebarProfileFormComponent.onSubmit", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
