import { Component, OnInit } from "@angular/core";
import { title } from "process";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.css"],
})
export class FaqsComponent implements OnInit {
  faqs = [
    {
      id: "1",
      title: "1. Pregunta",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, porro! Doloremque ut id ea, eaque rerum excepturi, iste unde error quod voluptas quas! Minus aspernatur sunt, tenetur possimus aliquam error?",
    },
    {
      id: "2",
      title: "2. Pregunta",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, porro! Doloremque ut id ea, eaque rerum excepturi, iste unde error quod voluptas quas! Minus aspernatur sunt, tenetur possimus aliquam error?",
    },
    {
      id: "3",
      title: "3. Pregunta",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, porro! Doloremque ut id ea, eaque rerum excepturi, iste unde error quod voluptas quas! Minus aspernatur sunt, tenetur possimus aliquam error?",
    },
    {
      id: "4",
      title: "4. Pregunta",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, porro! Doloremque ut id ea, eaque rerum excepturi, iste unde error quod voluptas quas! Minus aspernatur sunt, tenetur possimus aliquam error?",
    },
    {
      id: "5",
      title: "5. Pregunta",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, porro! Doloremque ut id ea, eaque rerum excepturi, iste unde error quod voluptas quas! Minus aspernatur sunt, tenetur possimus aliquam error?",
    },
  ];
  constructor() {}

  ngOnInit() {}

  openFaq(event: any, faqId: string) {
    let show = document.getElementById(faqId);
    if (show?.classList.contains("flex")) {
      show?.classList.remove("flex");
    } else {
      show?.classList.add("flex");
    }
    event.currentTarget.classList.toggle("active");
  }
}
