import { Component, OnInit } from "@angular/core";
import { catchError, from, map, Observable, of } from "rxjs";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-whitelist-list",
  templateUrl: "./whitelist-list.component.html",
  styleUrls: ["./whitelist-list.component.css"],
})
export class WhitelistListComponent implements OnInit {
  public pairList$!: Observable<any[]>;
  public nativeCurrency = environment.chain.nativeCurrency;
  public blockExplorerUrls = environment.chain.blockExplorerUrls[0];

  constructor(private vendorContractSrv: VendorContractService) {}

  ngOnInit(): void {
    this.pairList$ = from(
      this.vendorContractSrv.vendor_whitelist_tokensList()
    ).pipe(
      map((data) =>
        data.map((item: any, index: any) => ({ pId: index, ...item }))
      ),
      catchError((err) => of([]))
      // tap((pairList) => console.log('pairList', pairList))
    );
  }

  async openBSCScan(addr: string) {
    const url = `${this.blockExplorerUrls}/address/${addr}`;
    console.log("url", url);
    window.open(url, "_blank");
    return;
  }
}
