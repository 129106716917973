import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from './web3.service';

@Injectable({
  providedIn: 'root'
})
export class SaleDistributionContractService {

  public abi = '/assets/abi/Investok_SaleDistribution.json';

  public contractAddress = environment.saleDistributionAddress;

  constructor(
    private web3Srv: Web3Service
  ) { }

  async administered_isAdmin_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addAdmin(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_isUser_OFFCHAIN(addr: string) {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "isUser",
      callType: "call",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async administered_addUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_removeUser(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      params: null,
      callType: "send",
      urlABI: this.abi,
    });
  }

  async deposit_getDepositCount_OFFCHAIN() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "getDepositCount",
      callType: "call",
      params: null,
      urlABI: this.abi,
    });
  }

  async distribution_runDistribution(records: any[], total: string, token: string, isNative: boolean, value: string = "0") {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "runDistribution",
      params: [
        records,
        total,
        token,
        isNative
      ],
      callType: "send",
      optionals: { value },
      urlABI: this.abi,
    });
  }

  async withdraw_withdrawCrypto(amount: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawCrypto",
      params: [amount],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async withdraw_withdrawToken(token: string, amount: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawToken",
      params: [token, amount],
      callType: "send",
      urlABI: this.abi,
    });
  }

}
