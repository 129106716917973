import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { Observable, Subscription } from "rxjs";
import { CasesService } from "src/app/services/firebase/cases.service";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.css"],
})
export class NewsletterComponent implements OnInit {
  public uid$!: Observable<null | string>;
  private sub$!: Subscription;
  public userDoc$!: Observable<null | any>;
  public form!: FormGroup;
  public submit = false;
  public loading: boolean = false;
  private idUser: any;
  public vm: any = {
    name: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Solamente letras " },
      { type: "minlength", message: "Minimo 2 letras" },
    ],
    email: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Dirección de correo electrónico no válida" },
    ],
    phoneNumber: [
      { type: "required", message: "Se requiere número de teléfono" },
      { type: "pattern", message: "Solo se permiten los números" },
      {
        type: "minlength",
        message: "El número de teléfono debe tener al menos 10 caracteres",
      },
      {
        type: "maxlength",
        message: "El número de teléfono debe tener al menos 10 caracteres",
      },
    ],
    message: [{ type: "required", message: "Requerido" }],
  };

  constructor(
    public fb: FormBuilder,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private casesSrv: CasesService
  ) {
    this.buildForm();
  }

  async ngOnInit() {
    /** Escuchar si existe uid en la sesión */
    this.uid$ = this.authSrv.uid$;

    this.sub$ = this.authSrv.uid$.subscribe(async (res: any) => {
      /** Obtener id usuario */
      if (res) {
        this.idUser = res;
      } else {
        this.idUser = null;
      }
    });
  }

  /**
   * Construir formulario
   */
  buildForm() {
    this.form = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
          Validators.minLength(2),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      message: ["", [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }
  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      console.log("formData", formData);

      if (!this.form.valid) {
        console.log('ew');
        
        this.form.markAllAsTouched();
        return;
      }

      /** Construir documento de notificacion */
      const data = {
        uidUser: this.idUser,
        name: `${formData.name}`.trim().toLowerCase(),
        email: `${formData.email}`.trim().toLowerCase(),
        phoneNumber: `${formData.phoneNumber}`.trim(),
        message: `${formData.message}`.trim().toLowerCase(),
      };

      // /** Registrar notificacion */
      await this.casesSrv.store(data);

      /* alerta de envios*/
      this.sweetAlert2Srv.showSuccess("El envio del correo fue exitoso");
      
      this.submit = false;
      return;
    } catch (err: any) {
      console.log("Error on NewsletterComponent.submitData", err);
      this.sweetAlert2Srv.showError("A ocurrido un error intente de nuevo");
      return;
    } finally {
      this.form.reset()
      this.loading = false;
    }
  }
}
