<div class="row mt-4">
  <div class="col-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/admin/users">
            <i class="bi bi-arrow-left"></i>&nbsp;Inicio
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Criptos a Recibir
        </li>
      </ol>
    </nav>
  </div>

  <div class="col-12 mb-4">
    <h5 class="title-orange">Configuración de Criptos a Recibir</h5>
  </div>

  <div class="col-12 mb-4">
    <h5 class="text-center">Token a Recibir</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-add-token></app-add-token>
  </div>

  <div class="col-12 col-lg-6">
    <app-update-token></app-update-token>
  </div>

  <div class="col-12">
    <app-whitelist-list></app-whitelist-list>
  </div>
</div>
