import { Component, OnInit } from "@angular/core";
import { Observable, Subscription, catchError, map, of } from "rxjs";
import { CollectionService } from "src/app/services/firebase/collection.service";

@Component({
  selector: "app-proyects-view-list-mobile",
  templateUrl: "./proyects-view-list-mobile.component.html",
  styleUrls: ["./proyects-view-list-mobile.component.css"],
})
export class ProyectsViewListMobileComponent implements OnInit {
  public proyectsList$!: Observable<any[]>;
  public proyectsList!: any[];
  private sub$!: Subscription;

  constructor(private collectionSrv: CollectionService) {}

  ngOnInit(): void {
    this.proyectsList$ = this.collectionSrv
      .getDynamic([
        // { field: 'cStatus', condition: '==', value: true}
        { field: "status", condition: "in", value: ["preview", "opening"] },
      ])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            let gallery: any[] = Array.isArray(item.gallery)
              ? item.gallery
              : [];
            // find thumbnails if exists or set defautl value
            const thumbnails =
              gallery.find((item: any) => item.thumbnails)?.url ||
              this.collectionSrv.deafultThumbnails;

            /** Si no tiene archivos de */
            if (gallery.length == 0) {
              gallery = new Array(3).fill({
                type: "image/png",
                url: this.collectionSrv.defaultImage,
                order: 0,
                thumbnails: false,
                size: 0,
              });
              gallery[0].thumbnails = true;
            }

            return {
              ...item,
              statusParsed: this.collectionSrv.collectionStatus.find(
                (status: any) => status.value === item.status
              ),
              thumbnails,
              gallery,
            };
          })
        ),
        // tap((data) => console.log('ProyectsViewListDesktopComponent.proyectsList$', data)),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );
  }

  onSelectProject(item: any) {
    // console.log("onSelectProject", item);
    this.collectionSrv.onViewSelectedCollection$.next({
      action: "selectProject",
      data: item,
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
