import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CollectionService } from "src/app/services/firebase/collection.service";
import SwiperCore, { Swiper, Virtual, SwiperOptions, Autoplay } from "swiper";
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Virtual, Autoplay]);

@Component({
  selector: "app-proyects-view-details-desktop-swiper",
  templateUrl: "./proyects-view-details-desktop-swiper.component.html",
  styleUrls: ["./proyects-view-details-desktop-swiper.component.css"],
})
export class ProyectsViewDetailsDesktopSwiperComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("swiper", { static: false }) swiper!: SwiperComponent;

  @Input() item: any = {
    projectName: "...",
    gallery: new Array(3).fill({
      order: 0,
      size: 0,
      thumbnails: false,
      type: "image/png",
      url: this.collectionSrv.defaultSwiper,
    }),
  };

  public config: SwiperOptions = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  private sub$!: Subscription;

  public imgZoom!: string;

  constructor(private collectionSrv: CollectionService) {}

  ngOnInit(): void {
    this.sub$ = this.collectionSrv.onViewSelectedCollection$.subscribe(
      (res: any) => {
        if (res.action == "selectProject") {
          this.item = res.data;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { item } = changes;
    if (item && item.currentValue) {
      this.item = item.currentValue;
    }
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
    this.imgZoom = "";
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
    this.imgZoom = "";
  }

  selectImgZoom(img: string) {
    if (img) {
      this.imgZoom = img;
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
