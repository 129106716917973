<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 font_thb">Revocar permisos</div>
        <div class="fs-small mb-3">Renunciar a rol de super Administrador</div>
        <div class="d-flex justify-content-start">
            <button type="button" class="btn btn-primary" (click)="onSubmit()">
                Renunciar
            </button>
        </div>
    </div>
</div>