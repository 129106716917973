<div class="position-relative w-100 d-flex" style="height: 60%">
  <swiper
    class="swiper swiper-projects position-relative"
    #swiper
    [config]="config">
    <ng-template swiperSlide *ngFor="let gallery of item.gallery">
      <div
        class="swiper-slide"
        style="background-image: url({{gallery.url}})">
        <div class="swiper-slide-titles">
          <div>
            <h5>{{ item.projectName }}</h5>
            <p>*Rentabilidad estimada</p>
          </div>
          <div
            data-bs-toggle="modal"
            data-bs-target="#img-zoom"
            (click)="selectImgZoom(gallery.url)">
            <img src="assets/img/Icon_5.png" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="swiper-slide-shared">
          <!-- <img src="assets/img/Magic.png" class="img-fluid magic" alt="" /> -->
          <!-- <a href="http://www.facebook.com/sharer.php?u=http://queenoftruffles.com/el-producto.php?id=21" title="Compartir" >Compartir en Face</a> -->
          <!-- <img src="assets/img/Icon_20.png" class="img-fluid shared" alt="" /> -->
        </div>
      </div>
    </ng-template>

    <!-- <ng-template swiperSlide>
            <div class="swiper-slide" style="background-image: url(assets/img/F_1.png)">
                <div class="swiper-slide-titles">
                    <div>
                        <h5>MAGIC WEEDLAND</h5>
                        <p>*Rentabilidad estimada</p>
                    </div>
                    <div>
                        <img src="assets/img/Icon_5.png" class="img-fluid" alt="" />
                    </div>
                </div>
                <div class="swiper-slide-shared">
                    <img src="assets/img/Magic.png" class="img-fluid magic" alt="" />
                    <img src="assets/img/Icon_20.png" class="img-fluid shared" alt="" />
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="swiper-slide" style="background-image: url(assets/img/F_1.png)">
                <div class="swiper-slide-titles">
                    <div>
                        <h5>MAGIC WEEDLAND</h5>
                        <p>*Rentabilidad estimada</p>
                    </div>
                    <div>
                        <img src="assets/img/Icon_5.png" class="img-fluid" alt="" />
                    </div>
                </div>
                <div class="swiper-slide-shared">
                    <img src="assets/img/Magic.png" class="img-fluid magic" alt="" />
                    <img src="assets/img/Icon_20.png" class="img-fluid shared" alt="" />
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="swiper-slide" style="background-image: url(assets/img/F_1.png)">
                <div class="swiper-slide-titles">
                    <div>
                        <h5>MAGIC WEEDLAND</h5>
                        <p>*Rentabilidad estimada</p>
                    </div>
                    <div>
                        <img src="assets/img/Icon_5.png" class="img-fluid" alt="" />
                    </div>
                </div>
                <div class="swiper-slide-shared">
                    <img src="assets/img/Magic.png" class="img-fluid magic" alt="" />
                    <img src="assets/img/Icon_20.png" class="img-fluid shared" alt="" />
                </div>
            </div>
        </ng-template> -->
  </swiper>

  <div class="swiper-button-next" (click)="slideNext()">
    <img src="assets/img/arrow-slider.png" alt="" />
  </div>
  <div class="swiper-button-prev" (click)="slidePrev()">
    <img src="assets/img/arrow-slider.png" alt="" />
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade p-0"
  id="img-zoom"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered position-relative">
    <div class="d-flex flex-column">
      <button
        type="button"
        class="close-x"
        data-bs-dismiss="modal"
        aria-label="Close">
        &times;
      </button>

      <img class="img-fluid" [src]="imgZoom" alt="image slider zoom" />
    </div>
  </div>
</div>
