<section class="faq my-5" id="faq">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-11">
        <h2 class="title mb-2">FAQs</h2>
      </div>

      <div *ngFor="let faq of faqs" class="col-12 col-lg-11 mb-2">
        <div
          class="faq-item d-flex flex-column justify-content-between"
          (click)="openFaq($event, faq.id)">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-bold">{{ faq.title }}</p>
            <div class="icon">
              <img src="assets/img/down-arrow.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div [id]="faq.id" class="tabcontent mb-3">
            <p class="font-medium">
              {{ faq.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
