<section class="home-approval-processes">
  <div class="container">
    <div class="row my-5">
      <div class="col-12">
        <h2 class="title mt-5">
          Conozca el proceso de aprobación <br />
          de cada proyecto
        </h2>
      </div>
      <div class="col-12">
        <app-approval-processes></app-approval-processes>
      </div>
    </div>
  </div>
</section>
