import { Component, OnDestroy, OnInit } from "@angular/core";

import { TemplateService } from "src/app/services/template.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-proyects-view",
  templateUrl: "./proyects-view.component.html",
  styleUrls: ["./proyects-view.component.css"],
})
export class ProyectsViewComponent implements OnInit, OnDestroy {
  mobile: any;

  public showNavbar = true;
  private sub$!: Subscription;

  constructor(private templateSrv: TemplateService) {}

  ngOnInit() {
    var mediaqueryList = window.matchMedia("(max-width:  1199.98px)");

    if (mediaqueryList.matches) {
      console.log("mediaquery js");
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    this.sub$ = this.templateSrv.events$.subscribe((res: any) => {
      const { type, data } = res;
      if (type === "sidebar") {
        this.showNavbar = data;
      }
    });
  }

  openTab(evt: any, item: string) {
    let i: any, active: any;
    // Show the current tab, and add an "active" class to the button that opened the tab
    let show = document.getElementById(item);
    if (show?.classList.contains("flex")) {
      show?.classList.remove("flex");
    } else {
      show?.classList.add("flex");
    }

    evt.currentTarget.classList.toggle("active");
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
