<form [formGroup]="form" novalidate autocomplete="off">
  <div class="row">
    <div class="col-12 col-lg-4">
      <input
        type="text"
        class="form-control"
        placeholder="Nombre"
        aria-label=" name"
        formControlName="name"
      />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'name' }"
      ></ng-container>
    </div>
    <div class="col-12 col-lg-4 mt-4 mt-lg-0">
      <input
        type="email"
        class="form-control"
        placeholder="Email"
        aria-label="email"
        formControlName="email"
      />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"
      ></ng-container>
    </div>
    <div class="col-12 col-lg-4 mt-4 mt-lg-0">
      <input
        type="text"
        class="form-control"
        placeholder="Teléfono"
        aria-label="phone"
        formControlName="phoneNumber"
      />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'phoneNumber' }"
      ></ng-container>
    </div>

    <div class="col-12 col-lg-12 mt-4">
      <div class="form-floating">
        <textarea
          class="form-control"
          placeholder="Mensaje"
          id="floatingTextarea2"
          formControlName="message"
          style="height: 100px"
        ></textarea>
        <ng-container
          [ngTemplateOutlet]="renderVM"
          [ngTemplateOutletContext]="{ field: 'message' }"
        ></ng-container>
      </div>
    </div>
    <div class="col-12 col-lg mt-4 d-flex justify-content-center">
      <ng-container *ngIf="!loading; else renderLoadingBtn">
        <button type="submit" class="btn" (click)="onSubmit()">Enviar</button>
      </ng-container>
    </div>
  </div>
</form>

<ng-template #renderLoadingBtn>
  <button type="submit" class="btn" disabled>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | titlecase }}
      </span>
    </ng-container>
  </div>
</ng-template>
