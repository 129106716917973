import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, catchError, from, map, of } from 'rxjs';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { toWei } from 'src/app/helpers/utils';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { CollectionService } from 'src/app/services/firebase/collection.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-update-collection-form',
  templateUrl: './update-collection-form.component.html',
  styleUrls: ['./update-collection-form.component.css']
})
export class UpdateCollectionFormComponent implements OnInit, OnDestroy{

  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    id: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
    ],
    type: [
      { type: "required", message: "Is Required" },
    ],
    price: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0.00001" },
    ],
    bool: [
      { type: "required", message: "Is Required" },
    ]
  };

  public dataStatus$!: Observable<any>;
  public collections!: any[];

  private sub$!: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
    private collectionSrv: CollectionService
  ) {
    this.form = fb.group({
      id: ['', [Validators.required, Validators.min(0)]],
      type: [1, [Validators.required]],
      price: [0.01, [Validators.required, Validators.min(0.01)]],
      bool:[true, [Validators.required]]
    });
  }
  ngOnInit(): void {
    this.sub$ = from(this.contractService.vendor_collection_getAllCollections())
    .pipe(
      map((data: any[]) => data.map((item: any, id: number) => ({ id, ...item }))),
      catchError((err) => of([]))
    ).subscribe((data: any) => {
      // console.log('collection', data);
      this.collections = data;
    });
  }

  get f(){ return this.form.controls; }

  async onSubmit() {
    this.submitted = true;

    const formData = this.form.value;
    const _data = {
      id: parseInt(formData.id),
      type: parseInt(formData.type),
      price: toWei(formData.price),
      bool: (formData.bool === 'true')
    };

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {

      /** Leer colección seleccionada */
      const collectionSelected = this.collections[_data.id];
      // console.log('collectionSelected', collectionSelected);

      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Estas seguro de modificar esta colección?`,
        contractParams: { 
          method: 'vendor_collection_updateCollection', 
          params: [_data.id, _data.type, _data.price, _data.bool]
        }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        const toUpdate: any = {};
        switch (_data.type) {
          case 1: toUpdate.price = _data.price; break;
          case 2: toUpdate.cStatus = _data.bool; break;
          default: break;
        }

        /** Update Firebase Collecion Document */
        await this.collectionSrv.update(collectionSelected.addr, toUpdate)

        return this.alertStepsSrv.showBasicAlert(`Se modifico exitosamente la colección`)
        .then((result) => { 
          this.submitted = false;
          this.form.patchValue({
            id: '',
            type: 1,
            price: 0,
            bool: true
          });
        });
      }

    } catch (err) {
      console.log('Error on UpdateCollectionFormComponent@onSubmit', err);
    }

  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

}
