import { Component } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, from, map, of } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { CollectionService } from 'src/app/services/firebase/collection.service';
import { MediaFileService } from 'src/app/services/media-file.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.css']
})
export class CollectionListComponent {

  public pairList$!: Observable<any[]>;
  public vendorContract = environment.vendorAddress;
  public blockExplorerUrls = environment.chain.blockExplorerUrls[0];

  constructor(
    private web3Srv: Web3Service,
    private spinner: NgxSpinnerService,
    private collectionSrv: CollectionService,
    private commonSrv: CommonService,
    private alertStepsSrv: Sweetalert2stepsService,
    private mediaFileSrv: MediaFileService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.pairList$ = from(this.web3Srv.vendor_collection_getAllCollections())
    .pipe(
      map((data) => data.map((item: any, index: any) => ({pId: index, ...item}))),
      catchError((err) => of([])),
      // tap((pairList) => console.log('pairList', pairList))
    );
  }

  async openBSCScan(addr: string){
    const url = `${this.blockExplorerUrls}/address/${addr}`;
    console.log('url', url);
    window.open(url, "_blank");
    return;
  }

  async copyToClipboard(text: string){
    try {
      await navigator.clipboard.writeText(text);
      console.log('Copied to clipboard');
      return;

    } catch (err) {
      console.log('Error on CollectionListComponent.copyToClipboard', err);
    }
  }

  async setVendorPermission(addr: string) {
    const addrParsed = this.commonSrv.getAddress(addr);

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Desear darle permiso al contrato vendedor sobre la colección ${addrParsed} para que pueda transferir NFTs de su colección?`,
        contractParams: { 
          method: 'erc721_addRole', 
          params: [addr, this.vendorContract] 
        }
      });

      if(!result.status){
        this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{
        this.alertStepsSrv.showToast(`Se le dió permiso al contrato vendedor sobre la colección ${addrParsed} exitosamente`);
      }
      return;

    } catch (err) {
      console.log('Error on CollectionListComponent@setVendorPermission', err);
      return;
    }
  }

  async loadCollectionOnFB(item: any){
    try {
      console.log('item', item);
      const ask = await this.alertStepsSrv.askConfirm(`¿Deseas cargar la colección ${this.commonSrv.getAddress(item.addr)} en la base de datos?`);
      if(!ask) return;

      await this.spinner.show();

      const erc721aInfo = await this.web3Srv.erc721_getAllInfo(item.addr);
      console.log('erc721aInfo', erc721aInfo);

      /** Capturar hora de creación */
      const createdAt = moment().valueOf();

      /** Crear registro de colección */
      await this.collectionSrv.set(item.addr, {
        active: item.active,
        collectionId: item.pId,
        price: item.price,
        ...erc721aInfo,
        isNew: true,
        projectName: erc721aInfo.name,
        description: null,
        legalDocumentation: null,
        legalProcess: null,
        pdfDocument: null,
        gallery: null,
        estimatedProfitability: 0,
        cStatus: item.active,
        status: 'draft',
        timeline: [{status: 'draft', time: createdAt}],
        createdAt: createdAt,
        updatedAt: null,
        openAt: null,
        soldOutAt: null,
      });

      this.alertStepsSrv.showToast(`Se cargó la colección ${this.commonSrv.getAddress(item.addr)} exitosamente`);
      return;
      
    } catch (err) {
      console.log('Error on CollectionListComponent.loadCollectionOnFB', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async removeCollection(item: any){
    try {
      // console.log('item', item);
      const ask = await this.alertStepsSrv.askConfirm(`¿Deseas eliminar la colección ${this.commonSrv.getAddress(item.addr)} de la base de datos?`);
      if(!ask) return;

      await this.spinner.show();

      const toAwait = [];

      const gallery = (Array.isArray(item.gallery)) ? item.gallery : [];
      // console.log('gallery', gallery);

      /** Eliminar archivos si tiene archivos registrados */
      if(gallery.length > 0){
        gallery.map((item: any) => {
          toAwait.push(this.mediaFileSrv.removeByURL(item.url));
        })
      }

      /** Si tiene cargado documento PDF */
      if(item.pdfDocument) toAwait.push(this.mediaFileSrv.removeByURL(item.pdfDocument));

      /** Si tiene documento de proceso legar */
      if(item.legalProcess) toAwait.push(this.mediaFileSrv.removeByURL(item.legalProcess));

      /** Si tiene documento de documentación legal */
      if(item.legalDocumentation) toAwait.push(this.mediaFileSrv.removeByURL(item.legalDocumentation));

      /** Esperar a que se eliminen los archivos */
      await Promise.all(toAwait);

      /** Eliminar registro de colección */
      await this.collectionSrv.remove(item.addr);

      this.alertStepsSrv.showToast(`Se eliminó la colección ${this.commonSrv.getAddress(item.addr)} exitosamente`);
      return;
      
    } catch (err) {
      console.log('Error on CollectionListComponent.removeCollection', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

}