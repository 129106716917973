import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Web3Service } from "./web3.service";

@Injectable({
  providedIn: "root",
})
export class VendorContractService {
  public abi = "/assets/abi/Vendor.json";

  public contractAddress = environment.vendorAddress;

  constructor(private web3Srv: Web3Service) {}

  /** ===============================================================
   *                        VENDOR Administered METHODS
   * ================================================================ */

  async vendor_withdraw_withdraw(amount: string) {
    const addr = this.web3Srv.accounts[0];
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdraw",
      params: [amount, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_withdraw_withdrawToken(erc20: string, amount: string) {
    const addr = this.web3Srv.accounts[0];
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "withdrawToken",
      params: [erc20, amount, addr],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                        VENDOR Administered METHODS
   * ================================================================ */

  async vendor_administered_addAdmin(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addAdmin",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_administered_isAdmin(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "isAdmin",
      params: [wallet],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_administered_isUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "isUser",
      params: [wallet],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_administered_getRoles(addr: string) {
    try {
      const [vendorIsAdmin, vendorIsUser] = await Promise.all([
        this.vendor_administered_isAdmin(addr),
        this.vendor_administered_isUser(addr),
      ]);

      return {
        vendorIsAdmin,
        vendorIsUser,
      };
    } catch (err) {
      console.log("Error on Web3Service.vendor_administered_getRoles", err);

      return {
        vendorIsAdmin: false,
        vendorIsUser: false,
      };
    }
  }

  async vendor_administered_renounceAdmin() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "renounceAdmin",
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_administered_removeUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "removeUser",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_administered_addUser(wallet: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addUser",
      params: [wallet],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 VENDOR  Collection METHODS
   * ================================================================ */
  async vendor_collection_addCollection(
    name: string,
    symbol: string,
    maxSupply: number,
    baseTokenURI: string,
    price: string,
    active: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addCollection",
      params: [name, symbol, maxSupply, baseTokenURI, price, active],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_collection_importCollection(
    addr: string,
    price: string,
    active: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "importCollection",
      params: [addr, price, active],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_collection_updateCollection(
    id: number,
    type: number,
    price: string,
    active: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "updateCollection",
      params: [id, type, price, active],
      callType: "send",
      urlABI: this.abi,
    });
  }
  async vendor_collection_collectionCount() {
    const snapshot = await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "collectionCount",
      callType: "call",
      urlABI: this.abi,
    });
    return Number(snapshot);
  }

  async vendor_collection_collectionList(from: number, to: number) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "collectionList",
      params: [from, to],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_collection_getAllCollections() {
    try {
      const collectionCount = await this.vendor_collection_collectionCount();
      return await this.vendor_collection_collectionList(0, collectionCount);
    } catch (err) {
      console.log(
        "Error on Web3Service.vendor_whitelist_getAllCollections",
        err
      );
      return [];
    }
  }

  async vendor_collection_transferReserved(
    idx: number,
    addr: string,
    nro: number
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "transferReserved",
      params: [idx, addr, nro],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 VENDOR WhitheLIST METHODS
   * ================================================================ */

  async vendor_whitelist_tokensList() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "tokensList",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_whitelist_addToken(
    addr: string,
    orc: string,
    act: boolean,
    ntv: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "addToken",
      params: [addr, orc, act, ntv],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_whitelist_updateToken(
    id: any,
    type: number,
    addr: string,
    bool: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "updateToken",
      params: [id, type, addr, bool],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_whitelist_tokensListoc() {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "tokensList",
      callType: "call",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 VENDOR Oracle METHODS
   * ================================================================ */

  async vendor_oracle_parseUSDtoToken_OFFCHAIN(amount: string, addr: string) {
    // console.log("vendor_oracle_parseUSDtoToken_OFFCHAIN", amount, addr);
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: this.contractAddress,
      method: "parseUSDtoToken",
      params: [amount, addr],
      callType: "call",
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 VENDOR Code METHODS
   * ================================================================ */

  async vendor_code_addToken(
    addr: string,
    code: string,
    pg: number,
    status: boolean
  ) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "store",
      params: [addr, code, pg, status],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_code_update(id: any, tp: number, pg: number, status: boolean) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "update",
      params: [id, tp, pg, status],
      callType: "send",
      urlABI: this.abi,
    });
  }

  async vendor_code_collectionCount() {
    const snapshot = await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "referralCount",
      callType: "call",
      urlABI: this.abi,
    });
    return Number(snapshot);
  }

  async vendor_code_codeList(from: number, to: number) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "getReferralList",
      params: [from, to],
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_code_getAllCollections() {
    try {
      const collectionCount = await this.vendor_code_collectionCount();
      return await this.vendor_code_codeList(0, collectionCount);
    } catch (err) {
      console.log(
        "Error on Web3Service.vendor_whitelist_getAllCollections",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                 VENDOR Utils METHODS
   * ================================================================ */

  async vendor_utils_getPGFee() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "getPGFee",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_utils_setPGFee(pg: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setPGFee",
      callType: "send",
      params: [pg],
      urlABI: this.abi,
    });
  }

  async vendor_utils_getVaultAddress() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "getVaultAddress",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_utils_setVaultAddress(addr: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "setVaultAddress",
      callType: "send",
      params: [addr],
      urlABI: this.abi,
    });
  }

  async vendor_utils_getPGDirectBonus() {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "REFERRAL_PERCENTAGE_AMOUNT",
      callType: "call",
      urlABI: this.abi,
    });
  }

  async vendor_utils_setPGDirectBonus(pg: string) {
    return await this.web3Srv.calculateAndCallCustomABI({
      contractAddress: this.contractAddress,
      method: "set_referral_percentage_amount",
      callType: "send",
      params: [pg],
      urlABI: this.abi,
    });
  }

  /** ===============================================================
   *                 VENDOR Buy METHODS
   * ================================================================ */

  vendor_buy_buyWithToken(
    token: string,
    id: number,
    amount: string,
    code: string
  ) {
    return this.web3Srv.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "buyWithToken",
      params: [token, id, amount, code],
      callType: "send",
      urlABI: this.abi,
    });
  }

  /**
   *
   * @param SCtoken
   * @param collectionID
   * @param listing_id
   * @param price
   * @returns
   */
  vendor_buy_buyNative(
    id: number,
    token: string,
    amount: string,
    code: string,
    price: string
  ) {
    return this.web3Srv.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "buyNative",
      params: [id, token, amount, code],
      callType: "send",
      optionals: { value: price },
      urlABI: this.abi,
    });
  }
}
