import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { fromWei } from "src/app/helpers/utils";

@Component({
  selector: "app-projects-your-profile",
  templateUrl: "./projects-your-profile.component.html",
  styleUrls: ["./projects-your-profile.component.css"],
})
export class ProjectsYourProfileComponent implements OnInit, OnDestroy {
  @ViewChild("formBuy") formBuy!: ElementRef;

  public projectOne!: any;
  public projectTwo!: any;
  public proyectsList$!: Observable<any[]>;
  private sub$!: Subscription;

  constructor(private collectionSrv: CollectionService) {}

  ngOnInit() {
    this.proyectsList$ = this.collectionSrv
      .getDynamic([
        // { field: 'cStatus', condition: '==', value: true}
        { field: "status", condition: "in", value: ["preview", "opening"] },
      ])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            let gallery: any[] = Array.isArray(item.gallery)
              ? item.gallery
              : [];
            // find thumbnails if exists or set defautl value
            const thumbnails =
              gallery.find((item: any) => item.thumbnails)?.url ||
              this.collectionSrv.deafultThumbnails;

            /** Si no tiene archivos de */
            if (gallery.length == 0) {
              gallery = new Array(3).fill({
                type: "image/png",
                url: this.collectionSrv.defaultImage,
                order: 0,
                thumbnails: false,
                size: 0,
              });
              gallery[0].thumbnails = true;
            }

            return {
              ...item,
              statusParsed: this.collectionSrv.collectionStatus.find(
                (status: any) => status.value === item.status
              ),
              thumbnails,
              gallery,
            };
          })
        ),
        // tap((data) =>
        //   console.log("ProyectsViewListDesktopComponent.proyectsList$", data)
        // ),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );

    this.sub$ = this.proyectsList$.subscribe((projects) => {
      if (projects.length > 0) {
        this.projectOne = projects[0];
        this.projectTwo = projects[1];
      }
    });
  }

  onSelectProject(item: any) {
    // console.log('onSelectProject', item);
    this.collectionSrv.onViewSelectedCollection$.next({
      action: "selectProject",
      data: item,
    });
  }

  getPrice(price: any) {
    return fromWei(price, 18);
  }

  formView() {
    this.formBuy.nativeElement.classList.toggle("d-none");
  }

  noComma(value: any) {
    return value.replace(",", ".");
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
