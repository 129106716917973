<div class="secure-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card recovery border-0 rounded-3 p-3 p-lg-4">
          <div class="position-relative">
            <div class="back" routerLink="/pages/home">
              <img src="assets/img/arrow-back-orange.png" alt="" />
            </div>
            <h2 class="card-title text-center mb-5 fs-5">
              <b> Recuperar Contraseña </b>
            </h2>

            <form [formGroup]="form">
              <ng-container *ngIf="formStatus == 1">
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    [ngClass]="{ valid: submit && f['email'].errors }"
                    formControlName="email"
                    class="form-control"
                    id="floatingEmailInput"
                    placeholder="email@example.com" />
                  <label for="floatingEmailInput">
                    <!-- {{ "general.Enter-your-registered-email-address" | translate }}  -->
                    Ingrese su correo electronico registrado
                    <span class="text-danger">*</span>
                  </label>

                  <ng-container *ngIf="submit">
                    <ng-container *ngFor="let item of vm.email">
                      <span
                        class="badge bg-danger"
                        *ngIf="f['email'].hasError(item.type)">
                        {{ item.message | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="mb-4">
                  <label for="email" class="form-label">
                    <span class="text-danger">*</span>
                    <!-- {{ "general.Send-verification-code" | translate }} -->
                    Enviar codigo de Verificación
                  </label>
                  <div
                    class="d-flex justify-content-center flex-column align-items-center my-2">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-purple btn-one btn-large"
                      (click)="sendCode($event)">
                      Enviar
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="formStatus == 2">
                <div class="form-floating mb-3" *ngIf="formStatus == 2">
                  <input
                    type="password"
                    [ngClass]="{ valid: submit && f['password'].errors }"
                    formControlName="password"
                    class="form-control"
                    id="floatingPasswordInput"
                    placeholder="******" />
                  <label for="floatingPasswordInput"> Contraseña </label>

                  <ng-container *ngIf="submit">
                    <ng-container *ngFor="let item of vm.password">
                      <span
                        class="badge bg-danger"
                        *ngIf="f['password'].hasError(item.type)">
                        {{ item.message | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="form-floating mb-3" *ngIf="formStatus == 2">
                  <input
                    type="password"
                    [ngClass]="{ valid: submit && f['confirmPassword'].errors }"
                    formControlName="confirmPassword"
                    class="form-control"
                    id="floatingConfirmPasswordInput"
                    placeholder="******" />
                  <label for="floatingConfirmPasswordInput">
                    Repetir Contraseña
                  </label>

                  <ng-container *ngIf="submit">
                    <ng-container *ngFor="let item of vm.confirmPassword">
                      <span
                        class="badge bg-danger"
                        *ngIf="f['confirmPassword'].hasError(item.type)">
                        {{ item.message | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="mb-4">
                  <div
                    class="d-flex justify-content-center flex-column align-items-center my-2">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-purple btn-one btn-large text-uppercase"
                      [ngClass]="{ disabled: submit && loader }"
                      (click)="onSubmit()">
                      <ng-container *ngIf="!loader; else sendingForm">
                        Actualizar
                      </ng-container>

                      <ng-template #sendingForm>
                        <div class="spinner-border text-light" role="status">
                          <span class="visually-hidden">Cargando...</span>
                        </div>
                      </ng-template>
                    </a>

                    <!-- <button class="btn btn-one" type="submit" [disabled]="submit && loader">
                    <ng-container *ngIf="!loader; else sendingForm">
                      ACTUALIZAR
                    </ng-container>
  
                    <ng-template #sendingForm>
                      <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </ng-template>
  
                  </button> -->
                  </div>
                </div>
              </ng-container>
            </form>

            <!-- <form>
              <div class="form-floating mb-3" *ngIf="checkEmailStatus === 0 || checkEmailStatus === 1 ">
                  <input type="email" [ngClass]="{'valid': valid }" [ngModelOptions]="{standalone: true}" [(ngModel)]="datad.email" (ngModelChange)="saverange($event)" class="form-control" id="floatingInput" placeholder="name@example.com">
                  <label for="floatingInput">Ingrese su correo electronico registrado</label>
                  <span *ngIf="valid" class="badge bg-danger">Correo electronico ingresado no es valido</span>
                  <span *ngIf="validUser" class="badge bg-danger">Usuario no registrado</span>
              </div>
              <div class="d-grid" *ngIf="checkEmailStatus === 1">
                  <label for="email" class="form-label">
                      <span class="text-danger">*</span>
                      Enviar codigo de verificacion
                  </label>
                  <button class="btn btn-primary" (click)="prueba()" type="button">send</button>
              </div>
              <br>
              <div class="form-floating mb-3" *ngIf="checkEmailStatus === 2">
                  <input type="password" class="form-control" [ngClass]="{'valid': validPassword }" [(ngModel)]="datad.password" [ngModelOptions]="{standalone: true}" (ngModelChange)="modelChangePassword($event)" id="floatingPassword" placeholder="Password">
                  <label for="floatingPassword">New Password</label>
                  <span *ngIf="validPassword" class="badge bg-danger">password ingresado no es valido</span>
                  <span *ngIf="validPasswordEquals" class="badge bg-danger">password no son iguales</span>
              </div>
              <div class="form-floating mb-3" *ngIf="checkEmailStatus === 2">
                  <input type="password" class="form-control" [ngClass]="{'valid': validPassword }" [(ngModel)]="datad.repeatPassword" [ngModelOptions]="{standalone: true}" (ngModelChange)="modelChangePassword($event)" id="floatingRepeatPassword" placeholder="Password">
                  <label for="floatingRepeatPassword">Repeat New Password</label>
                  <span *ngIf="validRepeatPassword" class="badge bg-danger">password ingresado no es valido</span>
                  <span *ngIf="validPasswordEquals" class="badge bg-danger">password no son iguales</span>
              </div>
              <div class="d-grid" *ngIf="checkEmailStatus === 2">
                  <button (click)="openPopup()" class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Actualizar</button>
              </div>
          </form> -->

            <div
              class="d-flex justify-content-center flex-column align-items-center my-2">
              <a
                class="btn btn-orange btn-two btn-large text-uppercase"
                routerLink="/sign-in">
                Cancelar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
