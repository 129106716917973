import { Component, OnInit, ViewChild } from '@angular/core';
import { Web3Service } from 'src/app/services/contract/web3.service';

@Component({
  selector: 'app-vendor-manager',
  templateUrl: './vendor-manager.component.html',
  styleUrls: ['./vendor-manager.component.css']
})
export class VendorManagerComponent {

  constructor(
    public web3Srv: Web3Service,
  ) { }

  ngOnInit(): void { }
}
