<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Configurar Fee del contrato</div>
        <div class="fs-small mb-3">
            Fee Actual: {{(currentPg$ | async)}}%
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <input type="number" formControlName="pg" class="form-control" placeholder="Porcentaje (%)">
                <label for="floatingInput">
                    Porcentaje (%)
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.pg">
                        <div class="form-text text-danger mb-2" *ngIf="f['pg'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Actualizar
                </button>
            </div>

        </form>
    </div>
</div>