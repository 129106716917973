import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { toWei } from 'src/app/helpers/utils';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-import-collection-form',
  templateUrl: './import-collection-form.component.html',
  styleUrls: ['./import-collection-form.component.css']
})
export class ImportCollectionFormComponent {

  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    addr: [
      { type: "required", message: "Is Required" },
      { type: "isValidAddress", message: "Invalid Address" },
    ],
    price: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
    ],
    active: [
      { type: "required", message: "Is Required" },
    ],
  };

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
  ) {
    this.form = fb.group({
      addr: ['', 
        [Validators.required, cmIsValidAddress]
      ],
      price: [0, [Validators.required, Validators.min(0)]],
      active: [true, [Validators.required]],
    });
  }

  ngOnInit(): void { }

  get f(){ return this.form.controls; }

  async onSubmit() {
    this.submitted = true;
    const formData = this.form.value;
    const _data = {
      addr: formData.addr,
      price: toWei(formData.price),
      active: formData.active
    }
    console.log('_data', _data);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Estas seguro de importar esta colección?`,
        contractParams: { 
          method: 'vendor_collection_importCollection', 
          params: [_data.addr, _data.price, _data.active] 
        }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Se importo la colección exitosamente`)
        .then((result) => {
          this.submitted = false;
          this.form.patchValue({
            addr: '',
            price: 0,
            active: true
          });
        });
      }

    } catch (err) {
      console.log('Error on AddCollectionFormComponent@onSubmit', err);
    }
  }

}
