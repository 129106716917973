<div class="row mt-4">
  <div class="col-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/admin/dashboard">
            <i class="bi bi-arrow-left"></i>&nbsp;Inicio
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Retiros
        </li>
      </ol>
    </nav>
  </div>

  <div class="col-12 mb-4">
    <h5 class="text-center">Retiros</h5>
  </div>

  <div class="col-12 mb-4">
    <nav>

      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-vendor-tab" data-bs-toggle="tab" data-bs-target="#nav-vendor"
          type="button" role="tab" aria-controls="nav-vendor" aria-selected="true">
          Vendor
        </button>

        <button class="nav-link" id="nav-distribution-tab" data-bs-toggle="tab" data-bs-target="#nav-distribution"
          type="button" role="tab" aria-controls="nav-distribution" aria-selected="false">
          Distribution
        </button>

        <button class="nav-link" id="nav-sale-distribution-tab" data-bs-toggle="tab" data-bs-target="#nav-sale-distribution"
          type="button" role="tab" aria-controls="nav-sale-distribution" aria-selected="false">
          Sale Distribution
        </button>

      </div>
    </nav>
  </div>

  <div class="col-12">

    <div class="tab-content" id="nav-tabContent">

      <div class="tab-pane fade show active" id="nav-vendor" role="tabpanel" aria-labelledby="nav-vendor-tab">
        <div class="row">
          <div class="col-12 col-lg-6">
            <app-withdraw-native></app-withdraw-native>
          </div>

          <div class="col-12 col-lg-6">
            <app-withdraw-erc20></app-withdraw-erc20>
          </div>

        </div>
      </div>

      <div class="tab-pane fade" id="nav-distribution" role="tabpanel" aria-labelledby="nav-distribution-tab">
        <div class="row">
          <div class="col-12 col-lg-6">
            <app-withdraw-native-distributions></app-withdraw-native-distributions>
          </div>

          <div class="col-12 col-lg-6">
            <app-withdraw-erc20-distributions></app-withdraw-erc20-distributions>
          </div>

        </div>
      </div>

      <div class="tab-pane fade" id="nav-sale-distribution" role="tabpanel" aria-labelledby="nav-sale-distribution-tab">
        <div class="row">
          <div class="col-12 col-lg-6">
            <app-withdraw-native-sale-distribution></app-withdraw-native-sale-distribution>
          </div>

          <div class="col-12 col-lg-6">
            <app-withdraw-erc20-sale-distribution></app-withdraw-erc20-sale-distribution>
          </div>

        </div>
      </div>

    </div>
  </div>

</div>