import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, catchError, from, map, of } from 'rxjs';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { CommonService } from 'src/app/services/common.service';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-transfer-reserved-form',
  templateUrl: './transfer-reserved-form.component.html',
  styleUrls: ['./transfer-reserved-form.component.css']
})
export class TransferReservedFormComponent implements OnInit{

  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    idx: [
      { type: "required", message: "Is Required" },
    ],
    addr: [
      { type: "required", message: "Is Required" },
      { type: "isValidAddress", message: "Invalid Address" },
    ],
    nro: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 1" },
    ]
  };
  public collections$!: Observable<any[]>;

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
    private commonSrv: CommonService
  ) {
    this.form = fb.group({
      idx: ["", [Validators.required]],
      addr: ['', 
        [Validators.required, cmIsValidAddress]
      ],
      nro: [0, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit(): void {
    this.collections$ = from(this.contractService.vendor_collection_getAllCollections())
    .pipe(
      map((data: any[]) => data.map((item: any, id: number) => ({ id, ...item }))),
      catchError((err) => of([]))
    );
  }

  get f(){ return this.form.controls; }

  async onSubmit() {
    this.submitted = true;
    const formData = this.form.value;
    const _data = {
      idx: formData.idx,
      addr: formData.addr,
      nro: formData.nro
    };

    const addrParsed = this.commonSrv.getAddress(_data.addr);
    console.log('_data', _data);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Desear transferir de la colección ${_data.idx} al usuario ${addrParsed} la cantidad de ${_data.nro} NFTs?`,
        contractParams: { 
          method: 'vendor_collection_transferReserved', 
          params: [_data.idx, _data.addr, _data.nro] 
        }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Se transfirieron al usuario ${addrParsed} la cantidad de ${_data.nro} NFTs exitosamente`)
        .then((result) => {
          this.submitted = false;
          this.form.patchValue({
            idx: '',
            addr: '',
            nro: 0
          });
        });
      }

    } catch (err) {
      console.log('Error on TransferReservedFormComponent@onSubmit', err);
    }
  }

}
