<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Crear Colección</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <input type="text" formControlName="name" class="form-control" placeholder="Nombre de la Colección">
                <label for="floatingInput">
                    Nombre de la Colección
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.name">
                        <div class="form-text text-danger mb-2" *ngIf="f['name'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="text" formControlName="symbol" class="form-control" placeholder="Simbolo">
                <label for="floatingInput">
                    Simbolo
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.symbol">
                        <div class="form-text text-danger mb-2" *ngIf="f['symbol'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="number" formControlName="maxSupply" class="form-control" placeholder="Cantidad Máxima">
                <label for="floatingInput">
                    Cantidad Máxima
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.maxSupply">
                        <div class="form-text text-danger mb-2" *ngIf="f['maxSupply'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="text" formControlName="baseTokenURI" class="form-control" placeholder="Dirección de Metadata">
                <label for="floatingInput">
                    Dirección de Metadata
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.baseTokenURI">
                        <div class="form-text text-danger mb-2" *ngIf="f['baseTokenURI'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="number" formControlName="price" class="form-control" placeholder="Precio">
                <label for="floatingInput">
                    Precio
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.price">
                        <div class="form-text text-danger mb-2" *ngIf="f['price'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text">Estado</span>
                <select class="form-select" formControlName="active" placeholder="Estado">
                    <option [value]="false">Inactivo</option>
                    <option [value]="true">Activo</option>
                </select>
            </div>

            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>