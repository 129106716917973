<div *ngIf="!mobile">
  <app-user-profile-template></app-user-profile-template>
  <div class="tab mt-4">
    <button
      style="cursor: default"
      class="tablinks left active"
      id="left-login">
      Configurar Código
    </button>
  </div>
</div>

<div class="px-4 mt-4">
  <p class="text">
    Uno de los pasos mas importantes al configurar tu perfil es registrar una
    billetera con la cual nuestra plataforma podra interactuar. Para ello, es
    necesario que eligas una de las opciones que te presentamos a continuacion.
  </p>
  <p class="text">
    Es importante que tengas en cuenta que una vez que hayas registrado tu
    billetera, no podras cambiarla por otra. Por lo que te recomendamos que
    elijas una billetera que utilices con frecuencia, en caso de que ya tengas
    una, sino nosotros te ayudaremos a crear una.
  </p>

  <ng-container [ngSwitch]="optionSelected">
    <ng-container
      *ngSwitchCase="'own'"
      [ngTemplateOutlet]="renderOwnWallet"></ng-container>
    <ng-container
      *ngSwitchCase="'new'"
      [ngTemplateOutlet]="renderNewWallet"></ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="renderSelectOption"></ng-container>
  </ng-container>
</div>

<!-- Renderizar - Registrar wallet propia -->
<ng-template #renderOwnWallet>
  <ng-container
    *ngIf="dataStatus$ | async as account; else renderConnectWalletButton">
    <p class="text my-4 text-center">
      La wallet que se encuentra conectada y la cual se vinculara a tu cuenta
      es:
      <span class="badge bg-secondary"
        ><strong>{{ account | truncateWalletAddress }}</strong></span
      >
    </p>

    <!-- <div class="d-flex justify-content-evenly">
      <button
        type="button"
        class="btn btn-main"
        (click)="setOwnWallet(account)"
        [disabled]="blockButtons ? true : null">
        Confirmar
      </button>
      <button
        type="button"
        class="btn btn-main"
        (click)="disconnectAccount()"
        [disabled]="blockButtons ? true : null">
        Cancelar
      </button>
    </div> -->

    <div class="row mt-4">
      <div class="col-12">
        <button
          type="button"
          class="btn btn-orange mb-2 me-md-4 w-100"
          (click)="setOwnWallet(account)"
          [disabled]="blockButtons ? true : null">
          Confirmar
        </button>
      </div>
      <div class="col-12">
        <button
          type="button"
          class="btn btn-orange w-100"
          (click)="disconnectAccount()"
          [disabled]="blockButtons ? true : null">
          Cancelar
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #renderConnectWalletButton>
    <div class="row mt-4">
      <div class="col-12">
        <button
          type="button"
          class="btn btn-orange mb-2 me-md-4 w-100"
          (click)="connectAccount()">
          Conectar
        </button>
      </div>
      <div class="col-12">
        <button
          type="button"
          class="btn btn-orange w-100"
          (click)="optionSelected = null">
          Cancelar
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>

<!-- Renderizar - Registrar nueva wallet -->
<ng-template #renderNewWallet>
  <div class="row mt-4">
    <div class="col-12">
      <button
        type="button"
        class="btn btn-orange mb-2 me-md-4 w-100"
        (click)="setNewWallet()">
        Crear
      </button>
    </div>
    <div class="col-12">
      <button
        type="button"
        class="btn btn-orange w-100"
        (click)="optionSelected = null">
        Cancelar
      </button>
    </div>
  </div>
</ng-template>

<!-- Renderizar - Selecionar una opción -->
<ng-template #renderSelectOption>
  <div class="row mt-4">
    <div class="col-12">
      <button
        type="button"
        class="btn btn-orange mb-2 me-md-4 w-100"
        (click)="optionSelected = 'new'">
        No tengo wallet
      </button>
    </div>
    <div class="col-12">
      <button
        type="button"
        class="btn btn-orange w-100"
        (click)="optionSelected = 'own'">
        Ya tengo una wallet
      </button>
    </div>
  </div>
</ng-template>
