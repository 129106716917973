import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  Observable,
  catchError,
  distinctUntilChanged,
  interval,
  map,
  of,
  switchMap,
} from "rxjs";
import { fromWei } from "src/app/helpers/utils";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-proyects-view-list-item-mobile",
  templateUrl: "./proyects-view-list-item-mobile.component.html",
  styleUrls: ["./proyects-view-list-item-mobile.component.css"],
})
export class ProyectsViewListItemMobileComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() showDetails = false;
  @ViewChild("formBuy") formBuy!: ElementRef;
  @ViewChild("formBuyButton") formBuyButton!: ElementRef;

  public price$ = of({ price: 0 });
  public erc721Info$: Observable<any> = of({
    addr: null,
    totalSupply: 0,
    supply: 0,
    supplyLeft: 0,
  });

  constructor(
    private web3Srv: Web3Service,
    private sweetAlert2Srv: Sweetalert2Service
  ) {}

  get buttonOptionType() {
    if (!this.item) {
      return 1;
    } else if (["preview", "draft", "closed"].includes(this.item.status)) {
      return 1;
    } else if (this.item.status == "opening") {
      return 2;
    } else {
      return 3;
    }
  }

  ngOnInit(): void {
    /** Parsear Precio de la coleccióin */
    this.price$ = of({ price: fromWei(this.item.price, 18) });

    /** Obtener información de ERC721a */
    this.erc721Info$ = interval(1000).pipe(
      switchMap(() => this.web3Srv.erc721_getAllInfo(this.item.addr)),
      map((res: any) => ({
        addr: this.item.addr,
        totalSupply: res.totalSupply,
        supply: res.supply,
        supplyLeft: res.totalSupply - res.supply,
      })),
      catchError((err) =>
        of({
          addr: null,
          totalSupply: 0,
          supply: 0,
          supplyLeft: 0,
        })
      ),
      distinctUntilChanged(
        (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { item, showDetails } = changes;
    if (item && item.currentValue) {
      this.item = item.currentValue;
    }
    if (showDetails && showDetails.currentValue) {
      this.showDetails = showDetails.currentValue;
    }
  }

  onSelectProject() {
    this.showDetails = !this.showDetails;
    // console.log("onSelectProject", this.item);
  }

  async openBuyForm(event: any, form: any) {
    // console.log("openBuyForm", form);
    // Declare all variables
    var i, tabcontent: any, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("form-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("btn-buy");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    let formContent: any = document.getElementById(form);
    formContent.style.display = "block";
    event.currentTarget.className += " active";
  }

  openLink(url: string) {
    if (!url) {
      this.sweetAlert2Srv.showWarning("Enlace no disponible");
      return;
    }
    window.open(url, "_blank");
  }
}
