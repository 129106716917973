import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  Subscription,
  Observable,
  catchError,
  of,
  switchMap,
  combineLatest,
  map,
  distinctUntilChanged,
  from,
} from "rxjs";
import { PurchaseApiService } from "src/app/services/api/purchase-api.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PurchaseService } from "src/app/services/firebase/purchase.service";

const DEAFULT_PORFOLIO_VALUE = { uid: null, records: 0, vp: 0, ra: 0 };

@Component({
  selector: "app-sidebar-my-properties-list",
  templateUrl: "./sidebar-my-properties-list.component.html",
  styleUrls: ["./sidebar-my-properties-list.component.css"],
})
export class SidebarMyPropertiesListComponent implements OnInit, OnDestroy {
  public userDoc$!: Observable<null | any>;
  public records$: Observable<any[]> = of([]);
  public userDocument: any;

  public userPorfolio: any[] = [];

  public userPortfolio$: Observable<any> = of(DEAFULT_PORFOLIO_VALUE);

  private sub$!: Subscription;

  constructor(
    private authSrv: AuthenticationService,
    private purchaseService: PurchaseService,
    private purchaseApiSrv: PurchaseApiService
  ) {}

  ngOnInit() {
    this.userDoc$ = this.authSrv.userDoc$;
    /** Escuchar cambios en el usuario */
    const userPorfolio$ = this.authSrv.userDoc$.pipe(
      distinctUntilChanged(),
      switchMap((user: any) => {
        if (!user) return of([]);

        return this.purchaseService.getDynamic(
          [
            { field: "userId", condition: "==", value: user._id },
            // { field: "userId", condition: "==", value: "bDLGYGDoOJSfeC9NW6H3oeLX4CF3" },
            { field: "statusBuy", condition: "==", value: "success" },
          ]
          // { orderBy: [{ field: "createdAt", order: "desc" }] }
        );
      }),
      catchError((err) => of([]))
    );

    this.sub$ = combineLatest([
      this.authSrv.userDoc$.pipe(distinctUntilChanged()),
      userPorfolio$,
    ])
      .pipe(
        map(([user, userPorfolio]) => ({ user, userPorfolio }))
        // tap(console.log)
      )
      .subscribe(({ user, userPorfolio }) => {
        /** Capturar usuario */
        this.userDocument = user;

        /** Capturar registros del portafolio */
        this.userPorfolio = userPorfolio;
        // console.log(this.userPorfolio);

        /** Obtener información del portafolio del usuario */
        this.loadUserPortfolio();
      });
  }

  loadUserPortfolio() {
    this.userPortfolio$ = from(
      this.purchaseApiSrv.user_getPorfolioValue({ uid: this.userDocument._id })
    ).pipe(catchError((err) => of(DEAFULT_PORFOLIO_VALUE)));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
