import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaQueriesService } from "src/app/services/media-queries.service";
import { TemplateService } from "src/app/services/template.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public uid$!: Observable<null | string>;
  public segment = "sign-up";
  public showNavbar = true;

  private sub$!: Subscription;

  public sidenav: any;
  public main: any;
  public login: any;
  public defaultOPen: any;
  public mobile: any;

  constructor(
    private authSrv: AuthenticationService,
    private router: Router,
    private mediaQuery: MediaQueriesService,
    public templateSrv: TemplateService
  ) {}

  ngOnInit() {
    /** Escuchar si existe uid en la sesión */
    this.uid$ = this.authSrv.uid$;

    /** Escuchar si existe un cambio en el segmento */
    this.sub$ = this.authSrv.authOption$.subscribe((res: any) => {
      this.segment = res;
    });

    /** Escuchar cambios en mediaqueries de la pantalla */
    this.sub$.add(
      this.mediaQuery.mediaQuery$.subscribe((res: any) => {
        // console.log("mediaQueries", res);
        // console.log(res.width);

        this.mobile = res.width <= 1199.98;
        // console.log("mobile", this.mobile);
      })
    );

    /** Escuchar cambios en segmentos de tempalte */
    this.sub$.add(
      this.templateSrv.events$.subscribe((res: any) => {
        const { type, data } = res;
        if (type === "sidebar") {
          this.showNavbar = data;
        }
      })
    );
  }

  /**
   * Al cambiar de tab en el sidebar cuando no esta autenticado
   * @param tabName
   */
  onSelectTab(tabName: string) {
    this.authSrv.authOption$.next(tabName);
  }

  closeNav() {
    this.templateSrv.events$.next({ type: "sidebar", data: !this.showNavbar });
  }

  openNav() {
    this.showNavbar = true;
    this.templateSrv.events$.next({ type: "sidebar", data: this.showNavbar });
  }
  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
