import { Pipe, PipeTransform } from '@angular/core';
import { CollectionService } from '../services/firebase/collection.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Pipe({
  name: 'collectionById'
})
export class CollectionByIdPipe implements PipeTransform {

  constructor(
    private collectionSrv: CollectionService
  ) { }

  transform(collectionId: string): Observable<any> {
    return this.collectionSrv.getById(collectionId)
      .pipe(
        // tap((data) => console.log('data', data, collectionId)),
        map((data: any) => (!data) ? {exist: false} : {exist: true, ...data}),
        catchError((err) => of({exist: false}))
      );
  }

}
