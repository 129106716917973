import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
// import { pick } from "underscore";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<boolean>();
  
  public form!: FormGroup;
  public vm: any = {
    email: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Dirección de correo electrónico no válida" },
    ],
    password: [
      { type: "required", message: "Requerido" },
      { type: "minlength", message: "La longitud mínima es de 6" },
      { type: "maxlength", message: "La longitud máxima es de 12" },
    ],
  };
  public submit = false;

  public showPassword = false;
  public loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.buildForm();
  }

  ngOnInit() {}

  showValue() { this.showPassword = !this.showPassword; }

  mostrarContrasena(eyes: any, input: any) {
    let type: any = document.getElementById(input);
    let hiddenEyes: any = document.getElementById(eyes);

    if (type.type == "password") {
      type.type = "text";
      hiddenEyes.classList.add("active");
    } else {
      type.type = "password";
      hiddenEyes.classList.remove("active");
    }
  }

  /**
   * Construir formulario
   */
  buildForm() {
    this.form = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
        ],
      ],
    });
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      console.log("formData", formData);

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }

      const email = formData.email.trim().toLowerCase();
      const password = formData.password.trim();

      const result: any = await this.authSrv.signInWithEmail(email, password);

      /** Capturar UID del usuario */
      const uid = result.user.uid;

      /** Guardar identificador del usuario en el localStorage */
      this.authSrv.setLocalUID(uid);

      /** Obtener documento de usuario */
      const userDoc = await this.authSrv.getByUID(uid);

      const toParse = {
        email: userDoc.email,
        name: userDoc.name,
        avatar: userDoc.avatar,
        phoneNumber: userDoc.phoneNumber,
      };

      /** Guardar documento de usuario en el localStorage */
      localStorage.setItem("profile", JSON.stringify(toParse));

      // this.authSrv.userDoc$.next(Object.assign({}, toParse));

      this.newItemEvent.emit(false);
      return;

    } catch (err: any) {
      console.log("Error on SignInComponent.submitSigInData", err);
      if (err.code === "auth/wrong-password") {
        await this.sweetAlert2Srv.showError(
          // "la contraseña no es válida o el usuario no tiene una contraseña"
          "Usuario y/o contraseña incorrectos"
        );
      }

      if (err.code === "auth/user-not-found") {
        await this.sweetAlert2Srv.showError(
          // "No hay registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado."
          "Usuario y/o contraseña incorrectos"
        );
      }
      return;

    } finally {
      this.loading = false;
    }
  }

    /**
   * Al cambiar de tab en el sidebar cuando no esta autenticado
   * @param tabName
   */
    onSelectTab(tabName: string) {
      this.authSrv.authOption$.next(tabName);
    }
  
}
