<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center">
      <div
        class="col-12 d-flex justify-content-center flex-column align-items-center"
      >
        <h3>Inicia tu proyecto con:</h3>
        <img src="assets/img/88.png" class="img-fluid" alt="" />
      </div>

      <div class="col-12 col-lg-9 mt-5">
        <app-newsletter></app-newsletter>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center mt-4">
        <p class="footer-text">HAS REALIDAD TUS SUEÑOS</p>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-between">
        <p class="footer-text-foot">Todos los derechos reservados</p>
        <p class="footer-text-foot">Copyright © Elysium Capital</p>
      </div>
    </div>
  </div>
</footer>
