<app-header></app-header>
<app-proyects-view></app-proyects-view>
<app-home-investment></app-home-investment>
<app-projects-your-profile></app-projects-your-profile>
<app-how-it-works></app-how-it-works>
<app-advantages-real></app-advantages-real>
<app-home-approval-processes></app-home-approval-processes>
<app-faqs></app-faqs>
<app-footer></app-footer>
<app-notifications-users></app-notifications-users>
