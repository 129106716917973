<div class="section-coming-soon">
  <div class="container position-absolute">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-coming-soon-header">
          <div class="">
            <label class="switch" (click)="modeColor()">
              <input id="changeColor" type="checkbox" checked="true" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-language dropdown-toggle"
              type="button"
              (click)="toggleMenuLanguage()"
              aria-expanded="false">
              <img
                src="assets/img/icon-global-black.png"
                class="img-fluid dark"
                alt="" />
              <img
                src="assets/img/icon-global-white.png"
                class="img-fluid white"
                alt="" />

              {{ "global." + currentLanguage | translate }}
            </button>
            <ul class="dropdown-menu" #languageMenu>
              <li>
                <a class="dropdown-item" (click)="changeLanguage('es')">
                  {{ "global.es" | translate }}
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="changeLanguage('en')">
                  {{ "global.en" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-coming-soon-logo">
          <img
            class="img-fluid dark"
            src="assets/img/logo-nuevo-dark.png"
            alt=" logo" />
          <img
            class="img-fluid white"
            src="assets/img/logo-nuevo-white.png"
            alt=" logo" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h1
          class="section-coming-soon-title"
          [innerHTML]="'coming-soon.title' | translate"></h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-xxl-11">
        <p class="section-coming-soon-text">
          {{ "coming-soon.text" | translate }}
        </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-xxl-11">
        <form class="section-coming-soon-form">
          <input type="text" />

          <div class="section-coming-soon-button-container">
            <button class="section-coming-soon-button">
              {{ "Inicar Ahora " | translate }}
            </button>
            <img
              class="img-fluid"
              src="assets/img/icon-arrow-right-orange.png"
              alt="" />
          </div>
        </form>
      </div>
    </div>

    <footer class="section-coming-soon-footer">
      <div class="row justify-content-center">
        <div class="col-12 col-xxl-11">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-9">
              <p class="section-coming-soon-footer-text">
                {{ "coming-soon.footer-text" | translate }}
              </p>
            </div>

            <div class="col-12 col-xl-3">
              <div class="section-coming-soon-footer-logo">
                <img
                  class="img-fluid dark"
                  src="assets/img/bilart-logo-dark.png"
                  alt=" bilart logo" />
                <img
                  class="img-fluid white"
                  src="assets/img/bilart-logo-white.png"
                  alt=" bilart logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
