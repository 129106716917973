<section class="advantages-real mt-5">
  <div class="container">
    <div class="row align-items-center">
      <div
        class="col-12 col-lg-6 d-flex align-items-center justify-content-center"
      >
        <h2 class="title mb-5">
          Ventajas
          <br />
          Reales
        </h2>
      </div>

      <div class="col-12 col-lg-6">
        <div class="advantages-real-item">
          <img src="assets/img/92.png" class="img-fluid" alt="" />
          <h5>Regulaciones</h5>
          <p>
            Elysuym Capital es regulado bajo la legislación de EUA y en los
            países en donde operen los proyectos.
          </p>
        </div>

        <hr />

        <div class="advantages-real-item">
          <img src="assets/img/91.png" class="img-fluid" alt="" />
          <h5>Ganancias de la operación y capital</h5>
          <p>
            Cada proyecto posee su propio plan de negocios, los cuales generan
            rentabilidades periódicas y valoración del capital
          </p>
        </div>

        <hr />
      </div>
    </div>
  </div>
</section>
