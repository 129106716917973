import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { toWei } from 'src/app/helpers/utils';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-add-collection-form',
  templateUrl: './add-collection-form.component.html',
  styleUrls: ['./add-collection-form.component.css']
})
export class AddCollectionFormComponent {

  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    name: [
      { type: "required", message: "Is Required" },
    ],
    symbol: [
      { type: "required", message: "Is Required" },
    ],
    maxSupply: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
    ],
    baseTokenURI: [
      { type: "required", message: "Is Required" },
    ],
    price: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
    ],
    active: [
      { type: "required", message: "Is Required" },
    ],
  };

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
  ) {
    this.form = fb.group({
      name: ["", [Validators.required]],
      symbol: ["", [Validators.required]],
      maxSupply: [0, [Validators.required, Validators.min(0)]],
      baseTokenURI: ["#", [Validators.required]],
      price: [0, [Validators.required, Validators.min(0)]],
      active: [true, [Validators.required]],
    });
  }

  ngOnInit(): void { }

  get f(){ return this.form.controls; }

  async onSubmit() {
    this.submitted = true;
    const formData = this.form.value;
    const _data = {
      name: `${formData.name}`.trim().toUpperCase(),
      symbol: `${formData.symbol}`.trim().toUpperCase(),
      maxSupply: formData.maxSupply,
      baseTokenURI: `${formData.baseTokenURI}`.trim(),
      price: toWei(formData.price),
      active: formData.active
    }
    console.log('_data', _data);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Estas seguro de crear esta colección?`,
        contractParams: { 
          method: 'vendor_collection_addCollection', 
          params: [
            _data.name, 
            _data.symbol, 
            _data.maxSupply, 
            _data.baseTokenURI, 
            _data.price, 
            _data.active
          ] 
        }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Se creo la colección exitosamente`)
        .then((result) => {
          this.submitted = false;
          this.form.patchValue({
            name: '',
            symbol: '',
            maxSupply: 0,
            baseTokenURI: '#',
            price: 0,
            active: true
          });
        });
      }

    } catch (err) {
      console.log('Error on AddCollectionFormComponent@onSubmit', err);
    }
  }

}