<!-- Modal -->
<div
  class="modal fade"
  id="modalNotification"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          Notificaciones
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="modalNotificationClose()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-3 col-xxl-2 px-0">
              <div class="notifications-list">
                <ul>
                  <li
                    *ngFor="let item of notificatioList"
                    (click)="notificationView(item, $event)"
                    class="notifications-list-item"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-9 col-xxl-10 px-0">
              <div class="notifications-message">
                <h3 class="text-uppercase">{{ notification?.name }}</h3>
                <p>
                  {{ notification?.response }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
