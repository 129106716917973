import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, catchError, from, of } from 'rxjs';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { toBasicPoint } from 'src/app/helpers/utils';
import { CommonService } from 'src/app/services/common.service';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-set-contract-vault-from',
  templateUrl: './set-contract-vault-from.component.html',
  styleUrls: ['./set-contract-vault-from.component.css']
})
export class SetContractVaultFromComponent {

  public form: UntypedFormGroup;
  public vm = {
    addr: [
      {type: "required", message: "Is required"},
      { type: "isValidAddress", message: "Invalid Address" },
    ]
  };
  public submitted = false;
  public deadWallet = environment.addressdead;
  public currentVault$!: Observable<string>;

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
    private commonSrv: CommonService
  ) {
    this.form = fb.group({
      addr: ["", [Validators.required, cmIsValidAddress]],
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  get f() { return this.form.controls; }

  loadData(){
    this.currentVault$ = from(this.contractService.vendor_utils_getVaultAddress())
    .pipe(
      catchError((err) => of(this.deadWallet))
    );
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;
    const addrParsed = this.commonSrv.getAddress(_data.addr);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Actualizar vault del contrato a ${addrParsed}?`,
        contractParams: { method: 'vendor_utils_setVaultAddress', params: [ toBasicPoint(_data.pg) ] }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Dirección Vault actualizada a ${addrParsed}`)
        .then((result) => {
          this.submitted = false;
          this.form.patchValue({addr: ""});
          this.loadData();
        });
      }

    } catch (err) {
      console.log('Error on SetContractVaultFromComponent@onSubmit', err);
    }
  }
}
