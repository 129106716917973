<form class="form-sidebar" [formGroup]="form" novalidate>
  <div class="row flex-column">
    <div class="col mb-2 position-relative">
      <img src="assets/img/Icon_29.png" class="icon-input" />
      <input
        type="text"
        class="form-control user"
        placeholder="Correo"
        aria-label="email"
        formControlName="email"
      />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"
      ></ng-container>
    </div>

    <div class="col position-relative">
      <img src="assets/img/Icon_33.png" class="icon-input" />
      <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control mb-0"
        placeholder="Contraseña"
        aria-label="password"
        formControlName="password"
      />
      <ng-container
        [ngTemplateOutlet]="renderShowValueBtn"
        [ngTemplateOutletContext]="{
          field: 'repeatpassword',
          id: 'login-password'
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'password' }"
      ></ng-container>
      <span class="text-password mt-1" routerLink="/pages/secure"
        >Has olvidado tu contraseña</span
      >
    </div>

    <div class="col mt-3">
      <label class="container-check">
        Recuérdame
        <input type="checkbox" />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="col mt-2">
      <ng-container *ngIf="!loading; else renderLoadingBtn">
        <button type="submit" class="btn" (click)="onSubmit()">
          <span>Ingresar</span>
        </button>
      </ng-container>
    </div>
  </div>

  <div class="col-12">
    <div class="social-login">
      <span>o inicia sesión con</span>
      <div class="social-login-icons">
        <img
          src="assets/img/Icon_48.png"
          class="img-fluid img-icon-login"
          alt=""
        />
        <img
          src="assets/img/Icon_49.png"
          class="img-fluid img-icon-login"
          alt=""
        />
        <img
          src="assets/img/Icon_50.png"
          class="img-fluid img-icon-login"
          alt=""
        />
      </div>
      <span>¿No tienes una cuenta? <b (click)="onSelectTab('sign-up')">REGÍSTRATE</b> </span>
    </div>
  </div>
</form>

<ng-template #renderLoadingBtn>
  <button type="submit" class="btn" disabled>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</ng-template>

<ng-template #renderShowValueBtn let-field="field" let-id="id">
  <div
    class="password-view"
    [ngClass]="{ active: !showPassword }"
    [id]="id"
    (click)="showValue()"
  >
    <img src="assets/img/Icon_37.png" class="eye" />
    <img src="assets/img/hidden-eye.png" class="eye-hidden" />
  </div>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | titlecase }}
      </span>
    </ng-container>
  </div>
</ng-template>
