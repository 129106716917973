import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription, catchError, from, map, of } from 'rxjs';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { CollectionService } from 'src/app/services/firebase/collection.service';
import { MediaFileService } from 'src/app/services/media-file.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';

@Component({
  selector: 'app-update-fb-collection-form',
  templateUrl: './update-fb-collection-form.component.html',
  styleUrls: ['./update-fb-collection-form.component.css']
})
export class UpdateFbCollectionFormComponent implements OnInit, OnDestroy {

  public id: any;
  public account: string;
  public roles$!: Observable<any>;
  public collectionDoc: any;
  public collectionStatus: any[];

  public acceptImageFileTypes = ['image/jpeg', 'image/png', 'image/jpg'].join(',');
  public acceptPDFFileTypes = ['application/pdf'].join(',');

  public form: FormGroup;
  public vm: any = {
    projectName: [
      { type: 'required', message: 'Project name is required' },
    ],
    description: [
      { type: 'required', message: 'Description is required' },
    ],
    legalDocumentation: [
      { type: 'required', message: 'Legal documentation is required' },
    ],
    legalProcess: [
      { type: 'required', message: 'Legal process is required' },
    ],
    pdfDocument: [
      { type: 'required', message: 'PDF document is required' },
    ],
    gallery: [
      { type: 'required', message: 'Gallery is required' },
    ],
    estimatedProfitability: [
      { type: 'required', message: 'Estimated profitability is required' },
      { type: 'min', message: 'Estimated profitability must be greater or equal than 0' },
      { type: 'max', message: 'Estimated profitability must be less or equal than 100' },
    ],
    status: [
      { type: 'required', message: 'Status is required' },
    ]
  }
  public submitted = false;

  private sub$!: Subscription;

  constructor(
    private web3Srv: Web3Service,
    private router: Router,
    private route: ActivatedRoute,
    private collectionSrv: CollectionService,
    private fb: FormBuilder,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private mediaFileSrv: MediaFileService
  ) {

    /** Capturar wallet conectada */
    this.account = this.web3Srv.accounts[0];

    /** Obtener ID desde la URL */
    const id = this.route.snapshot.paramMap.get('id');
    // console.log('id', id);

    /** Validar ID recibido */
    if(!id) {
      this.router.navigate(['/admin/vendor']);
    } else {
      this.id = id;
    }

    /** Construir Formulario */
    this.form = this.fb.group({
      isNew: [ false, [Validators.required]],
      projectName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      legalDocumentation: [""],
      legalProcess: [""],
      pdfDocument: [""],
      gallery: [""],
      estimatedProfitability: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
      status: ["draft", [Validators.required]]
    });

    this.collectionStatus = this.collectionSrv.collectionStatus;
  }

  ngOnInit(): void {

    /** Obtener información del documento de la colección */
    this.sub$ = this.collectionSrv.getById(this.id)
    .pipe(
      map((data: any) => (data) ? ({exist: true, ...data}) : {exist: false, addr: this.id}),
      catchError((err) => of({exist: false}))
    )
    .subscribe((data) => {
      // console.log('data', data);
      if(!data.exist) {
        this.router.navigate(['/admin/vendor']);
        return;
      }

      /** Guardar información del documento */
      this.collectionDoc = data;

      /** Cargar información en el formulario */
      this.form.patchValue({
        isNew: data.isNew,
        projectName: data.projectName,
        description: data.description,
        legalDocumentation: data.legalDocumentation,
        legalProcess: data.legalProcess,
        pdfDocument: data.pdfDocument,
        gallery: data.gallery,
        estimatedProfitability: data.estimatedProfitability,
        status: data.status
      });
      return;
    });

    /** Obtener roles de la wallet conectada */
    this.roles$ = from(this.web3Srv.vendor_administered_getRoles(this.account));
  }

  get f() { return this.form.controls; }
  
  get collectionStatusHelp(){
    const status = this.form.get('status')?.value;
    const item = this.collectionStatus.find((item) => item.value === status);
    return (item) ? item.help : '';
  }

  get galleryFiles() {
    const gallery = this.form.get('gallery')?.value;
    return (Array.isArray(gallery)) ? gallery : [];
  }

  async onGalleryInputFileChange(file: File){
    try {
      await this.spinner.show();

      // console.log('file', file);
      const fileType = file.type;

      /** Construir ruta para almacenar archivo */
      const url = `multimedia/collection/${this.id}/${this.id}_${moment().valueOf()}`;
      // console.log('url', url);

      /** Cargar Archivo en el Bucket */
      const fileURL = await this.mediaFileSrv.uploadFileDocumentIntoRoute(url, file);
      // console.log('fileURL', fileURL);

      /** Construir objeto de galeria para almacenar en Firestore */
      const fileRow = {
        url: fileURL,
        type: fileType,
        size: file.size,
        thumbnails: (this.galleryFiles.length == 0),
        order: 0
      }

      /** Construir galeria */
      const gallery = (Array.isArray(this.form.get('gallery')?.value)) ? [...this.form.get('gallery')?.value, fileRow] : [fileRow];
      // console.log('gallery', gallery);
    
      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {gallery});

      /** Actualizar formulario */
      this.form.patchValue({gallery});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.onGalleryInputFileChange', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async setThumbnail(index: number){
    try {
      await this.spinner.show();

      /** Actualizar galeria */
      const gallery = this.galleryFiles.map((item: any, i: number) => ({...item, thumbnails: (i === index)}));

      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {gallery});

      /** Actualizar formulario */
      this.form.patchValue({gallery});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.setThumbnail', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async changeGalleryFileOrder(index: number, order: number){
    try {
      await this.spinner.show();

      const gallery = [...this.galleryFiles];

      /** Validar orden */
      if(order < 0 || order >= gallery.length) { return; }

      /** Actualizar orden */
      gallery.splice(index, 1);

      /** Actualizar orden */
      gallery.splice(order, 0, this.galleryFiles[index]);

      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {gallery});

      /** Actualizar formulario */
      this.form.patchValue({gallery});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.changeGalleryFileOrder', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async deleteGalleryFile(index: number){
    try {
      const ask = await this.sweetAlert2Srv.askConfirm("¿Estás seguro de eliminar el archivo?");
      if(!ask){ return; }

      await this.spinner.show();

      const gallery = this.galleryFiles;

      /** Eliminar archivo del Bucket */
      await this.mediaFileSrv.removeByURL(gallery[index].url);

      /** Eliminar archivo de la galeria */
      gallery.splice(index, 1);

      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {gallery});

      /** Actualizar formulario */
      this.form.patchValue({gallery});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.deleteGalleryFile', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async onSelectDocumentFile(field: string, file: File){
    console.log('onSelectDocumentFile', {field, file});
    try {
      await this.spinner.show();

      const fieldValue = this.form.get(field)?.value;

      // Remove if has a previous file
      if(fieldValue){
        await this.mediaFileSrv.removeByURL(fieldValue);
      }

      const fileURL = await this.mediaFileSrv.uploadFileDocumentIntoRoute(field, file);
      console.log('fileURL', fileURL);

      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {[field]: fileURL});
      
      this.form.patchValue({[field]: fileURL});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.onSelectDocumentFile', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  openFileOnNewTab(url: string){
    window.open(url, '_blank');
    return;
  }

  async removePDFDocumentFile(field: string){
    try {
      await this.spinner.show();

      const fieldValue = this.form.get(field)?.value;

      // Remove if has a previous file
      await this.mediaFileSrv.removeByURL(fieldValue);

      /** Actualizar documento en Firestore */
      await this.collectionSrv.update(this.id, {[field]: null});

      this.form.patchValue({[field]: null});
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.removePDFDocumentFile', err);
      return
    } finally {
      this.spinner.hide();
    }
  }

  // Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam modi, impedit maxime at nam dolore.
  async onSubmit(){
    try {
      this.submitted = true;
      const formData = this.form.value;

      if(!this.form.valid){
        return;
      }

      const ask = await this.sweetAlert2Srv.askConfirm("¿Estás seguro de actualizar la colección?");
      if(!ask){ return; }

      /** Válidar si es lanzamiento */
      if(formData.status === 'opening'){
        const askLaunch = await this.sweetAlert2Srv.askConfirm("¿Estás seguro de lanzar la colección?");
        if(!askLaunch){ return; }

        if(!formData.openAt) { formData.openAt = moment().valueOf(); }
      }

      /** Válidar si es cierre */
      if(formData.status === 'soldOut'){
        const askSoldOut = await this.sweetAlert2Srv.askConfirm("¿Estás seguro de cerrar la colección?");
        if(!askSoldOut){ return; }

        if(!formData.soldOutAt) { formData.soldOutAt = moment().valueOf(); }
      }

      await this.spinner.show();

      /** Si cambian el estado de la colección - Guardar en linea de tiempo */
      if(formData.status !== this.collectionDoc.status){
        formData.timeline = [...this.collectionDoc.timeline, {status: formData.status, time: moment().valueOf()}]
      }

      console.log('formData', formData);

      await this.collectionSrv.update(this.id, formData);

      this.sweetAlert2Srv.showToast('Se actualizó la colección exitosamente', 'success');

      this.router.navigate(['/admin/vendor']);
      return;
      
    } catch (err) {
      console.log('Error on UpdateFbCollectionFormComponent.onSubmit', err);
      return;

    } finally {
      this.spinner.hide();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
