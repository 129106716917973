<!-- <div id="mySidenav" class="sidenav">
  <span class="closebtn" (click)="closeNav()">&times;</span>

  <ng-container
    *ngIf="uid$ | async as data; then renderLoggedIn; else renderLoggedOut"
  ></ng-container>
</div> -->
<ng-container
  *ngIf="
    uid$ | async as data;
    then renderLoggedIn;
    else renderLoggedOut
  "></ng-container>

<ng-template #renderLoggedIn>
  <!-- <div class="close-btn" [ngClass]="{ active: !showNavbar }">
    <img
      class="img-fluid"
      src="assets/img/icon-menu.png"
      alt="icono menu"
      (click)="openNav()" />
  </div> -->
  <div
    id="mySidenav"
    class="sidenav active"
    [ngClass]="{ active: showNavbar }"
    *ngIf="!mobile">
    <span class="closebtn" (click)="closeNav()">&times;</span>

    <ng-container [ngSwitch]="templateSrv.currentSidebarOption">
      <app-user-profile-sidebar
        *ngSwitchCase="'my-properties'"></app-user-profile-sidebar>
      <app-sidebar-profile-form
        *ngSwitchCase="'profile'"></app-sidebar-profile-form>
      <app-sidebar-config-user-code
        *ngSwitchCase="'config-code'"></app-sidebar-config-user-code>
    </ng-container>
  </div>
</ng-template>

<ng-template #renderLoggedOut>
  <div id="mySidenav" class="sidenav" [ngClass]="{ active: showNavbar }">
    <span class="closebtn" (click)="closeNav()">&times;</span>
    <div>
      <h3>
        Invertir está muy bien, pero...
        <br />
        <span>Ser propietario es aún mejor</span>
      </h3>

      <div class="tab">
        <button
          class="tablinks left"
          [ngClass]="{ active: segment == 'login' }"
          (click)="onSelectTab('login')">
          Iniciar Sesión
        </button>
        <button
          class="tablinks right"
          [ngClass]="{ active: segment == 'sign-up' }"
          (click)="onSelectTab('sign-up')">
          Regístrate
        </button>
      </div>

      <ng-container [ngSwitch]="segment">
        <div
          id="Login"
          class="tabcontent active"
          style="display: block"
          *ngSwitchCase="'login'">
          <app-login></app-login>
        </div>

        <div
          id="Register"
          class="tabcontent active"
          style="display: block"
          *ngSwitchCase="'sign-up'">
          <app-register></app-register>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
