<div class="propiedades-container">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">PROYECTO</th>
        <th scope="col">FRACCIÓN</th>
        <th scope="col">USD</th>
        <th scope="col">APR</th>
      </tr>
    </thead>

    <tbody>
      <ng-container [ngTemplateOutlet]="renderCheck"></ng-container>

      <tr class="total-properties">
        <td scope="row"></td>
        <td>TOTAL</td>
        <td>
          <ng-container
            *ngIf="userPortfolio$ | async as info; else renderLoaderAmount">
            {{ info.vp | currency }} USD
          </ng-container>
        </td>
        <td>
          <ng-container
            *ngIf="userPortfolio$ | async as info; else renderLoaderAmount">
            {{ info.ra | currency }} USD
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="funds">
  <div class="d-flex mb-2">
    <p>Arriendo Recibidos</p>
    <!-- <span>300$</span> -->
    <span>0$</span>
  </div>
  <div class="d-flex funds-item pt-2">
    <p>Fondos Disponible</p>
    <!-- <span>200$</span> -->
    <span>0$</span>
  </div>

  <!-- <button class="btn btn-orange">Retirar Fondos</button> -->
</div>

<ng-template #renderTableRow let-propertie="propertie" let-row="row">
  <tr>
    <td scope="row">
      <div class="d-flex align-items-center justify-content-center">
        <img
          *ngIf="!propertie.gallery[0].url"
          src="assets/img/property-img-slider.png"
          class="img-table"
          alt="" />
        <img
          *ngIf="propertie.gallery[0].url"
          src="{{ propertie.gallery[0].url }}"
          class="img-table"
          alt="" />
        {{ propertie.projectName }}
      </div>
    </td>
    <td>
      {{ row.propertiesTokensNumberBuy }}
    </td>
    <td>{{ row.propertiesPriceUSD | currency }} USD</td>
    <td>{{ propertie.estimatedProfitability }} %</td>
  </tr>
</ng-template>

<ng-template #renderUserPorfolio>
  <ng-container *ngFor="let row of userPorfolio">
    <ng-container
      *ngIf="
        row.propertieId | propertie | async as propertie;
        else renderRowLoader
      "
      [ngTemplateOutlet]="renderTableRow"
      [ngTemplateOutletContext]="{
        row: row,
        propertie: propertie
      }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #noNotRecords>
  <tr>
    <th scope="row" colspan="7">
      <h5 class="text-center">No hay registros</h5>
    </th>
  </tr>
</ng-template>

<ng-template #renderCheck>
  <ng-container
    *ngIf="
      userPorfolio.length > 0;
      then renderUserPorfolio;
      else noNotRecords
    "></ng-container>
</ng-template>

<ng-template #loading>
  <ng-container
    *ngFor="let item of [1, 1, 1, 1]"
    [ngTemplateOutlet]="renderRowLoader"></ng-container>
</ng-template>

<ng-template #renderLoaderAmount>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #renderRowLoader>
  <tr>
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
