<div class="position-relative w-100 d-flex">
  <swiper class="swiper mySwiper position-relative" #swiper [config]="config">
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/85.png" alt="" />
            <p>Proyecto</p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/86.png" alt="" />
            <p>
              Plan de <br />
              negocios
            </p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/87.png" alt="" />
            <p>Valorización de activos reales</p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/85.png" alt="" />
            <p>Proyecto</p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/86.png" alt="" />
            <p>
              Plan de <br />
              negocios
            </p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-approval">
        <div class="slider-card">
          <div class="slider-card-header">
            <img src="assets/img/87.png" alt="" />
            <p>Valorización de activos reales</p>
          </div>
          <div class="slider-card-body">
            <span>
              Debe localizarse en países con ambientes de negocios y legales
              favorables</span
            >
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>

  <div class="slider-footer">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/arrow-slider.png"
          class="grey-arrow"
          alt="flecha" />
        <img src="assets/img/arrow-slider.png" class="orange-arrow" alt="" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/arrow-slider.png"
          class="grey-arrow"
          alt="flecha" />
        <img
          src="assets/img/arrow-slider.png"
          class="orange-arrow"
          alt="flecha" />
      </div>
    </div>

    <div class="swiper-pagination"></div>
  </div>
</div>
