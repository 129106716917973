import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "../pipes/pipes.module";
import { RouterModule } from "@angular/router";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { HeaderComponent } from "./header/header.component";
import { ProyectsViewComponent } from "./proyects-view/proyects-view.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { FooterComponent } from "./footer/footer.component";
import { SwiperModule } from "swiper/angular";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RegisterComponent } from "./form/register/register.component";
import { NewsletterComponent } from "./form/newsletter/newsletter.component";
import { LoginComponent } from "./form/login/login.component";
import { ApprovalProcessesComponent } from "./sliders/approval-processes/approval-processes.component";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { NotAccessComponent } from "./not-access/not-access.component";
import { UserProfileSidebarComponent } from "./user-profile-sidebar/user-profile-sidebar.component";
import { ProyectsViewListDesktopComponent } from "./proyects-view-list-desktop/proyects-view-list-desktop.component";
import { InputFileSingleButtonComponent } from "./input-file-single-button/input-file-single-button.component";
import { UserProfileMobileComponent } from "./user-profile-mobile/user-profile-mobile.component";
import { UserProfileTemplateComponent } from "./user-profile-template/user-profile-template.component";
import { ProyectsViewDetailsDesktopSwiperComponent } from "./proyects-view-details-desktop-swiper/proyects-view-details-desktop-swiper.component";
import { ProyectsViewDetailsDesktopInfoComponent } from "./proyects-view-details-desktop-info/proyects-view-details-desktop-info.component";
import { SecurePasswordComponent } from "./form/secure-password/secure-password.component";
import { ProyectsViewListMobileComponent } from "./proyects-view-list-mobile/proyects-view-list-mobile.component";
import { ProyectsViewListItemMobileComponent } from "./proyects-view-list-item-mobile/proyects-view-list-item-mobile.component";
import { SidebarProfileFormComponent } from "./sidebar-profile-form/sidebar-profile-form.component";
import { SidebarMyPropertiesListComponent } from "./sidebar-my-properties-list/sidebar-my-properties-list.component";
import { SidebarConfigUserCodeComponent } from "./sidebar-config-user-code/sidebar-config-user-code.component";
import { NotificationsUsersComponent } from "./notifications-users/notifications-users.component";
import { HeaderAdminComponent } from "./header-admin/header-admin.component";
import { SidebarAdminComponent } from "./sidebar-admin/sidebar-admin.component";
import { ModalFindUserFormComponent } from "./modal-find-user-form/modal-find-user-form.component";
import { FormBuyPropertieComponent } from "./form/form-buy-propertie/form-buy-propertie.component";
import { InputSingleImageComponent } from "./input-single-image/input-single-image.component";

@NgModule({
  declarations: [
    HeaderComponent,
    ProyectsViewComponent,
    FaqsComponent,
    FooterComponent,
    SidebarComponent,
    RegisterComponent,
    NewsletterComponent,
    LoginComponent,
    ApprovalProcessesComponent,
    AdminLayoutComponent,
    NotAccessComponent,
    UserProfileSidebarComponent,
    ProyectsViewListDesktopComponent,
    InputFileSingleButtonComponent,
    UserProfileMobileComponent,
    UserProfileTemplateComponent,
    ProyectsViewDetailsDesktopSwiperComponent,
    ProyectsViewDetailsDesktopInfoComponent,
    SecurePasswordComponent,
    ProyectsViewListMobileComponent,
    ProyectsViewListItemMobileComponent,
    SidebarProfileFormComponent,
    SidebarMyPropertiesListComponent,
    SidebarConfigUserCodeComponent,
    NotificationsUsersComponent,
    HeaderAdminComponent,
    SidebarAdminComponent,
    ModalFindUserFormComponent,
    FormBuyPropertieComponent,
    InputSingleImageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    RouterModule,
    ClipboardModule,
    TranslateModule,
    SwiperModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    RouterModule,
    ClipboardModule,
    TranslateModule,
    HeaderComponent,
    ProyectsViewComponent,
    FaqsComponent,
    FooterComponent,
    SidebarComponent,
    RegisterComponent,
    NewsletterComponent,
    LoginComponent,
    ApprovalProcessesComponent,
    NotAccessComponent,
    UserProfileSidebarComponent,
    InputFileSingleButtonComponent,
    UserProfileMobileComponent,
    UserProfileTemplateComponent,
    SecurePasswordComponent,
    NotificationsUsersComponent,
    HeaderAdminComponent,
    SidebarAdminComponent,
    ModalFindUserFormComponent,
    FormBuyPropertieComponent,
    InputSingleImageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
