<section class="proyects-perfil mt-5 pt-2" id="quienesSomos">
  <div class="container">
    <div class="row">
      <h2 class="title text-center mb-5">
        Descubra el proyecto que mejor <br class="d-none d-md-block" />
        encaja con su perfil
      </h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-xl-8 mb-5 mb-xl-0">
        <div *ngIf="projectOne" class="proyects-perfil-card-proyect">
          <img [src]="projectOne?.thumbnails" class="img-fluid" alt="" />
          <div class="proyects-perfil-card-proyect-content">
            <div class="d-flex flex-column align-items-start">
              <p class="title-card">
                {{ projectOne?.projectName | uppercase }}
              </p>
              <span>Hotel / Spa / Cultivo</span>
              <button class="btn btn-orange">SABER MÁS</button>
            </div>

            <div class="d-flex flex-column align-items-center">
              <p class="percent">{{ projectOne?.estimatedProfitability }}%</p>
              <p class="text-renta">Rentabilidad</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-2 mb-5 mb-xl-0">
        <div *ngIf="projectTwo" class="proyects-perfil-card-proyect">
          <img [src]="projectTwo?.thumbnails" class="img-fluid" alt="" />
          <div class="proyects-perfil-card-proyect-content-three">
            <div class="d-flex flex-column align-items-start">
              <p class="title-card">Descubre</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-2">
        <div *ngIf="projectTwo" class="proyects-perfil-card-proyect">
          <img [src]="projectTwo?.thumbnails" class="img-fluid" alt="" />
          <div class="proyects-perfil-card-proyect-content two">
            <div
              class="d-flex flex-column align-items-center align-items-xl-start w-100">
              <p class="title-card">
                {{ projectTwo?.name }}
              </p>
              <span>Apartamentos</span>
            </div>

            <div class="d-flex flex-column align-items-center w-100">
              <p class="percent">{{ projectTwo?.estimatedProfitability }}%</p>
              <button class="btn btn-orange">SABER MÁS</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="projectOne" class="row mt-5 pt-5">
      <div class="col-12 col-lg-6 proyects-perfil-description mb-5 mb-lg-0">
        <h2 class="title mb-4">
          {{ projectOne?.name }}
        </h2>

        <p class="text" [innerHTML]="projectOne?.description">
          {{ projectOne?.description }}
        </p>

        <div class="d-flex flex-column align-items-center mt-5 btns-proyects">
          <div class="d-flex align-items-center btns-proyects">
            <a
              *ngIf="projectOne.webSite"
              class="btn btn-purple"
              [href]="projectOne.webSite"
              target="_blank"
              >SITIO WEB</a
            >
            <button class="btn btn-orange" (click)="formView()">COMPRAR</button>
          </div>

          <div #formBuy class="w-100 mt-4 d-none">
            <div class="header-buy">Comprar</div>
            <app-form-buy-propertie
              [dataId]="projectOne.addr"></app-form-buy-propertie>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card-color-orange">
              <p>
                Tokens <br class="d-none d-lg-block" />
                Disponibles:
              </p>
              <span *ngIf="projectOne.totalSupply" class="number">
                {{ noComma(projectOne.totalSupply | number : "1.0-0") }}
              </span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card-color-orange img">
              <img [src]="projectOne?.thumbnails" alt="" />
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card-color-orange">
              <p>
                Ubicación <br class="d-none d-lg-block" />
                del proyecto:
              </p>
              <span> Colombia </span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card-color-orange">
              <p>
                Valor del <br class="d-none d-lg-block" />
                Token:
              </p>
              <span>
                {{ getPrice(projectOne?.price) | number : "1.0-0" }} USD
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 align-items-center">
      <div class="col-12 col-lg-6">
        <img src="assets/img/82.png" class="img-fluid" alt="" />
      </div>

      <div class="col-12 col-lg-6 tranfers-description">
        <h2 class="title mb-4">Transfiera sus fondos como mejor le convenga</h2>

        <p class="text">
          Nuestra infraestructura fue creada para que usted no tenga límites,
          por eso utilice transferencias bancarias (*), tarjetas de crédito o
          cryptomonedas para invertir en nuestra plataforma de forma segura.
        </p>

        <div class="d-flex align-items-center mt-5 btns-proyects">
          <button class="btn btn-purple">COMIENZA AHORA</button>
        </div>
      </div>
    </div>
  </div>
</section>
