<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Lista de paquetes</div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Token</th>
                                <th scope="col">Address</th>
                                <th scope="col">Oraculo</th>
                                <th scope="col">Address</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Estado</th>
                              </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngIf="(pairList$ | async) as list; else loading">
                                    <ng-container *ngIf="list.length > 0; else noRecords">
                                        <tr *ngFor="let item of list">
                                            <th scope="row">
                                                {{item.pId}}
                                            </th>
                                            <td>
                                                <ng-container *ngIf="!item.isNative; else nativeName">
                                                    {{item.addr | erc20:'name' | async | uppercase}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="!item.isNative; else nativeName">
                                                        <span class="badge bg-dark" (click)="openBSCScan(item.addr)">
                                                        {{item.addr | truncateWalletAddress}}
                                                        &nbsp;
                                                        <i class="bi bi-link-45deg"></i>
                                                    </span>
                                                </ng-container>
                                            </td>
                                            <td>
                                                
                                                {{item.oracle | chainlinkOracle:'description' | async | uppercase}}
                                            </td>
                                            <td>
                                                <span class="badge bg-dark" (click)="openBSCScan(item.oracle)">
                                                    {{item.oracle | truncateWalletAddress}}
                                                    &nbsp;
                                                    <i class="bi bi-link-45deg"></i>
                                                </span>
                                            </td>
                                            <td>
                                                {{item.isNative ? 'Nativo' : 'Erc20'}}
                                            </td>
                                            <td>
                                                <span class="badge" [ngClass]="{'bg-success': item.active, 'bg-danger': !item.active}">
                                                    {{item.active ? 'Activo' : 'Inactivo'}}
                                                </span>
                                            </td>
                                          </tr>
                                    </ng-container>
                                </ng-container>

                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #nativeName>
    {{nativeCurrency.name | uppercase}}
</ng-template>

<ng-template #noRecords>
    <tr>
        <th scope="row" colspan="6">
            <h5 class="text-center">
                No hay registros
            </h5>
        </th>
    </tr>
</ng-template>

<ng-template #loading>
    <tr *ngFor="let item of [1,1,1,1]">
        <th scope="row" colspan="6" class="placeholder-glow">
            <span class="placeholder placeholder-lg col-12"></span>
        </th>
    </tr>
</ng-template>