import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { cmIsValidAddress } from "src/app/helpers/custom-validations.helper";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-add-token",
  templateUrl: "./add-token.component.html",
  styleUrls: ["./add-token.component.css"],
})
export class AddTokenComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    addr: [
      { type: "required", message: "Is Required" },
      { type: "isValidAddress", message: "Invalid Address" },
    ],
    orc: [
      { type: "required", message: "Is Required" },
      { type: "isValidAddress", message: "Invalid Address" },
    ],
    orcDcm: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
      { type: "max", message: "Max 18" },
    ],
    active: [{ type: "required", message: "Is Required" }],
    native: [{ type: "required", message: "Is Required" }],
  };

  public dataStatus$!: Observable<any>;

  constructor(
    public fb: UntypedFormBuilder,
    public vendorContractSrv: VendorContractService,
    private alertStepsSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.form = fb.group({
      addr: ["", [Validators.required, cmIsValidAddress]],
      orc: ["", [Validators.required, cmIsValidAddress]],
      active: [true, [Validators.required]],
      native: [false, [Validators.required]],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        service: this.vendorContractSrv,
        askMessage: `¿Estas seguro de agregar este token?`,
        method: "vendor_whitelist_addToken",
        params: [_data.addr, _data.orc, _data.active, _data.native],
      });

      if (!result.status) {
        return await this.sweetAlert2Srv.showError(result.data.message);
      } else {
        return this.sweetAlert2Srv
          .showSuccess(`Se agrego exitosamente el token`)
          .then((result) => {
            this.submitted = false;
            this.form.patchValue({
              addr: "",
              orc: "",
              active: true,
              native: false,
            });
          });
      }
    } catch (err) {
      console.log("Error on AddTokenComponent@onSubmit", err);
    }
  }
}
