import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import BigNumber from "bignumber.js";
import { Router } from "@angular/router";
import {
  Subscription,
  catchError,
  from,
  map,
  of,
  switchMap,
  combineLatest,
  startWith,
} from "rxjs";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { fromWei } from "src/app/helpers/utils";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PurchaseService } from "src/app/services/firebase/purchase.service";
import * as moment from "moment";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";

@Component({
  selector: "app-form-buy-propertie",
  templateUrl: "./form-buy-propertie.component.html",
  styleUrls: ["./form-buy-propertie.component.css"],
})
export class FormBuyPropertieComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dataId: any;

  public form!: FormGroup;

  public vm: any = {
    numberTokens: [{ type: "required", message: "Requerido" }],
    amount: [{ type: "required", message: "Requerido" }],
    whiteListToken: [{ type: "required", message: "Requerido" }],
  };

  public submit = false;

  public showSubmitLoaderButton: boolean = false;

  public whitelist: any[] = [];

  public pair: any;

  public propertie: any;

  public user: any;

  public walletBuy: any;

  public nativeCurrency = environment.chain.nativeCurrency;

  public isProduction = environment.production;

  private sub$!: Subscription;

  constructor(
    public fb: FormBuilder,
    private sweetAlert2Srv: Sweetalert2Service,
    private web3Service: Web3Service,
    private vendorContractSrv: VendorContractService,
    private collectionSrv: CollectionService,
    private authSrv: AuthenticationService,
    private purchaseService: PurchaseService,
    private router: Router,
    private alertStepSrv: AlertStepsService
  ) {
    /** Construir formulario */
    this.buildForm();
  }

  ngOnInit() {
    /** Observable de wallet */
    const walletStatus$ = this.web3Service.accountStatus$.pipe(
      startWith([]),
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      catchError((err) => of(null))
    );

    /** Observable de tokens */

    const tokens$ = from(
      this.vendorContractSrv.vendor_whitelist_tokensListoc()
    ).pipe(
      map((data: any[]) =>
        data
          .map((item: any, index: number) => ({ ...item, cId: index }))
          .filter((item: any) => item.active)
      ),
      catchError((err) => of([]))
      // map((data: any[]) => ({tokens: data}))
    );

    /** Observable de usuario */
    const user$ = this.authSrv.uid$.pipe(
      startWith(null),
      switchMap((uid: any) => (uid ? this.authSrv.getByUID(uid) : of(null))),
      catchError((err) => of(null)),
      map((data: any) =>
        data ? { ...data, exist: data !== null } : { exist: false }
      )
    );

    this.sub$ = combineLatest([walletStatus$, tokens$, user$])
      .pipe(
        map(([walletStatus, tokens, user]) => ({
          walletStatus,
          tokens,
          user,
        }))
      )
      .subscribe((vm) => {
        // console.log("vm", vm);

        /** Si el formulario permanece desactivado - Activar */
        if (this.form.disabled) {
          this.showSubmitLoaderButton = false;
          this.form.enable();
        }
        /** Cargar listado de tokens de la whitelist */
        this.whitelist = vm.tokens;

        /** Cargar documento de usuario */
        this.user = vm.user;

        /** Cargar wallet */
        this.walletBuy = vm.walletStatus;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { dataId } = changes;
    /** Observable de propiedades */
    if (dataId && dataId.currentValue) {
      this.dataId = dataId.currentValue;
      this.sub$ = this.collectionSrv
        .getById(this.dataId)
        .pipe(
          map((data: any) => (data ? data : null)),
          catchError((err) => of(null)),
          map((data: any) =>
            data ? { ...data, exist: data !== null } : { exist: false }
          )
        )
        .subscribe((propertie) => {
          /** Cargar propiedades */
          this.propertie = propertie;
          // console.log(this.propertie);
          this.form.setValue({
            numberTokens: "",
            amount: "",
            whiteListToken: "",
          });
        });
    }
  }

  /**
   * @dev Construir formulario de elegir propiedad y metodo de pago
   */
  buildForm() {
    const whiteListToken = "";
    this.form = this.fb.group({
      numberTokens: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      whiteListToken: [whiteListToken, [Validators.required]],
    });

    this.form.disable();
    this.showSubmitLoaderButton = true;
  }

  get f() {
    return this.form.controls;
  }

  /**
   * @dev Al cambiar el método de pago
   */
  onChangeWhitelist() {
    const token = this.form.value.whiteListToken;
    this.pair = this.whitelist.find((item: any) => item.addr === token);
  }

  /**
   * Incrementar Valor de input de números
   * @returns
   */
  addTokens() {
    /** Si el formulario permanece desactivado - No permitir acciones */
    if (this.form.disabled) {
      return;
    }

    /** Si no existe propiedad - No permitir acciones */
    if (!this.propertie) {
      return;
    }

    const _v = isNaN(this.form.get("numberTokens")?.value)
      ? 1
      : Number(this.form.get("numberTokens")?.value);
    const numberTokens = _v + 1;

    this.form.patchValue({
      numberTokens: numberTokens,
      amount: fromWei(
        new BigNumber(this.propertie.price).multipliedBy(numberTokens).toFixed()
      ),
    });
  }

  /**
   * Decrementar Valor de input de números
   */
  restTokens() {
    /** Si el formulario permanece desactivado - No permitir acciones */
    if (this.form.disabled) {
      return;
    }

    /** Si no existe propiedad - No permitir acciones */
    if (!this.propertie) {
      return;
    }

    const _v = isNaN(this.form.get("numberTokens")?.value)
      ? 1
      : Number(this.form.get("numberTokens")?.value);
    const numberTokens = _v - 1 <= 0 ? 0 : _v - 1;

    const amount =
      numberTokens > 0
        ? fromWei(
            new BigNumber(this.propertie.price)
              .multipliedBy(numberTokens)
              .toFixed()
          )
        : 0;

    this.form.patchValue({
      numberTokens: numberTokens,
      amount: amount,
    });
  }

  launchCompleteIncompletedAlert() {
    this.sweetAlert2Srv
      .showWarning(
        "Debes terminar de configurar tu cuenta para poder realizar la compra, por favor ve a tu perfil y completa tu información"
      )
      .then(() => this.router.navigate(["/dashboard-user/profile"]));
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      const formData = this.form.value;
      // console.log("formData", formData);

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        const formErrors = getFormErrors(this.form);
        // console.log('formErrors', formErrors);
        return;
      }

      // await this.spinner.show();

      this.showSubmitLoaderButton = true;
      this.form.disable();

      /** Válidar si posee wallet registrada */
      if (!this.user || !this.user.walletAddress) {
        this.sweetAlert2Srv.showWarning(
          "No se encontró la wallet asociada a su cuenta, favor completar el registro de su cuenta e intentar nuevamente"
        );
        return;
      }

      /** Válidar wallet conectada con wallet registrada */
      if (this.user.walletAddress !== this.walletBuy) {
        this.sweetAlert2Srv.showWarning(
          "La wallet que esta conectando no es la misma que se encuentra asociada a su cuenta, favor verificar e intentar nuevamente."
        );
        return;
      }

      // console.log('try to buy');
      await this.buyCrypto(formData);
      return;
    } catch (err: any) {
      console.log("Error on buyPropertie.onSubmit", err);
      this.sweetAlert2Srv.showWarning(err.message);
      return;
    } finally {
      this.showSubmitLoaderButton = false;
      // this.spinner.hide();
      this.form.enable();
    }
  }

  /**
   * Al enviar formulario de crypto
   * @returns
   */

  async buyCrypto(formData: any) {
    try {
      /** Calcular Amount to Pay - Monto a Pagar */
      const atp =
        await this.vendorContractSrv.vendor_oracle_parseUSDtoToken_OFFCHAIN(
          new BigNumber(this.propertie.price)
            .multipliedBy(formData.numberTokens)
            .toFixed(),
          formData.whiteListToken
        );
      // console.log('atp', atp);

      /** Construir datos para la compra */
      const data = {
        cIdx: this.propertie.collectionId,
        token: this.pair.addr,
        // code: this.user.referralCode,
        code: "prueba",
        amount: Number(formData.numberTokens),
      };
      // console.log("data", data);

      const method = this.pair.isNative
        ? "vendor_buy_buyNative"
        : "vendor_buy_buyWithToken";
      // console.log("Method", method);

      const params = this.pair.isNative
        ? [data.cIdx, this.pair.addr, data.amount, data.code, atp]
        : [data.cIdx, this.pair.addr, data.amount, data.code];
      // console.log("params", params);

      const message = "Comprar";
      const result = this.pair.isNative
        ? await this.alertStepSrv.showStepsNative({
            service: this.vendorContractSrv,
            askMessage: `${message} ${data.amount} NFTs?`,
            checkBalanceParams: { amount: atp },
            contractParams: { method: method, params: params },
          })
        : await this.alertStepSrv.showStepsWithApproved({
            service: this.vendorContractSrv,
            askMessage: `${message} ${data.amount} NFTs?`,
            checkBalanceParams: { contract: this.pair.addr, amount: atp },
            approvedParams: {
              contract: this.pair.addr,
              amount: atp,
              spender: environment.vendorAddress,
            },
            contractParams: { method: method, params: params },
          });

      if (!result.status) {
        this.sweetAlert2Srv.showError(result.data.message);
        return;
      }

      /** Capturar hora de creación */
      const createdAt = moment().valueOf();

      /** Construir datos de la compra para la base de datos */
      const buyDocument = {
        userId: this.user._id,
        username: this.user.name,
        useremail: this.user.email,
        userphone: this.user.phoneNumber,
        userWallet: this.user.walletAddress,
        // userCodeRefered: this.user.referralCode, // TODO: todavia falta definir,
        userCodeRefered: "code",
        // propertie: this.propertie, // TODO: revisar por que guardar documento de la propiedad
        propertieId: this.propertie.addr,
        propertiesTokensNumberBuy: Number(formData.numberTokens),
        propertiesPriceUSD: Number(formData.amount),
        propertiesPriceToPay: atp,
        txHash: result.data.transactionHash,
        methodBuy: "crypto",
        createdAt: createdAt,
        statusBuy: "success",
        token: {
          addr: this.pair.addr,
          isNative: this.pair.isNative,
        },
      };

      /** Guardar documento de compra en la base de datos */
      const purchaseId = await this.purchaseService.store(buyDocument);
      console.log("purchaseId", purchaseId);

      console.log(result);

      this.sweetAlert2Srv.showAlertWithTxHash(result.data).then(() => {
        this.web3Service.logout(true);
      });
      return;
    } catch (err) {
      console.log("Error on buyCrypto", err);
      return;
    }
  }

  launchOpenConnection() {
    this.web3Service.launchAskConnectionType();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
