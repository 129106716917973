import { Component, OnInit } from '@angular/core';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-renounce-admin',
  templateUrl: './renounce-admin.component.html',
  styleUrls: ['./renounce-admin.component.css']
})
export class RenounceAdminComponent implements OnInit {

  constructor(
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
  ) { }

  ngOnInit(): void { }

  async onSubmit() {
    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Renunciar como Super Administrador?`,
        contractParams: { method: 'vendor_administered_renounceAdmin', params: null }
      });


      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Renuncio como Super Administrador correctamente`)
        .then((result) => { });
      }

    } catch (err) {
      console.log('Error on RenounceAdminComponent@onSubmit', err);
    }
  }
}