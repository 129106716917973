<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">
            Retirar {{nativeToken | uppercase}}
        </div>

        <div class="fs-small mb-3">
            Balance: 

            <ng-container *ngIf="(deadWallet | balance:contract | async) as balance; else loadingBalance">
                <ng-container *ngIf="balance > 0; else noBalance">
                    {{ balance  }}
                </ng-container>
            </ng-container>
            
            {{ this.nativeToken | uppercase }}
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="input-group mb-3">
                <span class="input-group-text">
                    Monto
                </span>
                <input type="number" formControlName="amount" class="form-control" placeholder='0'>
                <button class="btn btn-primary" type="submit">Retirar</button>
            </div>

            <ng-container *ngIf="submitted">
                <ng-container *ngFor="let vm of vm.amount">
                    <div id="dayHelp" class="form-text text-danger" *ngIf="f['amount'].hasError(vm.type)">
                        {{ vm.message }}
                    </div>
                </ng-container>
            </ng-container>
            
        </form>

    </div>
</div>

<ng-template #loadingBalance>
    Cargando ...
</ng-template>

<ng-template #noBalance>
    0.00
</ng-template>
