<div *ngIf="!mobile">
  <app-user-profile-template></app-user-profile-template>
  <div class="tab mt-4">
    <button
      style="cursor: default"
      class="tablinks left active"
      id="left-login">
      Mi Perfil
    </button>
  </div>
</div>

<ng-container
  *ngIf="userDoc; then renderForm; else renderLoaderForm"></ng-container>

<ng-template #renderForm>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100 px-4 mt-4">
    <div *ngIf="!userDoc?.avatar" class="user-img"></div>

    <div *ngIf="imgUpdate && !userDoc?.avatar" class="user-img">
      <img [src]="imgUpdate" class="img-fluid" />
    </div>
    <div *ngIf="userDoc?.avatar && imgUpdate" class="user-img">
      <img [src]="imgUpdate" class="img-fluid" />
    </div>

    <div *ngIf="userDoc?.avatar && !imgUpdate" class="user-img">
      <img [src]="userDoc?.avatar" class="img-fluid" />
    </div>

    <ng-container *ngIf="!imgUpdate; else renderUpdateAvatar">
      <button class="btn btn-change-img-profile w-100" (click)="onAddImage()">
        <i class="bi bi-camera-fill me-2"></i>

        <span> Foto de Perfil</span>
      </button>
    </ng-container>
  </div>

  <form
    class="form-sidebar mt-3"
    [formGroup]="form"
    novalidate
    autocomplete="off">
    <div class="row flex-column">
      <!-- Input Name -->
      <div class="col mb-1">
        <input
          type="text"
          class="form-control"
          placeholder="Nombre"
          aria-label="Nombre"
          formControlName="name" />
        <ng-container
          [ngTemplateOutlet]="renderVM"
          [ngTemplateOutletContext]="{ field: 'name' }"></ng-container>
      </div>

      <!-- Input Prefix -->
      <div class="col mb-2">
        <select
          class="form-select"
          formControlName="prefix"
          aria-label="Prefijo">
          <option value="" selected>Elegir...</option>
          <option *ngFor="let item of country" [value]="item.phonecode">
            (+{{ item.phonecode }}) {{ item.name }}
          </option>
        </select>
        <ng-container
          [ngTemplateOutlet]="renderVM"
          [ngTemplateOutletContext]="{ field: 'prefix' }"></ng-container>
      </div>

      <!-- Input Phone number -->
      <div class="col mb-1">
        <input
          type="text"
          class="form-control"
          placeholder="Número de teléfono"
          aria-label="Número de teléfono"
          formControlName="phoneNumber" />
        <ng-container
          [ngTemplateOutlet]="renderVM"
          [ngTemplateOutletContext]="{ field: 'phoneNumber' }"></ng-container>
      </div>

      <div class="col mb-1 mt-4">
        <div class="d-grid gap-2">
          <button class="btn btn-orange" (click)="onSubmit()">
            Actualizar
          </button>

          <button
            class="btn btn-orange"
            (click)="templateSrv.setDefaultSidebarOption()">
            Volver
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submitted">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | titlecase }}
      </span>
    </ng-container>
  </div>
</ng-template>

<ng-template #renderLoaderForm>
  <div class="form-sidebar">
    <div class="row g-2">
      <div class="col-12 placeholder-glow mb-3">
        <span class="placeholder col-12" style="height: 36px"></span>
      </div>
      <div class="col-12 placeholder-glow mb-3">
        <span class="placeholder col-12" style="height: 36px"></span>
      </div>
      <div class="col-12 placeholder-glow mb-3">
        <span class="placeholder col-12" style="height: 36px"></span>
      </div>
      <div class="col-12">
        <div class="d-grid gap-2">
          <a
            href="#"
            tabindex="-1"
            class="btn btn-orange disabled placeholder col-12"></a>
          <a
            href="#"
            tabindex="-1"
            class="btn btn-orange disabled placeholder col-12"></a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderUpdateAvatar>
  <button class="btn btn-change-img-profile w-100" (click)="saveAvatar()">
    <i class="bi bi-camera-fill me-1"></i>
    Actualizar
  </button>
</ng-template>

<app-input-single-image
  (onpUpdateImages)="onSelectImages($event)"
  [multiple]="true"></app-input-single-image>
