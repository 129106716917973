<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Enviar NFT de Cortesía</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <!-- <input type="number" formControlName="idx" class="form-control" placeholder="ID de la Colección"> -->
                <select class="form-select" formControlName="idx" placeholder="Colección">
                    <option *ngFor="let item of (collections$ | async)" [value]="item.id">
                        {{item.addr | customERC721a:'name' | async | titlecase}}
                    </option>
                </select>
                <label for="floatingInput">
                    ID de la Colección
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.idx">
                        <div class="form-text text-danger mb-2" *ngIf="f['idx'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="text" formControlName="addr" class="form-control" placeholder="Dirección">
                <label for="floatingInput">
                    Dirección
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.addr">
                        <div class="form-text text-danger mb-2" *ngIf="f['addr'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-floating mb-3">
                <input type="number" formControlName="nro" class="form-control" placeholder="Cantidad">
                <label for="floatingInput">
                    Cantidad
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.nro">
                        <div class="form-text text-danger mb-2" *ngIf="f['nro'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Enviar
                </button>
            </div>
        </form>
    </div>
</div>