<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">
            Retirar tokens
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="input-group mb-3">
                <span class="input-group-text">ERC20</span>
                <select class="form-select" formControlName="token" placeholder="ERC20">
                    <ng-container *ngIf="(erc20List$ | async) as list">
                        <option *ngFor="let item of list" [value]="item.addr">

                            <ng-container 
                                *ngIf="!item.isNative; else renderNativeCurrencyLabel" 
                                [ngTemplateOutlet]="renderERC20Label" 
                                [ngTemplateOutletContext]="{addr: item.addr}"
                            ></ng-container>
                        </option>
                    </ng-container>
                </select>
            </div>

            <ng-container *ngIf="submitted">
                <ng-container *ngFor="let item of vm.token">
                    <div class="form-text text-danger mb-2" *ngIf="f['token'].hasError(item.type)">
                        {{item.message | uppercase}}
                    </div>
                </ng-container>
            </ng-container>

            <!-- <div class="form-floating mb-3">
                <select class="form-select" formControlName="token" placeholder="Estado">
                    <option *ngFor="let item of TokenList; let indice=index" [value]="item">
                        <div> 
                            {{item | erc20:"name" | async}} 
                            <br>
                            {{ item | balance:contract | async }}
                        </div>
                    </option>
                </select>
                <label for="floatingInput">
                    Token a retirar
                </label>
            </div> -->

            <div class="input-group mb-3">
                <span class="input-group-text">
                    Monto
                </span>
                <input type="number" formControlName="amount" class="form-control" placeholder='0'>
                <button class="btn btn-primary" type="submit">Retirar</button>
            </div>

            <ng-container *ngIf="submitted">
                <ng-container *ngFor="let item of vm.amount">
                    <div class="form-text text-danger mb-2" *ngIf="f['amount'].hasError(item.type)">
                        {{item.message | uppercase}}
                    </div>
                </ng-container>
            </ng-container>

        </form>
    </div>
</div>

<ng-template #renderERC20Label let-addr="addr">
    {{ addr | erc20 : "name" | async | uppercase }} ({{ addr | erc20 : "balanceOf":[vendorContractSrv.contractAddress] | async | fromWei}})
</ng-template>

<ng-template #renderNativeCurrencyLabel>
    {{nativeCurrency.symbol | uppercase}}
</ng-template>


