import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { lastValueFrom, Observable } from "rxjs";
import * as moment from "moment";
import { handlerArrayResult } from "src/app/helpers/model.helper";
import { environment } from "src/environments/environment";
import { getCollectionName } from "src/app/helpers/utils";

@Injectable({
  providedIn: "root",
})
export class PurchaseService {
  public apiURL = environment.API_URL;
  // public collection = "purchases";
  public collection = getCollectionName("purchases");

  constructor(private afs: AngularFirestore, private http: HttpClient) {}

  generateDocId() {
    return this.afs.createId();
  }

  buildPurchaserDocument(params: PurchaseStruct) {
    const {
      payload = null,
      metadata = null,
      txHash = null,
      group = null,
      collectionId = null,
      nroTokens = 1,
      price = 0,
    } = params;
    const currentDate = moment();

    return {
      uid: params.uid,
      addr: params.addr,
      price: price,
      amount: params.amount,
      amountUSD: params.amountUSD,
      nroTokens: nroTokens,
      metadata: metadata,
      paymentMethodType: params.paymentMethodType,
      createdAt: currentDate.valueOf(),
      updatedAt: null,
      txHash: txHash,
      collectionId: collectionId,
    };
  }

  /**
   * Obtener listado completo
   * @returns
   */
  async getAll() {
    const snapshot = await this.afs.collection(this.collection).ref.get();
    return await handlerArrayResult(snapshot);
  }

  /* Registrar documento de la compra
   * @param data
   * @returns
   */
  async store(data: any) {
    const snapshot = await this.afs.collection(this.collection).add(data);
    return snapshot.id;
  }

  /**
   * Actualizar
   * @param docId
   * @param data
   * @returns
   */
  async update(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   * @param opts.startAt
   * @param opts.endAt
   *
   * @returns
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      endAt = null,
      orderBy = [],
    } = opts;

    return this.afs
      .collection(this.collection, (ref) => {
        let query: Query = ref;
        for (const row of where) {
          query = query.where(row.field, row.condition, row.value);
        }

        for (const order of orderBy) {
          query = query.orderBy(order.field, order.order);
        }

        if (startAt) {
          query = query.startAt(startAt);
        }

        if (endAt) {
          query = query.endAt(endAt);
        }

        return query;
      })
      .valueChanges({ idField });
  }

  async getDynamicToPromise(where: any[] = [], opts: any = {}): Promise<any[]> {
    try {
      const {
        idField = "_id",
        startAt = null,
        endAt = null,
        orderBy = [],
      } = opts;

      const snapshot = await this.afs
        .collection(this.collection, (ref) => {
          let query: Query = ref;
          for (const row of where) {
            query = query.where(row.field, row.condition, row.value);
          }

          for (const order of orderBy) {
            query = query.orderBy(order.field, order.order);
          }

          if (startAt) {
            query = query.startAt(startAt);
          }

          if (endAt) {
            query = query.endAt(endAt);
          }

          return query;
        })
        .get()
        .toPromise();

      return await handlerArrayResult(snapshot, { idField });
    } catch (err) {
      console.log("Error on PurchaseService.getDynamicToPromise", err);
      return [];
    }
  }

  /**
   * Reclamar NFT de la orden de compra
   * @param orderId
   * @returns
   */
  async claimNFT(orderId: string) {
    try {
      const url = `${this.apiURL}/purchase/claim`;
      // console.log('url', url);

      const snapshot = await lastValueFrom(this.http.post(url, { orderId }));
      return true;
    } catch (err) {
      console.log("Error on PurchaseService.claimNFT", err);
      throw err;
    }
  }

  /**
   * Obtener monto total de compras de un usuario
   * @param addr      Wallet
   * @returns
   */
  async getTotalUserPurchasesAmount(addr: string): Promise<number> {
    try {
      const url = `${environment.API_URL}/api/v1/purchases/total-user-purchases`;
      const snapshot: any = await lastValueFrom(this.http.post(url, { addr }));
      return snapshot.results.total;
    } catch (err) {
      console.log("Error on PurchasesService.getTotalUserPurchasesAmount", err);
      return 0;
    }
  }

  /**
   *
   * @param addr
   * @returns
   */
  async getUserPurchasesPaginated(
    addr: string,
    size: number = 10,
    page: number = 1
  ): Promise<any> {
    try {
      const url = `${environment.API_URL}/api/v1/purchases/user-purchases`;
      const snapshot: any = await lastValueFrom(
        this.http.post(url, { addr, size, page })
      );

      const { results } = snapshot;
      const maxPages = Math.ceil(results.total / size);
      return {
        page: page,
        size: size,
        total: results.total,
        rows: results.rows,
        back: page > 1,
        next: maxPages > page,
      };
    } catch (err) {
      console.log("Error on PurchasesService.getUserPurchasesPaginated", err);
      return {
        page: page,
        size: size,
        total: 0,
        rows: [],
        back: false,
        next: false,
      };
    }
  }

  async getUserDirectBonusRecordsPaginated(
    addr: string,
    size: number = 10,
    page: number = 1
  ): Promise<any> {
    try {
      const url = `${environment.API_URL}/api/v1/purchases/user-direct-bonus`;
      const snapshot: any = await lastValueFrom(
        this.http.post(url, { addr, size, page })
      );

      const { results } = snapshot;
      const maxPages = Math.ceil(results.total / size);
      return {
        page: page,
        size: size,
        total: results.total,
        rows: results.rows,
        back: page > 1,
        next: maxPages > page,
      };
    } catch (err) {
      console.log(
        "Error on PurchasesService.getUserDirectBonusRecordsPaginated",
        err
      );
      return {
        page: page,
        size: size,
        total: 0,
        rows: [],
        back: false,
        next: false,
      };
    }
  }
}

export interface PurchaseStruct {
  id?: string;
  uid?: string;
  addr?: string | null;
  price: number;
  amount: number;
  amountUSD: number;
  nroTokens: number;
  payload?: any;
  metadata?: any;
  paymentMethodType: string;
  createdAt?: number;
  updatedAt?: number;
  status?: string;
  completed?: boolean;
  collectionId?: string | number;
  txHash?: string;
  group?: string | null;
}
