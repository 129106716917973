export const environment = {
  production: false,
  dbPrefix: "dev_",
  seedKey: "DEV_ELYSIUM_2024$$**$%&",

  projectName: "ELYSIUM",
  API_KEY_BSC: "4S2UBP9NC7GS85BUV4HIJ76HNV73AY8YNH",
  API_URL: "https://api-dev.elysiumcap.io",
  urlWeb: "https://front-dev.elysiumcap.io",
  configUrlAbi: "/assets/abi/erc721s.json",
  contactEmail: "hola@investok.co",

  firebase: {
    apiKey: "AIzaSyAtTXG4Www5-ft5SBvImyS16plpVRst3jo",
    authDomain: "elysium-7ecbc.firebaseapp.com",
    projectId: "elysium-7ecbc",
    storageBucket: "elysium-7ecbc.appspot.com",
    messagingSenderId: "1067003345874",
    appId: "1:1067003345874:web:ea2934e8a40d5233f9e60b",
    measurementId: "G-W3LTDK0WMK",
  },

  marketplaceAddress: "0xF3Dcd2d7DFc44460b867ff1069D6f35776c8FA27",
  // vendorAddress: "0x21f5b7795aABfeb42581f2bb696d2F806B1cbFbd",
  // vendorAddress: "0xc6110F1c0c063cda5170f25Eea04d30DE41911C6", // Contrato actual de investok
  // distributionAddress: "0xFca20Bff0801109cb7E24F1536cd41AD63980327",
  // saleDistributionAddress: "0x842e3BCcA188EA695c64fA5DcfB6FA87A4f1fdAd",

  vendorAddress: "0xa0C312eA4dBd35EB382331924A2e1C9c7bfa8263",
  distributionAddress: "0x6DA97477ef24DC009a7fE3e2EbAb22cC924270A8",
  saleDistributionAddress: "0x488f845b201b90f54c1D63B5765a22D6317061b7",

  factoryAddress: "0x43f13c286D7EBd650BDDb0430fa68b98b39495B7",
  addressdead: "0x000000000000000000000000000000000000dEaD",

  infuraId: "",

  urlTokenLogo: "#",
  mainToken: {
    contract: "#",
    name: "#",
    symbol: "#",
    decimals: 18,
  },
  appDefaultReferralCode: "genesis",
  chain: {
    walletConnectID: "4af18cacaeb09f42b4d325033743f639",
    infuraId: "356256bc3fcf42de88d2bc2e129ea5d9",

    // TODO configuracion con elysium

    // Testnet
    chainId: 97,
    chainIdMetamask: "0x61",
    chainName: "BNB Smart Chain Testnet",
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],

    // Mainnnet
    // chainId: 56,
    // chainIdMetamask: "0X38",
    // chainName: "BNB Smart Chain Mainnet",
    // rpc: "https://nd-294-169-534.p2pify.com/58eedd73e9cd4bf06c5abc664b34f4da",
    // rpcUrls: ["https://nd-294-169-534.p2pify.com/58eedd73e9cd4bf06c5abc664b34f4da"],
    // blockExplorerUrls: ["https://bscscan.com/"],
    // scan: "https://bscscan.com/tx/",
    // scanNft: "https://bscscan.com/token/",

    nativeCurrency: {
      web3ModalNetwork: "binance",
      network: "BNB",
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },

    // TODO configuracion con investok

    // chainId: 80001,
    // chainIdMetamask: "0x13881",
    // chainName: "Polygon Mumbai Testnet",
    // // rpc: "https://rpc.ankr.com/polygon_mumbai/",
    // // rpcUrls: ["https://rpc.ankr.com/polygon_mumbai/"],
    // rpc: "https://polygon-mumbai-pokt.nodies.app/",
    // rpcUrls: ["https://polygon-mumbai-pokt.nodies.app/"],
    // blockExplorerUrls: ["https://mumbai.polygonscan.com/"],

    // nativeCurrency: {
    //   web3ModalNetwork: "polygon",
    //   network: "MATIC",
    //   name: "MATIC",
    //   symbol: "MATIC",
    //   decimals: 18,
    // },

  },
};
