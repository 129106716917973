<div class="row mt-4">
  <div class="col-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/admin/dashboard">
            <i class="bi bi-arrow-left"></i>&nbsp;Dashboard
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Configuración
        </li>
      </ol>
    </nav>
  </div>

  <div class="col-12 mb-4">
    <h5 class="title-orange">Configuración</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-add-user></app-add-user>
  </div>

  <div class="col-12 col-lg-6">
    <app-add-admin></app-add-admin>
  </div>

  <div class="col-12 col-lg-6">
    <app-remove-user></app-remove-user>
  </div>

  <div class="col-12 col-lg-6">
    <app-renounce-admin></app-renounce-admin>
  </div>

  <hr />

  <div class="col-12 mb-4">
    <h5 class="text-center">WhiteList Tokens</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-add-token></app-add-token>
  </div>

  <div class="col-12 col-lg-6">
    <app-update-token></app-update-token>
  </div>

  <div class="col-12">
    <app-whitelist-list></app-whitelist-list>
  </div>

  <hr />

  <div class="col-12 mb-4">
    <h5 class="text-center">Collections</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-add-collection-form></app-add-collection-form>
  </div>

  <div class="col-12 col-lg-6">
    <app-update-collection-form></app-update-collection-form>
  </div>

  <div class="col-12 col-lg-6">
    <app-import-collection-form></app-import-collection-form>
  </div>

  <div class="col-12 col-lg-6">
    <app-transfer-reserved-form></app-transfer-reserved-form>
  </div>

  <div class="col-12">
    <app-collection-list></app-collection-list>
  </div>

  <hr />

  <div class="col-12 mb-4">
    <h5 class="text-center">Utils</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-set-contract-fee-form></app-set-contract-fee-form>
  </div>

  <div class="col-12 col-lg-6">
    <app-set-contract-vault-from></app-set-contract-vault-from>
  </div>

  <hr />

  <div class="col-12 mb-4">
    <h5 class="text-center">Withdraw</h5>
  </div>

  <div class="col-12 col-lg-6">
    <app-withdraw-native></app-withdraw-native>
  </div>

  <div class="col-12 col-lg-6">
    <app-withdraw-erc20></app-withdraw-erc20>
  </div>
</div>

<!-- <div class="mt-5">
  <div class="col-12 col-md-6 mb-3" *ngIf="isUser">
    <div class="card-dashboard">
      <div class="title fw-bold">
        Administrar Vendor
      </div>

      <div class="c-button" (click)="modalCollectionAdd.show()">
        <button class="btn-three btn-admin fw-bold px-5"> Agregar Colleccion</button>
      </div>
    </div>
  </div>
  <div class="row aling-items-stretch">
    <ng-container *ngIf="isAdmin">
      <div class="col-12 col-lg-6 mb-4">
        <app-add-admin-vendor></app-add-admin-vendor>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-add-user></app-add-user>
      </div>
      <div class="col-12 mb-4">
        <app-remove-user></app-remove-user>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-renounce-admin></app-renounce-admin>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-withdraw></app-withdraw>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-withdraw-token></app-withdraw-token>
      </div>
    </ng-container>

    <ng-container *ngIf="isUser">
      <div class="col-12 mb-4">
        <app-collection-list></app-collection-list>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-transfer-reserved></app-transfer-reserved>
      </div>
      <div class="col-12 col-lg-6 mb-4">
        <app-add-token></app-add-token>
      </div>
      <div class="col-12 mb-4">
        <app-update-collection></app-update-collection>
      </div>
      <div class="col-12 mb-4">
        <app-update-token></app-update-token>
      </div>
    </ng-container>

  </div>
</div>

<app-add-collection></app-add-collection> -->
