import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import * as moment from "moment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { handlerObjectResult } from "src/app/helpers/model.helper";
import { getCollectionName } from "src/app/helpers/utils";

@Injectable({
  providedIn: "root",
})
export class CollectionService {
  public collection = getCollectionName("collections");

  public collectionStatus = [
    {
      value: "draft",
      label: "Draft",
      help: "La colección no se mostrará en la web",
    },
    {
      value: "preview",
      label: "Preview",
      help: "La colección se mostrará en la web pero no se podrá comprar, solo se podrá visualizar el contenido multimedia",
    },
    {
      value: "opening",
      label: "Opening",
      help: "La colección se mostrará en la web y se podrá comprar",
    },
    {
      value: "closed",
      label: "Closed",
      help: "La colección se mostrará en la web pero no se podrá comprar",
    },
    {
      value: "soldOut",
      label: "Sold out",
      help: "La colección se mostrará en la web pero no se podrá comprar",
    },
  ];

  /**
   * Observable al seleccionar una colección en la vista
   * Estructura:
   * - action: string
   * - data: any
   */
  public onViewSelectedCollection$ = new Subject<any>();

  public deafultThumbnails = "assets/img/loaderListItem.png";
  public defaultSwiper = "assets/img/SwiperLoader.png";
  public defaultImage = "assets/img/ProjectDetailLoader.png";

  constructor(public afs: AngularFirestore) {}

  async set(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).set(data);
  }

  /**
   * Obtener a través del identificador
   * @param docId
   * @returns
   */
  getById(docId: any) {
    return this.afs.collection(this.collection).doc(docId).valueChanges();
  }

  async update(docId: string, data: any) {
    const fmt = Object.assign({}, data, { updatedAt: moment().valueOf() });
    return await this.afs.collection(this.collection).doc(docId).update(fmt);
  }

  async getByIdPromise(docId: string) {
    const snapshot = await this.afs
      .collection(this.collection)
      .doc(docId)
      .get()
      .toPromise();
    return await handlerObjectResult(snapshot);
  }

  async remove(docId: string) {
    return await this.afs.collection(this.collection).doc(docId).delete();
  }

  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   * @param opts.startAt
   * @param opts.endAt
   * @param opts.limit
   *
   * @returns
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      endAt = null,
      orderBy = [],
      limit = null,
    } = opts;

    return this.afs
      .collection(this.collection, (ref) => {
        let query: Query = ref;
        for (const row of where) {
          query = query.where(row.field, row.condition, row.value);
        }

        for (const order of orderBy) {
          query = query.orderBy(order.field, order.order);
        }

        if (startAt) {
          query = query.startAt(startAt);
        }

        if (endAt) {
          query = query.endAt(endAt);
        }

        if (limit) {
          query = query.limit(limit);
        }

        return query;
      })
      .valueChanges({ idField });
  }
}
