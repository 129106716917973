<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Modificar token</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <!-- <input type="number" formControlName="id" class="form-control" placeholder="0"> -->
                <select class="form-select" formControlName="id" placeholder="Colección">
                    <option *ngFor="let item of (tokenList$ | async)" [value]="item.id">
                        <ng-container
                            [ngTemplateOutlet]="(!item.isNative) ? renderERC20Name: renderNativeName"
                            [ngTemplateOutletContext]="{addr: item.addr}"
                        ></ng-container>
                    </option>
                </select>
                <label for="floatingInput">
                    Id del token
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.id">
                        <div class="form-text text-danger mb-2" *ngIf="f['id'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text">Tipo de cambio</span>
                <select class="form-select" formControlName="type" placeholder="Estado">
                    <option [value]="1">Direccion del oraculo</option>
                    <option [value]="2">Estado</option>
                    <option [value]="3">Tipo: Nativo o BEP20</option>
                </select>
            </div>

            <div class="form-floating mb-3">
                <input type="text" formControlName="address" class="form-control" placeholder="0x...">
                <label for="floatingInput">
                    Direccion
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.address">
                        <div class="form-text text-danger mb-2" *ngIf="f['address'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text">
                    
                    <ng-container *ngIf="f['type'].value === '3'">
                        Tipo de Token
                    </ng-container>

                    <ng-container *ngIf="f['type'].value != '3'">
                        Estado a aplicar
                    </ng-container>

                </span>
                <select class="form-select" formControlName="bool" placeholder="Estado">
                    <option [value]="false">

                        <ng-container *ngIf="f['type'].value === '3'">
                            BEP20
                        </ng-container>

                        <ng-container *ngIf="f['type'].value != '3'">
                            Falso
                        </ng-container>

                    </option>
                    <option [value]="true">

                        <ng-container *ngIf="f['type'].value === '3'">
                            Nativo
                        </ng-container>

                        <ng-container *ngIf="f['type'].value != '3'">
                            Verdadero
                        </ng-container>

                    </option>
                </select>
            </div>
            
            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>


<ng-template #renderERC20Name let-addr="addr">
    {{addr | erc20:'name' | async | titlecase}}
</ng-template>

<ng-template #renderNativeName>
    {{nativeCurrency.name | uppercase}}
</ng-template>

