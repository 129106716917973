<div *ngIf="!mobile">
  <app-user-profile-template></app-user-profile-template>
  <div class="tab mt-4">
    <button class="tablinks left active" id="left-login">
      Mis Propiedades
    </button>
  </div>
</div>

<app-sidebar-my-properties-list></app-sidebar-my-properties-list>
