<div class="position-relative">
  <section class="proyects" id="main" [ngClass]="{ active: showNavbar }">
    <!-- Desktop View -->
    <div class="container-fluid d-none d-xl-block" *ngIf="!mobile">
      <div class="row">
        <!-- Left Sidebar -->
        <div class="col-12 col-xl-4" style="padding-left: 0">
          <div class="pt-3 pt-xl-5">
            <h4 class="pb-3">PROYECTOS DISPONIBLES</h4>

            <!-- Sidebar - Projects List -->
            <app-proyects-view-list-desktop></app-proyects-view-list-desktop>

            <div class="proyects-regulation">
              <p class="mb-3 text-regulation">
                Aportamos impuestos y <br />
                cumplimos con regulaciones de:
              </p>
              <div class="d-flex align-items-center proyects-regulation-img">
                <img
                  src="assets/img/IRL.png"
                  class="img-fluid img-regulation"
                  alt="" />
                <img
                  src="assets/img/SEC.png"
                  class="img-fluid img-regulation"
                  alt="" />
              </div>
              <hr />
              <p class="text-buy">Pague con:</p>
              <div
                class="d-flex align-items-center justify-content-between proyects-regulation-img">
                <img
                  src="assets/img/visa.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/mastercard.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/AExpress.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/pse.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/bitcoin.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/tether.png"
                  class="img-fluid img-buy"
                  alt="" />
                <img
                  src="assets/img/binace.png"
                  class="img-fluid img-buy"
                  alt="" />
              </div>
              <hr />
              <img
                src="assets/img/Logo_elysium2.png"
                alt=""
                style="width: 200px"
                class="proyects-regulation-img" />
              <div class="d-flex align-items-center proyects-regulation-img">
                <a class="link-term" href="#">TÉRMINOS Y CONDICIONES </a> |
                <a class="link-term" href="#"> ¿TIENES ALGUNA DUDA? </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Project View Details -->
        <div class="col-12 col-xl-8">
          <!-- Project View Details Swiper -->
          <app-proyects-view-details-desktop-swiper></app-proyects-view-details-desktop-swiper>

          <app-proyects-view-details-desktop-info></app-proyects-view-details-desktop-info>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="d-flex d-xl-none position-relative" *ngIf="mobile">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="pt-3 pt-xl-5">
              <h4 class="pb-3 text-center">PROYECTOS DISPONIBLES</h4>

              <!-- Projects List -->

              <app-proyects-view-list-mobile></app-proyects-view-list-mobile>

              <div class="proyects-regulation px-lg-3">
                <p class="mb-3 text-regulation">
                  Aportamos impuestos y <br class="d-none d-lg-block" />
                  cumplimos <br class="d-block d-lg-none" />
                  con regulaciones de:
                </p>
                <div class="d-flex align-items-center proyects-regulation-img">
                  <img
                    src="assets/img/IRL.png"
                    class="img-fluid img-regulation"
                    alt="" />
                  <img
                    src="assets/img/SEC.png"
                    class="img-fluid img-regulation"
                    alt="" />
                </div>
                <hr />
                <p class="text-buy">Pague con:</p>
                <div
                  class="d-flex align-items-center justify-content-between proyects-regulation-img">
                  <img
                    src="assets/img/visa.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/mastercard.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/AExpress.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/pse.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/bitcoin.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/tether.png"
                    class="img-fluid img-buy"
                    alt="" />
                  <img
                    src="assets/img/binace.png"
                    class="img-fluid img-buy"
                    alt="" />
                </div>
                <hr />
                <img
                  src="assets/img/Logo_elysium2.png"
                  alt=""
                  style="width: 160px"
                  class="proyects-regulation-img d-none d-xl-block" />
                <img
                  src="assets/img/Logo_elysium2.png"
                  alt=""
                  style="width: 215px"
                  class="proyects-regulation-img d-xl-none" />
                <div class="d-flex align-items-center proyects-regulation-img">
                  <a class="link-term" href="#">TÉRMINOS Y CONDICIONES </a> |
                  <a class="link-term" href="#"> ¿TIENES ALGUNA DUDA? </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar -->
      <app-sidebar></app-sidebar>
    </div>
  </section>

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>
</div>
