import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, catchError, from, map, of } from 'rxjs';
import { fromBasicPoint, toBasicPoint } from 'src/app/helpers/utils';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-set-contract-fee-form',
  templateUrl: './set-contract-fee-form.component.html',
  styleUrls: ['./set-contract-fee-form.component.css']
})
export class SetContractFeeFormComponent {

  public form: UntypedFormGroup;
  public vm = {
    pg: [
      {type: "required", message: "Is required"},
      {type: "min", message: "Min value is 0"},
      {type: "max", message: "Max value is 100"},
    ]
  };
  public submitted = false;

  public currentPg$!: Observable<number>;

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService
  ) {
    this.form = fb.group({
      pg: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  get f() { return this.form.controls; }

  loadData(){
    this.currentPg$ = from(this.contractService.vendor_utils_getPGFee())
    .pipe(
      map((pg) => (pg != "0") ? fromBasicPoint(pg) : 0),
      catchError((err) => of(0))
    );
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Actualizar fee del contrato a ${_data.pg}%?`,
        contractParams: { method: 'vendor_utils_setPGFee', params: [ toBasicPoint(_data.pg) ] }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Fee actualizado a ${_data.pg}%`)
        .then((result) => {
          this.submitted = false;
          this.form.patchValue({pg: ""});
          this.loadData();
        });
      }

    } catch (err) {
      console.log('Error on SetContractFeeFormComponent@onSubmit', err);
    }
  }
}
