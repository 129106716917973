import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonService } from "./common.service";
import { CustomTranslateService } from "./custom-translate.service";

@Injectable({
  providedIn: "root",
})
export class Sweetalert2Service {
  public title = environment.projectName;

  constructor(
    private commonSrv: CommonService,
    private customTranslateSrv: CustomTranslateService
  ) {}

  async showError(message: any) {
    return Swal.fire({
      html: `
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img src="assets/img/logo-nuevo.png" width="200">
        <p class="m-0 mt-3">${message}</p>
      </div>
    `,
      icon: "error",
      text: message,
    });
  }

  async showQuestion(message: any) {
    return Swal.fire({
      html: `
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img src="assets/img/logo-nuevo.png" width="200">
        <p class="m-0 mt-3">${message}</p>
      </div>
    `,
      icon: "question",
      text: message,
    });
  }

  async showWarning(message: any) {
    return Swal.fire({
      html: `
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img src="assets/img/logo-nuevo.png" width="200">
        <p class="m-0 mt-3">${message}</p>
      </div>
    `,
      icon: "warning",
      text: message,
    });
  }

  async showSuccess(message: any) {
    return Swal.fire({
      html: `
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img src="assets/img/logo-nuevo.png" width="200">
        <p class="m-0 mt-3">${message}</p>
      </div>
    `,
      icon: "success",
      text: message,
    });
  }

  /**
   *
   * @param message
   * @returns
   */
  async askConfirm(message: string, type = "text") {
    const confirmButtonText = await this.customTranslateSrv.translate("Si");
    const cancelButtonText = await this.customTranslateSrv.translate("No");
    const { isConfirmed } = await Swal.fire({
      icon: "info",
      title: this.title,
      [type]: message,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText,
      cancelButtonText,
    });

    return isConfirmed;
  }

  async askUserCodeToRegist() {
    const { value: code } = await Swal.fire({
      title: "Ingresa tu usuario",
      input: "text",
      inputPlaceholder: "Ingresa tu usuario",
      showCancelButton: true,
      inputValidator: (value: any) => {
        const xss = this.commonSrv.removeSpecialCharacters(`${value}`.trim());
        if (xss.length == 0) {
          return "Es necesario que ingreses un usuario para poder continuar";
        }

        return null;
      },
    });

    if (!code) return null;
    return this.commonSrv.removeSpecialCharacters(
      `${code}`.trim().toLowerCase()
    );
  }

  /**
   * Launche alert like toast
   * @param message
   * @param type
   * @returns
   */
  public showToast(message: string, icon: any = "success") {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
      customClass: {
        htmlContainer: "applef sw2FixHtmlContainer",
        icon: "sw2FixIcon",
      },
    });
    const textStyle = `color: 'black';`;
    return Toast.fire({ icon: icon, title: message });
  }

  async showAlertWithTxHash(opts: AlertWithTxHashParams) {
    const {
      seeTransaction = "See transaction",
      transactionHash,
      icon = "success",
      confirmButtonText = "OK",
    } = opts;

    return await Swal.fire({
      title: this.title,
      icon: icon,
      html:
        "<a style='color: #fb9503 !important;' href='" +
        environment.chain.blockExplorerUrls +
        "tx/" +
        transactionHash +
        "' target='_blank'>" +
        seeTransaction +
        "</a>",
      confirmButtonText: confirmButtonText,
    });
  }
}

export interface AlertWithTxHashParams {
  seeTransaction?: string;
  transactionHash: string;
  icon?: any;
  confirmButtonText?: string;
}
