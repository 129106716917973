import { Injectable } from '@angular/core';
import { Observable, Subscription, fromEvent, map, merge, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaQueriesService {

  public mediaQuery!: string;
  public mediaQuery$!: Observable<any>;
  public subscription!: Subscription;

  constructor() {
    this.run();
  }

  run() {
    const mobileQuery = window.matchMedia('(max-width: 768px)');
    const tabletQuery = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
    const desktopQuery = window.matchMedia('(min-width: 1025px)');

    this.mediaQuery$ = merge(
      fromEvent(mobileQuery, 'change'),
      fromEvent(tabletQuery, 'change'),
      fromEvent(desktopQuery, 'change'),
      fromEvent(window, 'resize')
    ).pipe(
      map(() => {
        return {
          type: this.getDeviceType(mobileQuery, tabletQuery, desktopQuery),
          width: window.innerWidth,
          height: window.innerHeight,
        };
      }),
      startWith(this.getInitialDeviceInfo())
    );
  }

  private getDeviceType(
    mobileQuery: MediaQueryList,
    tabletQuery: MediaQueryList,
    desktopQuery: MediaQueryList
  ): string {
    if (mobileQuery.matches) {
      return 'Mobile';
    } else if (tabletQuery.matches) {
      return 'Tablet';
    } else if (desktopQuery.matches) {
      return 'Desktop';
    }
    return 'Unknown';
  }

  private getInitialDeviceInfo(): { type: string; width: number; height: number } {
    const initialDeviceType = this.getDeviceType(
      window.matchMedia('(max-width: 768px)'),
      window.matchMedia('(min-width: 769px) and (max-width: 1024px)'),
      window.matchMedia('(min-width: 1025px)')
    );

    return {
      type: initialDeviceType,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

}
