import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { cmIsValidAddress } from 'src/app/helpers/custom-validations.helper';
import { CommonService } from 'src/app/services/common.service';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  public form: UntypedFormGroup;
  public vm = {
    address: [
      {type: "required", message: "Is required"},
      {type: "isValidAddress", message: "Invalid address"},
    ]
  };
  public submitted = false;

  constructor(
    public fb: UntypedFormBuilder,
    public contractService: Web3Service,
    private alertStepsSrv: Sweetalert2stepsService,
    private commonSrv: CommonService
  ) {
    this.form = fb.group({
      address: ["", 
        [
          Validators.required,
          cmIsValidAddress
        ]
      ],
    });
  }

  ngOnInit(): void { }

  get f() { return this.form.controls; }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;
    const addrTruncated = this.commonSrv.getAddress(_data.address);
    console.warn("_data", _data.address)

    if (this.form.invalid) {
      return;
    }
    
    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `¿Añadir como Usuario la billetera ${addrTruncated}?`,
        contractParams: { method: 'vendor_administered_addUser', params: [ _data.address ] }
      });

      if(!result.status){
        return await this.alertStepsSrv.showBasicAlert(result.data.message,'error');
      }else{

        return this.alertStepsSrv.showBasicAlert(`Billtera ${addrTruncated} añadida como Usuario correctamente`)
        .then((result) => {
          this.submitted = false;
          this.form.reset();
        });
      }

    } catch (err) {
      console.log('Error on AddUserComponent@onSubmit', err);
    }
  }
}