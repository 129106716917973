import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { CasesService } from "src/app/services/firebase/cases.service";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";

@Component({
  selector: "app-notifications-users",
  templateUrl: "./notifications-users.component.html",
  styleUrls: ["./notifications-users.component.css"],
})
export class NotificationsUsersComponent implements OnInit, OnDestroy {
  private id: any;
  public notificatioList!: any[];
  public notificatioList$!: Observable<any[]>;
  private sub$!: Subscription;
  notifications: any = [
    {
      title: "notificaciones 1",
      message:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem, ullam. Tenetur, expedita officia non consectetur voluptates aliquid, corrupti, esse a quas cupiditate rerum autem officiis soluta dolores quos recusandae explicabo! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae iusto quaerat eveniet. Consequatur eos, nulla placeat modi esse provident eligendi debitis id quasi officia. Nesciunt animi aut obcaecati dolor repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut asperiores maxime sint illum expedita aperiam amet accusantium non ea animi doloribus perspiciatis eos officia debitis ullam quo nisi, iusto repellat?",
    },
    {
      title: "notificaciones 2",
      message:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem, ullam. Tenetur, expedita officia non consectetur voluptates aliquid, corrupti, esse a quas cupiditate rerum autem officiis soluta dolores quos recusandae explicabo! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae iusto quaerat eveniet. Consequatur eos, nulla placeat modi esse provident eligendi debitis id quasi officia. Nesciunt animi aut obcaecati dolor repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut asperiores maxime sint illum expedita aperiam amet accusantium non ea animi doloribus perspiciatis eos officia debitis ullam quo nisi, iusto repellat?",
    },
    {
      title: "notificaciones 3",
      message:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem, ullam. Tenetur, expedita officia non consectetur voluptates aliquid, corrupti, esse a quas cupiditate rerum autem officiis soluta dolores quos recusandae explicabo! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae iusto quaerat eveniet. Consequatur eos, nulla placeat modi esse provident eligendi debitis id quasi officia. Nesciunt animi aut obcaecati dolor repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut asperiores maxime sint illum expedita aperiam amet accusantium non ea animi doloribus perspiciatis eos officia debitis ullam quo nisi, iusto repellat?",
    },
    {
      title: "notificaciones 4",
      message:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem, ullam. Tenetur, expedita officia non consectetur voluptates aliquid, corrupti, esse a quas cupiditate rerum autem officiis soluta dolores quos recusandae explicabo! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae iusto quaerat eveniet. Consequatur eos, nulla placeat modi esse provident eligendi debitis id quasi officia. Nesciunt animi aut obcaecati dolor repellendus. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut asperiores maxime sint illum expedita aperiam amet accusantium non ea animi doloribus perspiciatis eos officia debitis ullam quo nisi, iusto repellat?",
    },
  ];

  public notification: any;

  constructor(
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private casesSrv: CasesService
  ) {
    this.id = this.authSrv.getLocalUID();
    this.notificatioList$ = this.casesSrv
      .getDynamic([{ field: "uidUser", condition: "==", value: this.id }])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            return {
              ...item,
            };
          })
        ),
        // tap((data) => console.log('ProyectsViewListDesktopComponent.proyectsList$', data)),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );

    this.sub$ = this.notificatioList$.subscribe((res) => {
      this.notificatioList = res;
      if (this.notificatioList.length > 0) {
        // console.log(this.notificatioList);
      }
    });
  }

  ngOnInit() {}

  async modalNotificationClose() {
    let modal = document.getElementById("modalNotification");
    modal?.classList.remove("show");
    modal?.classList.remove("d-flex");
  }

  notificationView(item: any, event: any) {
    let i, notificationListActive: any;
    this.notification = item;
    notificationListActive = document.getElementsByClassName(
      "notifications-list-item"
    );
    for (i = 0; i < notificationListActive.length; i++) {
      notificationListActive[i].className = notificationListActive[
        i
      ].className.replace("active", "");
    }
    event.currentTarget.className += " active";
  }
  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
