import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "../../pipes/pipes.module";

import { VendorManagerComponent } from "./components/vendor-manager/vendor-manager.component";
import { VendorAdminRoutingModule } from "./vendorAdmin-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { AddUserComponent } from "./components/add-user/add-user.component";
import { AddAdminComponent } from "./components/add-admin/add-admin.component";
import { RemoveUserComponent } from "./components/remove-user/remove-user.component";
import { RenounceAdminComponent } from "./components/renounce-admin/renounce-admin.component";
import { AddTokenComponent } from "./components/add-token/add-token.component";
import { UpdateTokenComponent } from "./components/update-token/update-token.component";
import { WhitelistListComponent } from "./components/whitelist-list/whitelist-list.component";
import { AddCollectionFormComponent } from "./components/add-collection-form/add-collection-form.component";
import { UpdateCollectionFormComponent } from "./components/update-collection-form/update-collection-form.component";
import { CollectionListComponent } from "./components/collection-list/collection-list.component";
import { WithdrawNativeComponent } from "./components/withdraw-native/withdraw-native.component";
import { WithdrawERC20Component } from "./components/withdraw-erc20/withdraw-erc20.component";
import { ImportCollectionFormComponent } from "./components/import-collection-form/import-collection-form.component";
import { SetContractFeeFormComponent } from "./components/set-contract-fee-form/set-contract-fee-form.component";
import { SetContractVaultFromComponent } from "./components/set-contract-vault-from/set-contract-vault-from.component";
import { TransferReservedFormComponent } from "./components/transfer-reserved-form/transfer-reserved-form.component";
import { UpdateFbCollectionFormComponent } from "./components/update-fb-collection-form/update-fb-collection-form.component";
import { WithdrawNativeDistributionsComponent } from "./components/withdraw-native-distributions/withdraw-native-distributions.component";
import { WithdrawErc20SaleDistributionComponent } from "./components/withdraw-erc20-sale-distribution/withdraw-erc20-sale-distribution.component";
import { WithdrawErc20DistributionsComponent } from "./components/withdraw-erc20-distributions/withdraw-erc20-distributions.component";
import { WithdrawNativeSaleDistributionComponent } from "./components/withdraw-native-sale-distribution/withdraw-native-sale-distribution.component";

@NgModule({
  declarations: [
    VendorManagerComponent,
    AddUserComponent,
    AddAdminComponent,
    RemoveUserComponent,
    RenounceAdminComponent,
    AddTokenComponent,
    UpdateTokenComponent,
    WhitelistListComponent,
    AddCollectionFormComponent,
    UpdateCollectionFormComponent,
    CollectionListComponent,
    WithdrawNativeComponent,
    WithdrawERC20Component,
    ImportCollectionFormComponent,
    SetContractFeeFormComponent,
    SetContractVaultFromComponent,
    TransferReservedFormComponent,
    UpdateFbCollectionFormComponent,
    WithdrawNativeDistributionsComponent,
    WithdrawErc20SaleDistributionComponent,
    WithdrawErc20DistributionsComponent,
    WithdrawNativeSaleDistributionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SharedModule,
    VendorAdminRoutingModule,
  ],
  exports: [
    VendorManagerComponent,
    AddUserComponent,
    AddAdminComponent,
    RemoveUserComponent,
    RenounceAdminComponent,
    AddTokenComponent,
    UpdateTokenComponent,
    WhitelistListComponent,
    AddCollectionFormComponent,
    UpdateCollectionFormComponent,
    CollectionListComponent,
    WithdrawNativeComponent,
    WithdrawERC20Component,
    ImportCollectionFormComponent,
    SetContractFeeFormComponent,
    SetContractVaultFromComponent,
    TransferReservedFormComponent,
    UpdateFbCollectionFormComponent,
    WithdrawNativeDistributionsComponent,
    WithdrawErc20SaleDistributionComponent,
    WithdrawErc20DistributionsComponent,
    WithdrawNativeSaleDistributionComponent,
  ],
})
export class VendorAdminModule {}
