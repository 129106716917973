<div class="card p-3 mb-4">
    <div class="card-body">
        <div class="fs-5 mb-3">Modificar Colección</div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <!-- <input type="number" formControlName="id" class="form-control" placeholder="0"> -->
                <select class="form-select" formControlName="id" placeholder="Colección">
                    <option *ngFor="let item of collections" [value]="item.id">
                        {{item.addr | customERC721a:'name' | async | titlecase}}
                    </option>
                </select>

                <label for="floatingInput">
                    Id de la Colección
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.id">
                        <div class="form-text text-danger mb-2" *ngIf="f['id'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text">Tipo de cambio</span>
                <select class="form-select" formControlName="type" placeholder="Estado">
                    <option [value]="1">Precio</option>
                    <option [value]="2">Estado</option>
                </select>
            </div>

            <div class="form-floating mb-3">
                <input type="number" formControlName="price" class="form-control" placeholder="Precio">
                <label for="floatingInput">
                    Precio
                </label>

                <ng-container *ngIf="submitted">
                    <ng-container *ngFor="let item of vm.price">
                        <div class="form-text text-danger mb-2" *ngIf="f['price'].hasError(item.type)">
                            {{item.message | uppercase}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text">
                    Estado a aplicar
                </span>
                <select class="form-select" formControlName="bool" placeholder="Estado">
                    <option [value]="false">Falso</option>
                    <option [value]="true">Verdadero</option>
                </select>
            </div>
            
            <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-primary">
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>

