import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { addressIsValid } from "./utils";
import { AuthenticationService } from "../services/authentication.service";
import { Observable, map, tap } from "rxjs";

/**
 * Validar si una dirección es correcta
 * @param control 
 * @returns
 */
export function cmIsValidAddress(control: AbstractControl){
    return (addressIsValid(control.value)) ? null : { isValidAddress: true };
}

/**
 * Válidar si el correo ya se encuentra registrado o esta en uso
 */
export function checkEmailStored(service: AuthenticationService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return service.afs.collection(
            'users',
            (ref) => ref.where('email', '==', `${control.value}`.trim()).limit(1)
        ).get()
        .pipe(
            // tap((data: any) => console.log('checkEmailForExists', data) ),
            map((data: any) =>  (data.empty) ? null : { emailStored: true })
        );
    }
}