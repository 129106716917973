import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, catchError, from, map, of } from "rxjs";
import { cmIsValidAddress } from "src/app/helpers/custom-validations.helper";
import { fromWei, toWei } from "src/app/helpers/utils";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2stepsService } from "src/app/services/sweetalert2steps.service";
import { environment } from "src/environments/environment";
import { AlertStepsService } from "src/app/services/contract/alert-steps.service";
import { VendorContractService } from "src/app/services/contract/vendor-contract.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
@Component({
  selector: "app-update-token",
  templateUrl: "./update-token.component.html",
  styleUrls: ["./update-token.component.css"],
})
export class UpdateTokenComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted = false;
  public vm = {
    id: [
      { type: "required", message: "Is Required" },
      { type: "min", message: "Min 0" },
    ],
    type: [{ type: "required", message: "Is Required" }],
    address: [
      { type: "required", message: "Is Required" },
      { type: "isValidAddress", message: "Invalid Address" },
    ],
    bool: [{ type: "required", message: "Is Required" }],
  };

  public dataStatus$!: Observable<any>;
  public tokenList$!: Observable<any[]>;
  public nativeCurrency = environment.chain.nativeCurrency;

  constructor(
    public fb: UntypedFormBuilder,
    public vendorContractSrv: VendorContractService,
    private alertStepsSrv: AlertStepsService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.form = fb.group({
      id: ["", [Validators.required, Validators.min(0)]],
      type: [1, [Validators.required]],
      address: [
        "0x0000000000000000000000000000000000000000",
        [Validators.required, cmIsValidAddress],
      ],
      bool: [true, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.tokenList$ = from(
      this.vendorContractSrv.vendor_whitelist_tokensList()
    ).pipe(
      map((data: any[]) =>
        data.map((item: any, index: number) => ({ ...item, id: index }))
      ),
      catchError((err) => of([]))
    );
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (
      _data.address == "0x0000000000000000000000000000000000000000" &&
      _data.type == 1
    ) {
      this.sweetAlert2Srv.showError("Debe ingresar una direccion valida");
      return;
    }

    try {
      const result = await this.alertStepsSrv.showStepsGeneral({
        service: this.vendorContractSrv,
        askMessage: `¿Estas seguro de modificar este token?`,
        method: "vendor_whitelist_updateToken",
        params: [_data.id, _data.type, _data.address, _data.bool === "true"],
      });

      if (!result.status) {
        return await this.sweetAlert2Srv.showError(result.data.message);
      } else {
        return this.sweetAlert2Srv
          .showSuccess(`Se modifico exitosamente el token`)
          .then((result) => {
            this.submitted = false;
            this.form.patchValue({
              id: "",
              type: 1,
              address: "0x0000000000000000000000000000000000000000",
              bool: true,
            });
          });
      }
    } catch (err) {
      console.log("Error on UpdateTokenComponent@onSubmit", err);
    }
  }
}
