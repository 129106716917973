<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/img/logo-nuevo.png" alt="Bootstrap" width="200" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
        <li class="nav-item">
          <ng-container
            *ngIf="dataStatus$ | async as dataStatus; else noConnected"
            [ngTemplateOutlet]="connected"
            [ngTemplateOutletContext]="{ data: dataStatus }"></ng-container>
        </li>
        <li class="nav-item"></li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #noConnected>
  <button class="btn btn-orange" (click)="launch()">
    <img src="assets/img/wallet-black.png" alt="" />
    Conectar Wallet
  </button>
</ng-template>

<ng-template #connected>
  <button class="btn btn-orange" (click)="logOut()">
    <img src="assets/img/logout-black.png" alt="" />
    Cerrar sesión
  </button>
</ng-template>
