import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorManagerComponent } from './components/vendor-manager/vendor-manager.component'; 
import { UpdateFbCollectionFormComponent } from './components/update-fb-collection-form/update-fb-collection-form.component';

const routes: Routes = [
  {
    path: '',
    component: VendorManagerComponent,
  },
  {
    path: 'collection/:id',
    component: UpdateFbCollectionFormComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/admin/vendor'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorAdminRoutingModule { }
